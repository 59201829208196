<template>
	<div id="app">
		<Layout />
	</div>
</template>

<script>
	import
	Layout
	from "./layout/layIndex.vue"
	export default {
		name: 'app',
		components: {
			Layout
		}
	}
</script>
<style>
	.tiprsHer .lines .right>div {
		cursor: pointer;
	}

	.minBox {
		cursor: pointer;
	}

	.Rightbox .header>div {
		cursor: pointer;
	}

	.fuyoBox .box {
		cursor: pointer;
	}

	.Limited .minhe {
		cursor: pointer;
	}

	.zhuanbTe>.right {
		cursor: pointer;
	}
	.bottomfor .onBotton {
		cursor: pointer;
	}
	.bottomfor .toBotton{
		cursor: pointer;
	}
	.tipser .lefts > div{
		cursor: pointer;
	}
	.liness > div{
		cursor: pointer;
	}
</style>
<style>
	.el-message--error .el-message__content {
		font-size: 16px !important;
	}

	.el-dialog {
		zoom: 0.7;
	}

	.el-popper {
		zoom: 0.7;
	}

	#app {
		min-width: 1280px;
		overflow-x: auto;
	}

	.el-select-dropdown__item {
		font-size: 18px !important;
	}

	.el-pagination.is-background .el-pager li {
		min-width: 35px;
		height: 30px;
		border: 1px solid #A5A5A5;
		border-radius: 4px;
		background: transparent;
	}

	.el-pagination.is-background .btn-prev:disabled {
		border: 1px solid #A5A5A5;
		min-width: 35px;
		height: 30px;
		background: transparent;
	}

	.el-pagination.is-background .btn-next {
		border: 1px solid #A5A5A5;
		min-width: 35px !important;
		height: 30px;
		background: transparent;
	}

	.el-pagination .btn-next .el-icon:before {
		color: #A5A5A5;
	}

	.el-pagination .btn-next .el-icon {
		color: #A5A5A5;
	}
</style>
<style lang="scss">
	body {
		margin: 0;
	}
</style>