<template>
	<div class="maxin" id="MaxDuens">
		<div class="tiprsHer">
			<div class="lines">
				<div class="left">一级分类：</div>
				<div class="right">
					<div v-for="(item,index) in oneDat" :key="index" :class="{'bottonsre':ones== index}"
						@click="clickOne(index)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="lines">
				<div class="left">二级分类：</div>
				<div class="right">
					<div v-for="(item,index) in twoDat" :key="index" :class="{'bottonsre':twos== index}"
						@click="clickTwo(index)">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="biaoqian">
			<div class="title">全部商品</div>
			<div class="text">共找到<span style="color: #333333;">{{total}}</span>个结果</div>
		</div>
		<div class="sortser">
			<div class="xiaoBox" :class="{'xuanzs': values == 0}" @click="clickVal(0)">
				综合
			</div>
			<div class="xiaoBox" :class="{'xuanzs': values == 1}" @click="clickVal(1)">
				价格
				<div>
					<div><i :style="{color: oneIndex == 0 && values == 1  ?'#3B99FE':'#999999'}"
							class="el-icon-caret-top"></i></div>
					<div><i :style="{color: oneIndex == 1 && values == 1 ?'#3B99FE':'#999999'}"
							class="el-icon-caret-bottom"></i></div>
				</div>
			</div>
			<div class="xiaoBox" :class="{'xuanzs': values == 2}" @click="clickVal(2)">
				销量
				<div>
					<div><i :style="{color: twoIndex == 0 && values == 2 ?'#3B99FE':'#999999'}"
							class="el-icon-caret-top"></i></div>
					<div><i :style="{color: twoIndex == 1 && values == 2 ?'#3B99FE':'#999999'}"
							class="el-icon-caret-bottom"></i></div>
				</div>
			</div>
		</div>
		<!-- 商品 -->
		<div v-if="datalist.length >0">
			<div class="fuyoBox">
				<div class="box" v-for="(item,index) in datalist" :key="index" @click="routerXing(item.id)">
					<img :src="item.image" alt="">
					<div class="title">{{item.title}}</div>
					<div class="fotter">
						<div class="left">
							<span>￥</span>{{item.money}}
						</div>
						<div class="right">已售{{item.sale}}</div>
					</div>
				</div>
			</div>
			<div class="paging">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="20"
					@current-change="pagese" @prev-click='pearDta' @next-click='mextDat'>
				</el-pagination>
			</div>
		</div>
		<div v-else class="zhanwuShu">
			<img src="../../assets/quesheng.png" alt="" />
			<div>暂无数据</div>
		</div>
	</div>
</template>

<script>
	import {
		shoppLists,
		shoppYji,
		shoppErji
	} from "../../api/shoppIng.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				oneIndex: 0,
				twoIndex: 0,
				values: 0,
				ones: 0,
				twos: 0,
				oneDat: [],
				twoDat: [],
				page: 1,
				datalist: [],
				total: 0,
				valueTit: ''
			}
		},
		mounted() {
			if (this.$route.query.title) {
				this.valueTit = this.$route.query.title
			}
			this.getData()
			this.getDaters()
			this.getLists()
		},
		watch: { // 监听,当路由发生变化的时候执行
			'$route'() {
				if (this.$route.query.title) {
					this.valueTit = this.$route.query.title
					this.getLists()
				}
			},
		},
		methods: {
			// 一级分类
			async getData() {
				let data = await shoppYji()
				this.oneDat = data.data.list
				this.oneDat.unshift({
					title: '全部',
					id: 0
				})

			},
			//二级分类
			async getDaters() {
				let data = await shoppErji()
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取二级分类
			async getDsherss() {
				let data = await shoppYji({
					pid: this.ones
				})
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取列表
			async getLists() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#MaxDuens')
				});
				let ser = {
					page: this.page,
					limit: 20,
					title: this.valueTit
				}
				if (this.values == 1) {
					ser.order = this.oneIndex == 0 ? 'asc' : 'desc'
				} else if (this.values == 2) {
					ser.order = this.twoIndex == 0 ? 'asc' : 'desc'
				}
				if (this.values != 0) {
					ser.sorts = this.values == 1 ? 'money' : 'sale'
				}
				if (this.ones) {
					ser.cid = this.ones
				} else if (this.twos) {
					ser.cid = this.twos
				}
				let data = await shoppLists(ser)
				if (data.code == 1) {
					this.datalist = data.data.list
					this.total = data.data.total
				} else {
					this.datalist = []
					this.total = 0
				}
				this.valueTit = ''
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			routerXing(e) {
				this.$router.push({
					path: '/deloePage',
					query: {
						id: e
					}
				})
			},
			clickOne(e) {
				this.ones = e
				this.twos = ''
				if (this.ones == 0) {
					this.getDaters()
				} else {
					this.getDsherss()
				}
				this.getLists()
			},
			clickTwo(e) {
				this.twos = e
				this.ones = ''
				this.getLists()
			},
			clickVal(e) {
				this.page = 1
				if (e == 1) {
					this.oneIndex = this.oneIndex == 0 ? 1 : 0
				}
				if (e == 2) {
					this.twoIndex = this.twoIndex == 0 ? 1 : 0
				}
				this.values = e
				this.getLists()
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getLists()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getLists()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getLists()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.paging {
		display: flex;
		justify-content: center;
		padding: 55px 0 20px 0;
		zoom: 0.76;
	}

	.fuyoBox {
		display: flex;
		flex-wrap: wrap;


		.fotter {
			display: flex;
			justify-content: space-between;
			padding: 16px 20px 0 20px;
			align-items: center;

			.right {
				font-size: 16px;
				color: #B2B2B2;
			}

			.left {
				font-size: 28px;
				color: #CD1212;

				&>span {
					font-size: 18px;
					font-weight: bold;
				}
			}
		}


		.box:nth-child(5n) {
			margin-right: 0;
		}

		.box:hover {
			transform: scale(1.06);
		}

		.box {
			transition: transform 0.3s;
			zoom: 0.8;
			width: 290px;
			height: 420px;
			background: #FFFFFF;
			border-radius: 12px;
			overflow: hidden;
			margin-bottom: 20px;
			margin-right: 24px;

			.title {
				height: 44px;
				padding: 0 20px;
				font-size: 16px;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				margin-top: 10px;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			&>img {
				width: 290px;
				height: 290px;
			}
		}
	}

	.sortser {
		zoom: 0.8;
		display: flex;
		align-content: center;
		margin-bottom: 35px;

		.xiaoBox {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #999999;
			margin-right: 25px;

			&>div {
				position: relative;
				bottom: 7px;
			}

			&>div>div {
				width: 10px;
				height: 10px;
			}
		}

		.xuanzs {
			color: #3B99FE;
		}
	}

	.zhanwuShu {
		text-align: center;
		padding: 200px 0 220px 0;

		&>img {
			width: 200px;
			height: 100px;
		}

		&>div {
			margin-top: 10px;
			font-size: 14px;
			color: #B2B2B2;
		}
	}

	.biaoqian {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 40px 0 36px 0;

		.title {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}

		.text {
			font-size: 14px;
			color: #999999;
		}
	}


	.tiprsHer {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 20px 30px 10px 30px;

		.lines {
			display: flex;

			.right {
				font-size: 14px;
				color: #666666;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				&>div {
					font-size: 14px;
					margin-right: 22px;
					margin-bottom: 10px;
					padding: 0 5px;
					height: 36px;
					line-height: 36px;
				}

				.bottonsre {
					padding: 0 6px;
					height: 28px;
					background: #DEEEFF;
					border-radius: 4px;
					font-size: 14px;
					line-height: 28px;
					color: #3B99FE;
				}
			}

			.left {
				padding-top: 6px;
				min-width: 100px;
				font-size: 14px;
				color: #333333;
			}
		}
	}

	.maxin {
		padding: 30px 0 20px 0;
		width: 1240px;
		margin: auto;
	}
</style>