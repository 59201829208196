import request from "../utils/request.js"

// 直播分类
export function zhiboFenlei(data) {
	return request({
		url: "/course.courselive/get_cate",
		method: "post",
		data
	});
}
// 二级分类
export function zhiboErji(data) {
	return request({
		url: "/course.courselive/get_cate_node",
		method: "post",
		data
	});
}
// 直播列表
export function zhiboLists(data) {
	return request({
		url: "/course.courselive/get_list",
		method: "post",
		data
	});
}
// 直播详情
export function zhiboDelis(data) {
	return request({
		url: "/course.courselive/get_view",
		method: "post",
		data
	});
}
//验证是否可以看直播
export function zhiboYanzen(data) {
	return request({
		url: "/course.courselive/info_check",
		method: "post",
		data
	});
}
//查看直播
export function zhiboChakan(data) {
	return request({
		url: "/course.courselive/info_add",
		method: "post",
		data
	});
}