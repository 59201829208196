import request from "../utils/request.js"

// 密码登录
export function login(data) {
	return request({
		url: "/home/app_login",
		method: "post",
		data
	});
}
// 验证码登录
export function loginse(data) {
	return request({
		url: "/home/mobile_login",
		method: "post",
		data
	});
}
// 我的资料
export function myzhilaos(data) {
	return request({
		url: "/user.index/get_view",
		method: "post",
		data
	});
}
// 注册
export function register(data) {
	return request({
		url: "/home/register",
		method: "post",
		data
	});
}
// 发送短信
export function messages(data) {
	return request({
		url: "/sms/get_send",
		method: "post",
		data
	});
}
// 首页
export function homesPage(data) {
	return request({
		url: "/index/pc",
		method: "post",
		data
	});
}
// 添加地址
export function addDizhi(data) {
	return request({
		url: "/user.address/get_add",
		method: "post",
		data
	});
}
// 地址列表l
export function getDiliest() {
	return request({
		url: "/user.address/get_list",
		method: "post"
	});
}
// 获取默认地址
export function morenizhi(data) {
	return request({
		url: "/user.address/get_type",
		method: "post",
		data
	});
}
// 修改地址
export function putDizhi(data) {
	return request({
		url: "/user.address/get_edit",
		method: "post",
		data
	});
}
// 删除地址
export function deleDizhi(data) {
	return request({
		url: "/user.address/get_del",
		method: "post",
		data
	});
}
// 获取城市
export function getChengshi() {
	return request({
		url: "/config/get_city",
		method: "post"
	});
}
// 获取城市
export function peizhixiang(data) {
	return request({
		url: "/config/get_list",
		method: "post",
		data
	});
}
// 更新用户信息
export function putXingxi(data) {
	return request({
		url: "/user.index/get_update",
		method: "post",
		data
	});
}
// 分销二维码
export function fnegxiaosss(data) {
	return request({
		url: "/user.index/get_qrcode_http",
		method: "post",
		data
	});
}
// 个人中心
export function gerenzxing(data) {
	return request({
		url: "/user.index/get_index",
		method: "post",
		data
	});
}
// 上传
export function shancgss(data) {
	return request({
		url: "/upload/zyupload",
		method: "post",
		data
	});
}
// 会员
export function huioyuanka(data) {
	return request({
		url: "/user.usergroup/get_list",
		method: "post",
		data
	});
}
// 三级菜单
export function sabjiCaidan(data) {
	return request({
		url: "/course.course/get_cate_tree",
		method: "post",
		data
	});
}
// 会员下单
export function hiyuanxiadan(data) {
	return request({
		url: "/user.usergroup/get_order_add",
		method: "post",
		data
	});
}
// 会员拉支付
export function huiyuanlazhif(data) {
	return request({
		url: "/user.usergroup/get_wx_order",
		method: "post",
		data
	});
}