const state = {
	type: 0, // 用户类型 0 学生 1 老师 2 企业
	token: '',
	states: false,
	popushui: false,
	pid: 0,
	iphens: ''
}
const getters = {
	getType: (state) => state.type,
}

const mutations = {
	setType: (state, val) => {
		state.type = val
	},
	setToken: (state, val) => {
		state.token = val
	},
	setPid: (state, val) => {
		state.pid = val
	},
	setIphens: (state, val) => {
		state.iphens = val
	}
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}