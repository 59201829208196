<template>
	<article>
		<div class="MEMBER">会员中心</div>
		<div class="headerBox" v-if="dataKs">
			<div class="imger">
				<img :src="dataKs.avatar" alt="" class="oneImg">
				<!-- action="https://shop.yhjtedu.com/api/upload/zyupload" -->
				<el-upload ref="files" v-show="false" action="https://shop.yhjtedu.com/api/upload/zyupload"
					:before-upload="beforeAvatarUpload" :on-success="uopajdijd" multiple :limit="1"
					:file-list="fileList">
					<el-button size="small" type="primary">点击上传</el-button>
					<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
				<img src="../../../src/assets/xiangji.png" alt="" @click="shangchuan" class="twoImg">
			</div>
			<div class="informa">
				<div class="title">{{dataKs.nickname}} : {{dataKs.mobile}}</div>
				<div class="huiyuan">{{dataKs.Usergroup.title_fake}}（ID：{{dataKs.id}}）<img
						src="../../../src/assets/bianhui.png" alt="" v-show="dataKs.groupid == 1"
						style="cursor: pointer;"><span v-show="dataKs.groupid == 1" @click="kaitonghui = true"
						style="cursor: pointer;">开通会员</span></div>
				<div class="youxiaoq" v-show="dataKs.groupid == 2">{{hjuiyan}}</div>
			</div>
			<div class="erCode" @click="fengxaoss">
				<img :src="image" alt="" class="imgsee" v-show="image" />
				<div style="font-size: 20px;margin-top: 10px;">邀请码: <span
						style="color: #f4a300;font-size: 20px;">{{dataKs.id}}</span></div>
			</div>
		</div>
		<div class="centers">
			<div class="lefts">
				<div class="oneBox">
					<div :class="{'textBox':value == 0,'textMin':value != 0}" @click="valueDat(0)">商城订单</div>
					<div :class="{'textBox':value == 1,'textMin':value != 1}" @click="valueDat(1)">我的活动</div>
					<div :class="{'textBox':value == 2,'textMin':value != 2}" @click="valueDat(2)">我的课程</div>
					<div :class="{'textBox':value == 3,'textMin':value != 3}" @click="valueDat(3)">我的答题</div>
					<div :class="{'textBox':value == 4,'textMin':value != 4}" @click="valueDat(4)">购物车</div>
				</div>
				<div class="twoBox">
					<div :class="{'textBox':value == 5,'textMin':value != 5}" @click="valueDat(5)">我的佣金</div>
					<div :class="{'textBox':value == 6,'textMin':value != 6}" @click="valueDat(6)">我的团队</div>
				</div>
				<div class="threeBox">
					<div :class="{'textBox':value == 7,'textMin':value != 7}" @click="valueDat(7)">地址管理</div>
					<div :class="{'textBox':value == 8,'textMin':value != 8}" @click="valueDat(8)">我的资料</div>
					<div class="textMin" @click="dengluse = true">退出登录</div>
				</div>
			</div>
			<div class="rights" id="jiazaizhong">
				<ShangCheng v-if="value == 0" ref="dehhsss" />
				<MyHuodong v-else-if="value ==1" />
				<MyKecheng v-else-if="value == 2" />
				<MyDati v-else-if="value == 3" />
				<GouWuche v-else-if="value == 4" />
				<MyYongj v-else-if="value == 5" />
				<MyTuandui v-else-if="value == 6" />
				<DiZhi v-else-if="value == 7" />
				<MyZhiliao v-else-if="value == 8" />
			</div>
		</div>
		<el-dialog title="开通VIP 免费观看海量学习视频" :visible.sync="kaitonghui" :before-close="openGoumnai"
			custom-class="goumaiHui" :close-on-click-modal="false">
			<div>
				<div class="huiyuanpcr">
					<div class="left" v-for="(item,index) in huiyuanka" :key="index"
						:class="{'rightss':huiValue== index}" @click="huival(index)">
						<div class="gengduoi" v-show="item.id == 5">
							更多人选择
						</div>
						<div class="title">
							{{item.title_fake}}
						</div>
						<div class="price">
							<span>￥</span>{{item.money}}
						</div>
						<div class="yuanjia">
							￥{{item.money_fake}}
						</div>
						<div class="boxin" v-html="item.text">
						</div>
					</div>
				</div>
				<div class="erweima" v-if="erWeima">
					<img :src="erWeima" alt="" />
				</div>
				<div class="zhiofus">
					<img src="../../assets/weixing.png" alt="" />
					扫码支付
				</div>
				<div class="jaigeishe" v-if="huiyuanka.length >0">
					￥{{huiyuanka[huiValue].money}}
				</div>
				<div class="shaoMazhif" v-if="erWeima" @click="wanchengzhif">完成支付，请点击</div>
				<div class="shaoMazhif" @click="huiyuanx" v-else>扫码支付</div>
				<div class="fotterssw" @click="yonghuxieyi">*支付视为您同意《谊华教育用户协议》</div>
			</div>
		</el-dialog>
		<el-dialog title="退出登录" :visible.sync="dengluse" width="460px" custom-class="tuichudeng">
			<span>确认退出登录？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dengluse = false">取 消</el-button>
				<el-button type="primary" @click="openLogin">确 定</el-button>
			</span>
		</el-dialog>
		<!-- <el-dialog title="分销二维码" :visible.sync="fengxiao" custom-class="goumaiHui" width="600px">
			<div class="erweima">
				<img :src="image" alt="" class="imgsee" />
				<div style="font-size: 34px;margin-top: 10px;">邀请码: <span
						style="color: #f4a300;font-size: 38px;">{{dataKs.id}}</span></div>
			</div>
		</el-dialog> -->
	</article>
</template>

<script>
	import {
		gerenzxing,
		putXingxi,
		fnegxiaosss,
		huioyuanka,
		hiyuanxiadan,
		huiyuanlazhif
	} from "../../api/user.js"
	// 商城
	import ShangCheng from './shangc/indexP.vue'
	// 活动
	import MyHuodong from './myHuodong/indexP.vue'
	// 课程
	import MyKecheng from "./myKecheng/indexP.vue"
	// 答题
	import MyDati from "./myDati/indexP.vue"
	// 购物车
	import GouWuche from "./gouwuc/indexP.vue"
	// 地址
	import DiZhi from "./dizhi/indexP.vue"
	// 我的资料
	import MyZhiliao from "./myZhiliao/indexP.vue"
	// 佣金
	import MyYongj from "./myYongj/indexP.vue"
	// 我的团队
	import MyTuandui from "./myTuandui/indexP.vue"
	export default {
		components: {
			ShangCheng,
			MyHuodong,
			MyKecheng,
			MyDati,
			GouWuche,
			DiZhi,
			MyZhiliao,
			MyYongj,
			MyTuandui
		},
		data() {
			return {
				erWeima: '',
				hjuiyan: '',
				fengxiao: false,
				kaitonghui: false,
				value: 0,
				image: '',
				huiValue: 0,
				dataKs: '',
				statew: true,
				dengluse: false,
				fileList: [],
				huiyuanka: []
			}
		},
		mounted() {
			if (this.$route.query.unms) {
				this.value = this.$route.query.unms
			}
			if (this.$route.query.delis) {
				this.$refs.dehhsss.stater = false
				this.$refs.dehhsss.ids = this.$route.query.idss
			}
			this.fengxaoss()
			this.getData()
			this.getHuiyuanka()
		},
		watch: { // 监听,当路由发生变化的时候执行
			'$route'() {
				if (this.$route.query.unms) {
					this.value = this.$route.query.unms
				}
			},
		},
		methods: {
			// 完成支付
			async wanchengzhif() {
				let data = await gerenzxing()
				if (data.code == 1) {
					if (data.data.user.groupid != 1) {
						this.getData()
						this.$message({
							message: '会员卡购买成功',
							type: 'success'
						});
					} else {
						this.$message.error('请支付后，再点击');
					}
				}
			},
			yonghuxieyi() {
				this.kaitonghui = false
				this.$router.push("/yongHxyi")
			},
			async getHuiyuanka() {
				let data = await huioyuanka()
				if (data.code == 1) {
					this.huiyuanka = data.data.list
				}
			},
			// 会员下单
			async huiyuanx() {
				let data = await hiyuanxiadan({
					gid: this.huiyuanka[this.huiValue].id,
					type: 2
				})
				if (data.code == 1) {
					this.huiyuanlaz(data.data.id)
				}
			},
			async huiyuanlaz(e) {
				let data = await huiyuanlazhif({
					oid: e
				})
				if (data.code == 1) {
					this.erWeima = data.data.ewm
				}
			},
			shangchuan() {
				this.$refs.files.$el.querySelector("input").click();
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			uopajdijd(e) {
				this.gengxing(e.data.image_name)
			},
			async fengxaoss() {
				let data = await fnegxiaosss({
					page: `pages/index/index?pid=${this.dataKs.id}`
				})
				if (data.code == 1) {
					this.image = data.data
				}
			},
			// 更新信息
			async gengxing(e) {
				let data = await putXingxi({
					avatar: e
				})
				if (data.code == 1) {
					this.getData()
					this.$message({
						message: '更新成功',
						type: 'success'
					});
				}
			},
			openLogin() {
				this.$store.commit('user/setToken', '')
				this.$store.commit('user/setIphens', '')
				this.dengluse = false
				this.$router.push("/")
			},
			async getData() {
				let data = await gerenzxing()
				if (data.code == 1) {
					this.dataKs = data.data.user
					this.hjuiyan = data.data.group_text
				}
			},
			openGoumnai() {
				this.kaitonghui = false
			},
			huival(e) {
				this.huiValue = e
			},
			valueDat(e) {
				this.value = e
			}
		}
	}
</script>
<style lang="scss" scoped>
	/deep/ .tuichudeng {
		zoom: 1 !important;
	}

	.shaoMazhif {
		cursor: pointer;
		margin: auto;
		width: 360px;
		height: 60px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 18px;
		text-align: center;
		line-height: 60px;
		color: #FFFFFF;
		margin-top: 20px;
	}

	.fotterssw {
		cursor: pointer;
		font-size: 16px;
		text-align: center;
		color: #B2B2B2;
		margin: 26px 0 10px 0;
	}

	.jaigeishe {
		margin-top: 12px;
		text-align: center;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FF8400;
	}

	.zhiofus {
		margin-top: 20px;
		font-size: 16px;
		text-align: center;
		color: #999999;

		&>img {
			width: 23px;
			height: 22px;
			margin-right: 5px;
			vertical-align: middle;
			position: relative;
			bottom: 2px;
		}
	}

	.erweima {
		text-align: center;

		img {
			width: 150px;
			height: 150px;
		}

		margin: 20px 0 10px 0;
	}

	.huiyuanpcr {
		width: 590px;
		margin: auto;
		display: flex;
		justify-content: space-between;

		.left {
			width: 280px;
			height: 320px;
			background: #FFFFFF;
			border-radius: 12px;
			box-shadow: 0 0 4px #b8b8b8;
			padding-bottom: 30px;
			position: relative;

			.gengduoi {
				width: 110px;
				height: 32px;
				background: linear-gradient(250deg, #FF8400 0%, #FF5A00 100%);
				border-radius: 12px 0px 12px 0px;
				font-size: 18px;
				font-weight: bold;
				color: #FFFFFF;
				text-align: center;
				line-height: 32px;
				position: absolute;
			}


			.lsisjs {
				margin-top: 10px;
			}

			.boxin {
				padding-left: 30px;

				&>div {
					width: 6px;
					height: 6px;
					background: #BBBBBB;
					border-radius: 50%;
					margin-right: 10px;
				}
			}

			.yuanjia {
				font-size: 20px;
				text-decoration: line-through;
				color: #B2B2B2;
				text-align: center;
				padding: 0 0 36px 0;
			}

			.title {
				font-size: 26px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				text-align: center;
				padding: 50px 0 28px 0
			}

			.price {
				font-size: 60px;
				font-weight: bold;
				color: #FF8400;
				text-align: center;

				&>span {
					font-size: 32px;
				}
			}
		}

		.rightss {
			background: #FFFBF7;
			border: 2px solid #FF8400;
		}
	}

	/////////////////////////////////////////////
	.youxiaoq {
		font-size: 14px;
		margin-top: 6px;
		color: #B2B2B2;
	}

	.textMin {
		cursor: pointer;
		width: 180px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		font-size: 16px;
		color: #333333;
	}

	.textBox::before {
		content: '';
		width: 4px;
		height: 50px;
		background: #3B99FE;
		position: absolute;
		left: 0;
		top: 0;
	}

	.textBox {
		cursor: pointer;
		position: relative;
		width: 180px;
		height: 50px;
		background: #F0F7FF;
		text-align: center;
		line-height: 50px;
		font-size: 16px;
		color: #3B99FE;
	}

	.centers {
		margin-bottom: 30px;
		width: 1240px;
		margin-top: 20px;
		display: flex;
		justify-content: space-between;

		.lefts {
			width: 180px;

			.oneBox {
				padding: 30px 0;
				width: 180px;
				background: #FFFFFF;
				border-radius: 12px;
			}

			.twoBox {
				padding: 30px 0;
				margin-top: 20px;
				width: 180px;
				background: #FFFFFF;
				border-radius: 12px;
			}

			.threeBox {
				padding: 30px 0;
				margin-top: 20px;
				width: 180px;
				background: #FFFFFF;
				border-radius: 12px;
			}
		}

		.rights {
			zoom: 0.83;
			padding: 30px;
			width: 1200px;
			background: #FFFFFF;
			border-radius: 12px;
		}
	}

	.headerBox {
		width: 1160px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 20px 40px;
		display: flex;
		align-items: center;

		.erCode {
			cursor: pointer;
			zoom: 0.7;
			text-align: center;

			&>div {
				margin-top: 10px;
			}

			&>img {
				vertical-align: middle;
				width: 140px;
				height: 140px;
			}
		}

		.informa {
			zoom: 0.7;
			flex: 1;
			margin-left: 30px;

			.huiyuan {
				font-size: 16px;
				color: #333333;
				margin-top: 12px;

				&>img {
					width: 20px;
					height: 20px;
					vertical-align: middle;
					position: relative;
					bottom: 1px;
				}

				&>span {
					font-size: 16px;
					color: #F5B53A;
				}
			}

			.title {
				font-size: 28px;
				font-weight: bold;
				color: #333333;
			}
		}

		.imger {
			position: relative;

			.oneImg {
				border-radius: 50%;
				vertical-align: middle;
				width: 90px;
				height: 90px;
			}

			.twoImg {
				cursor: pointer;
				position: absolute;
				width: 38px;
				height: 38px;
				bottom: 4px;
				right: 1px;
			}
		}
	}

	.MEMBER {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		margin: 20px 0 20px 0;
	}

	article {
		width: 1240px;
		margin: auto;
	}
</style>