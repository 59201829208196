<template>
	<div class="xuaiaodel" id="xuexiaoss">
		<div class="eadrs">{{dats.title}}</div>
		<div class="xingxi">
			<span style="margin-right: 60px;">地址：{{dats.address}}</span>
			<span>电话：{{dats.mobile}}</span>
		</div>
		<div v-html="dats.content"></div>
		<div style="display: flex;flex-wrap: wrap;margin-top: 20px;">
			<div class="anstes" v-for="(item,index) in datalsi" :key="index">
				<img :src="item.image" alt="" />
				<div>{{item.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		xiaoDelis,
		xiaoDelistew
	} from "../../api/xingMok.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				id: '',
				dats: '',
				datalsi: []
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.getData()
			this.getListe()
		},
		methods: {
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#xuexiaoss')
				});
				let data = await xiaoDelis({
					id: this.id
				})
				if (data.code == 1) {
					this.dats = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			async getListe() {
				let data = await xiaoDelistew({
					school_id: this.id
				})
				if (data.code == 1) {
					this.datalsi = data.data.list
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.anstes:nth-child(3n) {
		margin-right: 0;
	}

	.anstes {
		margin-right: 22px;
		width: 32%;
		margin-bottom: 20px;

		&>div {
			text-align: center;
			font-size: 16px;
		}

		&>img {
			width: 100%;
		}
	}

	.xuaiaodel {
		min-height: 300px;
		margin: 30px auto 30px auto;
		width: 1180px;
		background: #ffffff;
		padding: 30px;

		.xingxi {
			margin-top: 20px;
			font-size: 16px;
		}

		.eadrs {
			font-size: 20px;
			text-align: center;
			font-weight: bold;
			color: #333333;
		}
	}
</style>