<template>
	<div class="wiherss" id="laoshixq">
		<div class="guanyu">老师详情</div>
		<div class="headers" v-if="datas">
			<div class="maesser">
				<img :src="datas.image" alt="" class="nameImg" />
			</div>
			<div class="rights">
				<div class="tits">{{datas.title}}</div>
				<div class="jieshao">{{datas.Courseteachercate.title}}</div>
				<div class="xiangq">{{datas.text}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		laoshiDelis
	} from "../../api/xingMok.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				id: '',
				datas: ''
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.getDatas()
		},
		methods: {
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#laoshixq')
				});
				let data = await laoshiDelis({
					id: this.id
				})
				if (data.code == 1) {
					this.datas = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			}

		}
	}
</script>

<style lang="scss" scoped>
	.headers {
		background: #ffffff;
		border-radius: 12px;
		width: 1200px;
		// height: 600px;
		padding: 20px;
		display: flex;

		.rights {
			.tits {
				font-size: 20px;
				color: #333333;
				font-weight: bold;
			}

			.jieshao {
				margin: 8px 0 10px 0;
				font-size: 18px;
				color: #999999;

			}

			.xiangq {
				font-size: 16px;
				color: #666666;
				line-height: 32px;
			}
		}

		.maesser {
			margin-right: 30px;
		}
	}

	.nameImg {
		width: 250px;
	}

	.guanyu {
		font-size: 20px;
		padding: 20px 0;
		font-weight: bold;
		color: #333333;
	}

	.wiherss {
		height: 460px;
		width: 1240px;
		margin: auto;
		padding-bottom: 30px;
	}
</style>