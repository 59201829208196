<template>
	<div class="maxin" id="Muhduhu">
		<!-- 标签 -->
		<div class="tiprsHer">
			<div class="lines">
				<div class="left">一级分类：</div>
				<div class="right">
					<div v-for="(item,index) in oneDat" :key="index" :class="{'bottonsre':ones== item.id}"
						@click="clickOne(item)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="lines">
				<div class="left">二级分类：</div>
				<div class="right">
					<div v-for="(item,index) in twoDat" :key="index" :class="{'bottonsre':twos== item.id}"
						@click="clickTwo(item)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="lines">
				<div class="left">课程分类：</div>
				<div class="right">
					<div v-for="(item,index) in threeDat" :key="index" :class="{'bottonsre':threes== item.id}"
						@click="clickThree(item)">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="biaoqian">
			<div class="title">全部课程</div>
			<div class="text">共找到<span style="color: #333333;">{{total}}</span>个结果</div>
		</div>
		<div class="chenKne">
			<div @click="clickChen(0)">
				<img src="../../assets/kongss .png" alt="" v-show="chenkens != 0" />
				<img src="../../assets/nankongs.png" alt="" v-show="chenkens == 0" />
				全部
			</div>
			<div @click="clickChen(1)">
				<img src="../../assets/kongss .png" alt="" v-show="chenkens != 1" />
				<img src="../../assets/nankongs.png" alt="" v-show="chenkens == 1" />
				免费
			</div>
			<div @click="clickChen(2)">
				<img src="../../assets/kongss .png" alt="" v-show="chenkens != 2" />
				<img src="../../assets/nankongs.png" alt="" v-show="chenkens == 2" />
				VIP
			</div>
		</div>
		<div class="ReuseBox" v-if="datalist.length >0">
			<div class="maxBox">
				<div class="minBox" v-for="(item,index) in datalist" :key="index" @click="routers(item.id)">
					<img :src="item.image" alt="">
					<div class="title">{{item.title}}</div>
					<div class="bottonBox">
						<div class="leftBox">
							<img src="../../assets/logo.png" alt="">
							{{item.view_text}}
						</div>
						<div class="botoness">
							<div class="vip" v-if="item.vip == 2">
								VIP
							</div>
							<div class="free" v-else>
								免费
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="zhanwuShu">
			<img src="../../assets/quesheng.png" alt="" />
			<div>暂无数据</div>
		</div>
		<!-- 分页 -->
		<div class="paging" v-show="datalist.length >0">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="28" @current-change="pagese"
				@prev-click='pearDta' @next-click='mextDat'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		keChengfen,
		keChenList,
		keChenerj,
		keCheFenl
	} from '../../api/keChen.js'
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				chenkens: 0,
				datalist: [],
				page: 1,
				total: 100,
				checkList: [],
				ones: 0,
				twos: 0,
				threes: 0,
				oneDat: [],
				twoDat: [],
				threeDat: [],
				valueTit: ''
			}
		},
		watch: { // 监听,当路由发生变化的时候执行
			'$route'() {
				if (this.$route.query.title) {
					this.valueTit = this.$route.query.title
					this.getLists()
				}

				if (this.$route.query.idsws) {
					this.twos = this.$route.query.idsws
					this.getLists()
				} else if (this.$route.query.idse) {
					this.ones = this.$route.query.idse
					this.getLists()
				}
			},
		},
		mounted() {
			if (this.$route.query.title) {
				this.valueTit = this.$route.query.title
			}
			if (this.$route.query.idse) {
				this.ones = this.$route.query.idse
			}
			if (this.$route.query.idsws) {
				this.twos = this.$route.query.idsws
			}
			this.getData()
			this.getDaters()
			this.kenCheFen()
			this.getLists()
		},
		methods: {
			// 切换
			clickChen(e) {
				this.chenkens = e
				this.getLists()
			},
			// 一级分类
			async getData() {
				let data = await keChengfen()
				this.oneDat = data.data.list
				this.oneDat.unshift({
					title: '全部',
					id: 0
				})

			},
			//二级分类
			async getDaters() {
				let data = await keChenerj()
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取二级分类
			async getDsherss() {
				let data = await keChengfen({
					pid: this.ones
				})
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 课程分类
			async kenCheFen() {
				let data = await keCheFenl()
				this.threeDat = data.data.list
				this.threeDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取列表
			async getLists() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Muhduhu')
				});
				let ser = {
					page: this.page,
					limit: 28,
					title: this.valueTit
				}
				if (this.chenkens != 0) {
					ser.vip = this.chenkens
				}
				if (this.ones) {
					ser.cid = this.ones
				} else if (this.twos) {
					ser.cid = this.twos
				}
				if (this.threes) {
					ser.type_id = this.threes
				}
				let data = await keChenList(ser)
				if (data.code == 1) {
					this.datalist = data.data.list
					this.total = data.data.total
				} else {
					this.datalist = []
					this.total = 0
				}
				this.valueTit = ''
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 跳转详情
			routers(e) {
				this.$router.push({
					path: '/zaiXingq',
					query: {
						id: e
					}
				})
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getLists()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getLists()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getLists()
			},
			clickOne(e) {
				this.ones = e.id
				this.twos = ''
				if (this.ones == 0) {
					this.getDaters()
				} else {
					this.getDsherss()
				}
				this.getLists()
			},
			clickTwo(e) {
				this.twos = e.id
				this.ones = ''
				this.getLists()
			},
			clickThree(e) {
				this.threes = e.id
				this.getLists()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.zhanwuShu {
		text-align: center;
		padding: 200px 0 220px 0;

		&>img {
			width: 200px;
			height: 100px;
		}

		&>div {
			margin-top: 10px;
			font-size: 14px;
			color: #B2B2B2;
		}
	}

	.chenKne {
		zoom: 0.7;
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #999999;

		&>div {
			cursor: pointer;
			margin-right: 24px;
		}

		img {
			width: 20px;
			height: 20px;
			vertical-align: middle;
			position: relative;
			bottom: 2px;
			margin-right: 7px;
		}
	}

	/deep/.el-checkbox__inner {
		width: 20px;
		height: 20px;
	}

	/deep/ .el-checkbox__label {
		font-size: 17px;
		color: #999999;
	}

	/deep/.el-checkbox__inner::after {
		border: 3px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 5px;
		top: 2px;
	}

	/deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
		transform: rotate(50deg) scaleY(1.3);
	}

	.paging {
		display: flex;
		justify-content: center;
		padding: 55px 0 20px 0;
		zoom: 0.76;
	}

	.ReuseBox {
		margin-top: 20px;

		.ReuseBoxsp:nth-child(3n) {
			margin-right: 0;
		}

		.ReuseBoxsp {
			margin-right: 25px;
			width: 480px;
			height: 180px;
			background: #FFFFFF;
			border-radius: 12px;
			display: flex;
			align-items: center;
			margin-bottom: 20px;



			.boxspBox {
				margin-left: 50px;

				.texts {
					font-size: 20px;
					font-weight: bold;
					color: #333333;
					width: 260px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}
			}

			img {
				width: 116px;
				height: 149px;
				margin-left: 30px;
			}
		}

		.maxBox {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.bottonBox {
				padding: 25px 20px 20px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.minBox:nth-child(4n) {
				margin-right: 0;
			}

			.minBox:hover {
				transform: translate(0, -10px);
			}

			.minBox {
				transition: all 0.3s;
				zoom: 0.8;
				margin-right: 24px;
				margin-bottom: 10px;
				width: 368px;
				background: #FFFFFF;
				border-radius: 12px;
				overflow: hidden;
				background-color: #FFFFFF;

				.leftBox {
					font-size: 16px;
					font-weight: 500;
					color: #333333;

					img {
						position: relative;
						bottom: 3px;
						width: 20px;
						height: 20px;
						vertical-align: middle;

					}
				}

				.title {
					margin-top: 5px;
					padding: 0 20px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
				}

				&>img {
					width: 368px;
					height: 270px;
				}
			}
		}
	}

	.botoness {
		display: flex;
		align-items: center;

		.vip {
			width: 90px;
			height: 32px;
			border: 1px solid #FF9000;
			border-radius: 16px;
			font-size: 18px;
			font-weight: bold;
			color: #FF9000;
			text-align: center;
			line-height: 32px;
		}

		.free {
			width: 90px;
			height: 32px;
			border: 1px solid #3B99FE;
			border-radius: 16px;
			font-size: 18px;
			font-weight: bold;
			color: #3B99FE;
			text-align: center;
			line-height: 32px;
		}
	}

	.biaoqian {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px 0 20px 0;

		.title {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}

		.text {
			font-size: 14px;
			color: #999999;
		}
	}

	.tiprsHer {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 20px 30px 10px 30px;

		.lines {
			display: flex;

			.right {
				font-size: 14px;
				color: #666666;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				&>div {
					font-size: 14px;
					margin-right: 22px;
					margin-bottom: 10px;
					padding: 0 5px;
					height: 36px;
					line-height: 36px;
				}

				.bottonsre {
					padding: 0 6px;
					height: 28px;
					background: #DEEEFF;
					border-radius: 4px;
					font-size: 14px;
					line-height: 28px;
					color: #3B99FE;
				}
			}

			.left {
				padding-top: 6px;
				min-width: 100px;
				font-size: 14px;
				color: #333333;
			}
		}
	}

	.maxin {
		padding-top: 30px;
		width: 1240px;
		margin: auto;
	}
</style>