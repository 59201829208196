<template>
	<div class="gouwu">
		<div class="title">我的购物车</div>
		<div class="linesBoox">
			<div class="ones">产品</div>
			<div class="twos">数量</div>
			<div class="threes">价格</div>
			<div class="fours">总计</div>
			<div>操作</div>
		</div>
		<div class="serlls" v-if="dataList.length >0">
			<div class="shangping" v-for="(item,index) in dataList" :key="index">
				<div class="selects">
					<img src="../../../assets/fnagdong.png" alt="" v-show="!pamnduan(item.id,ids)"
						@click="clickDEESS(item.id)" />
					<img src="../../../assets/nafnag.png" alt="" v-show="pamnduan(item.id,ids)"
						@click="clickEEs(item.id)" />
				</div>
				<div class="imgBox">
					<img :src="item.goods_image" alt="" />
				</div>
				<div class="contents">
					<div class="title">{{item.goods_title}}</div>
					<div class="text">{{item.goods_sku_text}}</div>
				</div>
				<el-input-number v-model="item.sum" @change="shuliang($event,item.id)" :min="1" :max="100"
					label="描述文字"></el-input-number>
				<div class="jiage"><span>￥</span>{{item.goods_money}}</div>
				<div class="zhongjia"><span>￥</span>{{item.money}}</div>
				<img src="../../../assets/shanc.png" alt="" class="shanchu" @click="deleSahng(item.id)" />
			</div>
			<div class="piece"></div>
		</div>
		<div class="quesheng" v-else>
			<img src="../../../assets/quesheng.png" alt="" />
			<div>暂无内容~</div>
		</div>
		<div class="poserte">
			<div class="boxBottom">
				<div class="lefts">
					<div class="bottons" @click="clickQuan">
						<img src="../../../assets/nafnag.png" alt="" v-if="statexuan" />
						<img src="../../../assets/fnagdong.png" alt="" v-else />
						全选
					</div>
					<div class="deleSp" @click="deleSahngfu">删除所选商品</div>
				</div>
				<div class="rights">已选择{{ids.length}}件商品</div>
			</div>
			<div class="heji">
				<span class="ones">合计：</span>
				<span class="twos">￥</span>
				<span class="threes">{{price}}</span>
			</div>
			<div class="tijiao">
				<div @click="routJie">提交订单</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		gouwuList,
		deleShanghg,
		gouwceJia,
		gouwceNumber
	} from "../../../api/gouWuche.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				unber: 1,
				statexuan: true,
				price: 0,
				dataList: [],
				ids: []
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			// 选中
			clickDEESS(e) {
				this.ids.push(e)
				this.jiashuan()
			},
			// 取消选中
			clickEEs(e) {
				let ser = this.ids.indexOf(e)
				this.ids.splice(ser, 1)
				this.jiashuan()
			},
			// 数量修改 
			async shuliang(e, id) {
				let data = await gouwceNumber({
					id: id,
					sum: e
				})
				if (data.code == 1) {
					this.jiashuan()
				}
			},
			// 获取列表
			async getList() {
				this.ids = []
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let data = await gouwuList({
					limit: 100
				})
				if (data.code == 1) {
					this.dataList = data.data.list
					data.data.list.forEach(item => {
						this.ids.push(item.id)
					})
					this.jiashuan()
				} else {
					this.dataList = []
					this.ids = []
					this.price = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 计算价格
			async jiashuan() {
				let data = await gouwceJia({
					id: this.ids
				})
				if (data.code == 1) {
					this.price = data.data.money
				} else {
					this.price = 0
				}
			},
			// 删除商品
			async deleSahng(e) {
				let data = await deleShanghg({
					id: e
				})
				if (data.code == 1) {
					this.getList()
				}
			},
			// 删除多个商品
			async deleSahngfu() {
				if (this.ids.length == 0) {
					this.$message.error('请选择商品');
					return
				}
				let data = await deleShanghg({
					id: this.ids
				})
				if (data.code == 1) {
					this.getList()
				}
			},
			pamnduan(e, b) {
				return b.some(ele => ele == e)
			},
			// 立即购买
			routJie() {
				let set = []
				this.ids.forEach(item => {
					this.dataList.forEach(des => {
						if (item == des.id) {
							set.push(des)
						}
					})
				})
				let data = JSON.stringify(set)
				this.$router.push({
					path: '/gouWuChes',
					query: {
						data: encodeURIComponent(data)
					}
				})
			},
			// 全选
			clickQuan() {
				if (this.statexuan) {
					this.ids = []
				} else {
					this.dataList.forEach(item => {
						this.ids.push(item.id)
					})
				}
				this.statexuan = !this.statexuan
				this.jiashuan()
			},
		}
	}
</script>

<style lang="scss" scoped>
	::-webkit-scrollbar {
		display: none;
	}

	/deep/ .el-input__inner {
		height: 41px;
	}

	/deep/ .el-input-number {
		width: 135px;
	}

	.quesheng {
		text-align: center;
		padding: 160px 0 240px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.serlls {
		height: 600px;
		overflow-y: auto;
	}

	.piece {
		height: 80px;
	}

	.poserte {
		background: #FFFFFF;
		width: 1200px;
		position: absolute;
		top: 624px;
		z-index: 666;
	}

	.tijiao {
		&>div {
			cursor: pointer;
			margin-left: 1048px;
			width: 160px;
			height: 52px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 20px;
			text-align: center;
			line-height: 52px;
			font-weight: bold;
			color: #FFFFFF;
		}
	}

	.heji {
		text-align: right;
		margin: 14px 0 26px 0;

		.ones {
			font-size: 16px;
			color: #999999;
		}

		.twos {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}

		.threes {
			font-size: 24px;
			color: #333333;
		}
	}

	.boxBottom {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 40px;
		border-top: 1px solid #E5E5E5;

		.lefts {
			display: flex;
			align-items: center;

			.deleSp {
				font-size: 16px;
				text-decoration: underline;
				color: #333333;
				margin-left: 40px;
			}

			.bottons {
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: 500;
				color: #333333;

				&>img {
					width: 26px;
					height: 26px;
					margin-right: 10px;
				}
			}
		}

		.rights {
			font-size: 16px;
			color: #999999;
		}
	}

	.shangping {
		display: flex;
		align-items: center;
		margin-bottom: 40px;

		.shanchu {
			cursor: pointer;
			width: 26px;
			height: 28px;
			margin-left: 99px;
		}

		.zhongjia {
			text-align: center;
			width: 142px;

			&>span {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}

			font-size: 24px;
			color: #333333;
		}

		.jiage {
			text-align: center;
			width: 205px;

			&>span {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}

			font-size: 24px;
			color: #333333;
		}

		.contents {
			margin-right: 80px;

			&>.title {
				width: 280px;
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

			&>.text {
				width: 270px;
				margin-top: 12px;
				font-size: 16px;
				color: #999999;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}
		}

		.imgBox {
			margin: 0 20px 0 30px;

			&>img {
				width: 120px;
				height: 120px;
			}
		}

		.selects {
			cursor: pointer;
			&>img {
				width: 26px;
				height: 26px;
			}
		}
	}

	.gouwu {
		position: relative;

		.linesBoox {
			margin-bottom: 40px;
			display: flex;
			align-items: center;
			padding: 38px 0 26px 0;
			border-bottom: 1px solid #E5E5E5;
			font-size: 16px;
			color: #999999;

			.ones {
				margin: 0 470px 0 100px;
			}

			.twos {
				margin-right: 139px;
			}

			.threes {
				margin-right: 140px;
			}

			.fours {
				margin-right: 155px;
			}
		}

		.title {
			font-size: 24px;
			font-weight: bold;
			color: #333333;
		}
	}
</style>