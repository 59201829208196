<template>
	<article>
		<div class="title">订单结算</div>
		<div class="oneBox">
			<div class="lines">
				<img src="../../assets/diz.png" alt="">
				收货地址
			</div>
			<div class="ensheshs">
				<div class="wuyuwzhu" :class="{'boajsijds':morensss == index}" v-for="(item,index) in dizhiList"
					:key="index" @click="ckucjdizhi(index)">
					<div class="namese">{{item.name}} {{item.mobile}}</div>
					<div class="cheng">{{item.address}}</div>
					<div class="xiang">{{item.detal}}</div>
					<div class="dunerws">
						<div class="left" v-if="item.status == 1">
							<div class="yuan"></div>
							<div class="moren">默认</div>
						</div>
						<div v-else></div>
						<div class="right">
							<div class="delese" @click="deleDizhi(item.id)">删除</div>
							<div class="bianji" @click="bianjis(item)">编辑</div>
						</div>
					</div>
				</div>
				<div class="defaults" @click="clcierss">
					<img src="../../assets/jias.png" alt="">
					<div>添加地址</div>
				</div>
			</div>
			<div class="lines">
				<img src="../../assets/shangp.png" alt="">
				商品信息
			</div>
			<div class="nizaiGan" v-if="datalist">
				<div class="topBoxs">
					<div class="ones">产品</div>
					<div class="twos">数量</div>
					<div class="threes">价格</div>
					<div class="fours">总计</div>
				</div>
				<div class="bottonBox">
					<div>
						<img :src="datalist.image" alt="" v-if="mioasha">
						<img :src="datalist.Goods.image" alt="" v-else>
					</div>
					<div class="characters">
						<div class="titl">{{datalist.title}}</div>
						<div class="text" v-if="datalist.guigexiang">{{datalist.guigexiang.list_text}}</div>
					</div>
					<div class="shuluangs">{{datalist.unmse}}</div>
					<div class="pricText" v-if="mioasha && datalist.guigexiang">
						<span>￥</span>
						{{datalist.guigexiang.money}}
					</div>
					<div class="pricText" v-else>
						<span>￥</span>
						{{datalist.money}}
					</div>
					<div class="pricTexts" v-if="mioasha && datalist.guigexiang">
						<span>￥</span>
						{{datalist.guigexiang.money*datalist.unmse}}
					</div>
					<div class="pricTexts" v-else>
						<span>￥</span>
						{{datalist.money *datalist.unmse}}
					</div>
				</div>
			</div>
		</div>
		<div class="twoBox">
			<div class="lineser">
				<img src="../../assets/diz.png" alt="">
				支付方式
				<span>（支付方式一旦选择/提交，无法变更）</span>
			</div>
			<div class="zhifux">
				<!-- <div :class="{'left':zhifus == 1,'right':zhifus != 1}" @click="clickOne(1)">
					<div>
						<img src="../../assets/zhifu.png" alt="">
					</div>
					<div class="zhiText">
						您可以直接使用您的支付宝账号作为本订单付款，安全可靠，便捷灵活。
					</div>
				</div> -->
				<div :class="{'left':zhifus == 1,'right':zhifus != 1}">
					<div>
						<img src="../../assets/weix.png" alt="">
					</div>
					<div class="zhiText">
						您可以选择使用移动客户端微信支付扫码功能，扫码二维码进行付款。，安全可靠，便捷灵活。
					</div>
				</div>
			</div>
			<div v-if="dizhiList.length >0">
				<div class="linesers">
					<div class="oneText">小计</div>
					<div class="twoText">￥{{miasaPric.money_count}}</div>
				</div>
				<div class="linesers">
					<div class="oneText">运费</div>
					<div class="twoText">￥{{miasaPric.yun_money}}</div>
				</div>
				<div class="lineder">
					<div class="ontts">订单总额</div>
					<div class="twoss">￥{{miasaPric.money}}</div>
				</div>
				<div class="bottonserw">
					<div class="deert" @click="buttonTap">提交订单</div>
				</div>
			</div>
		</div>
		<el-dialog :title="statess?'添加地址':'编辑地址'" :visible.sync="kaitonghui" :before-close="popuClone"
			custom-class="goumaiHui" :close-on-click-modal="false">
			<div class="popuAddzi">
				<div>
					<input type="text" placeholder="*姓名" v-model="names">
				</div>
				<div class="cascade">
					<el-select v-model="value" placeholder="请选择" @change="oneCheng">
						<el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
					<el-select v-model="value1" placeholder="请选择" @change="twoCheng">
						<el-option v-for="item in options1" :key="item.id" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
					<el-select v-model="value2" placeholder="请选择">
						<el-option v-for="item in options2" :key="item.id" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div>
					<input type="text" placeholder="*地址" v-model="dizhis">
				</div>
				<div>
					<input type="text" placeholder="*手机" maxlength="11" v-model="phones">
				</div>
				<div class="dferee">
					<el-checkbox v-model="checked">默认地址</el-checkbox>
				</div>
				<div class="popuFoter">
					<div class="lefts" @click="popuClone">取消</div>
					<div class="rights" @click="clickss">确认</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="扫码支付" :visible.sync="codeShao" :before-close="clickOpen" custom-class="goumaiHui"
			:close-on-click-modal="false">
			<div class="shaomazhif">
				<img :src="codeEwm" alt="">
				<div class="textDun">请使用微信app扫描支付，支付完成后请等待页面自动跳转。</div>
				<div class="bottomsd" @click="routerWers">支付成功,请点击</div>
			</div>
		</el-dialog>
	</article>
</template>

<script>
	import {
		addDizhi,
		getChengshi,
		getDiliest,
		deleDizhi,
		putDizhi
	} from "../../api/user.js"
	import {
		miaosPrice,
		miaoshaxia,
		miaoshazhif,
		shoppPricesa,
		shoppXiadan,
		shoppZhifu,
		shoppXiangqs
	} from "../../api/shoppIng.js"
	export default {
		data() {
			return {
				morensss: 0,
				dizhiList: [],
				dizhis: '',
				phones: '',
				options: [],
				options1: [],
				options2: [],
				names: '',
				datalist: '',
				checked: true,
				value: '',
				value1: '',
				value2: '',
				kaitonghui: false,
				codeShao: false,
				unber: 1,
				zhifus: 1,
				bianId: '',
				statess: true,
				mioasha: true,
				miasaPric: '',
				diss: '',
				requestStatus: true,
				miaosDasss: '',
				codeEwm: ''
			}
		},
		mounted() {
			this.datalist = JSON.parse(decodeURIComponent(this.$route.query.data));
			this.chengshi()
			this.getDizhi()
			if (this.$route.query.state) {
				this.mioasha = false
			}
		},
		methods: {
			// 防抖
			buttonTap() {
				if (this.requestStatus) {
					this.requestStatus = false
					if (this.mioasha === false) {
						this.miaosXiadan()
					} else {
						this.dingdanxia()
					}
				} else {
					return
				}
				setTimeout(() => {
					this.requestStatus = true;
				}, 4000);
			},
			// 订单价格
			async dingdanJiag() {
				let ser
				if (this.datalist.guigexiang) {
					ser = [{
						"gid": this.datalist.guigexiang.gid,
						"sum": this.datalist.unmse,
						"sku_id": this.datalist.guigexiang.id
					}]
				} else {
					ser = [{
						"gid": this.datalist.id,
						"sum": this.datalist.unmse,
						"sku_id": 0
					}]
				}
				let data = await shoppPricesa({
					"info": ser,
					"aid": this.dizhiList[this.morensss].id
				})
				if (data.code == 1) {
					this.miasaPric = data.data
				}
			},
			// 订单下单
			async dingdanxia() {
				let ser
				if (this.datalist.guigexiang) {
					ser = [{
						"gid": this.datalist.guigexiang.gid,
						"sum": this.datalist.unmse,
						"sku_id": this.datalist.guigexiang.id
					}]
				} else {
					ser = [{
						"gid": this.datalist.id,
						"sum": this.datalist.unmse,
						"sku_id": 0
					}]
				}
				let data = await shoppXiadan({
					"info": ser,
					"aid": this.dizhiList[this.morensss].id,
					'type': 2
				})
				if (data.code == 1) {
					this.diss = data.data
					this.dingdanzfs(data.data)
				}
			},
			// 订单支付
			async dingdanzfs(e) {
				let data = await shoppZhifu({
					oid: e
				})
				if (data.code == 1) {
					this.codeShao = true
					this.codeEwm = data.data.ewm
				}
			},
			// 秒杀价格
			async miaosJiage() {
				let data = await miaosPrice({
					seckill_id: this.datalist.id,
					sum: this.datalist.unmse,
					aid: this.dizhiList[this.morensss].id
				})
				if (data.code == 1) {
					this.miasaPric = data.data
				}
			},
			// 秒杀下单
			async miaosXiadan() {
				let data = await miaoshaxia({
					seckill_id: this.datalist.id,
					sum: this.datalist.unmse,
					aid: this.dizhiList[this.morensss].id,
					type: 2
				})
				if (data.code == 1) {
					this.diss = data.data.id
					this.miaosDasss = data.data
					this.maosaLaqidd(data.data.id)
				}
			},
			// 秒杀拉起支付
			async maosaLaqidd(e) {
				let data = await miaoshazhif({
					oid: e
				})
				if (data.code == 1) {
					this.codeShao = true
					this.codeEwm = data.data.ewm
				}
			},
			// 切换地址
			ckucjdizhi(e) {
				this.morensss = e
				if (this.mioasha == false) {
					this.miaosJiage()
				} else {
					this.dingdanJiag()
				}
			},
			oneCheng(e) {
				let set = this.options.findIndex(item => item.title === e)
				this.options1 = this.options[set].children
				this.options2 = this.options1[0].children
				this.value1 = ''
				this.value2 = ''
			},
			twoCheng(e) {
				let set = this.options1.findIndex(item => item.title === e)
				this.options2 = this.options1[set].children
				this.value2 = ''
			},
			// 打开编辑
			bianjis(e) {
				this.statess = false
				let sets = e.address.split(',')
				this.bianId = e.id
				this.names = e.name
				this.phones = e.mobile
				this.dizhis = e.detal
				this.value = sets[0]
				this.value1 = sets[1]
				this.value2 = sets[2]
				this.checked = e.status == 1 ? true : false
				this.kaitonghui = true
			},
			clickss() {
				if (this.statess) {
					this.tianjiadiz()
				} else {
					this.bianjizhi()
				}
			},
			// 编辑地址
			async bianjizhi() {
				if (this.value == '' || this.value1 == '' || this.value2 == '' || this.names == '' || this.phones ==
					'' || this.dizhis == '') {
					this.$message.error("请完善地址信息");
					return
				}
				const regex = /^1[3456789]\d{9}$/;
				let set = regex.test(this.phones);
				if (set == false) {
					this.$message.error('请输入正确手机号')
					return
				}
				let data = await putDizhi({
					id: this.bianId,
					city: this.value1,
					name: this.names,
					mobile: this.phones,
					detal: this.dizhis,
					address: this.value + ',' + this.value1 + ',' + this.value2,
					status: this.checked ? 1 : 0
				})
				if (data.code == 1) {
					this.$message({
						message: '编辑成功',
						type: 'success'
					});
					this.names = ''
					this.phones = ''
					this.dizhis = ''
					this.value = ''
					this.value1 = ''
					this.value2 = ''
					this.kaitonghui = false
					this.getDizhi()
				}
			},
			// 取消
			popuClone() {
				this.names = ''
				this.phones = ''
				this.dizhis = ''
				this.value = ''
				this.value1 = ''
				this.value2 = ''
				this.kaitonghui = false
			},
			// 删除地址
			async deleDizhi(e) {
				let data = await deleDizhi({
					id: e
				})
				if (data.code == 1) {
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					this.getDizhi()
				}
			},
			// 获取城市
			async chengshi() {
				let data = await getChengshi()
				this.options = data.data
				this.options1 = data.data[0].children
				this.options2 = data.data[0].children[0].children
			},
			// 添加地址
			async tianjiadiz() {
				if (this.value == '' || this.value1 == '' || this.value2 == '' || this.names == '' || this.phones ==
					'' || this.dizhis == '') {
					this.$message.error("请完善地址信息");
					return
				}
				const regex = /^1[3456789]\d{9}$/;
				let set = regex.test(this.phones);
				if (set == false) {
					this.$message.error('请输入正确手机号')
					return
				}
				let data = await addDizhi({
					city: this.value1,
					name: this.names,
					mobile: this.phones,
					detal: this.dizhis,
					address: this.value + ',' + this.value1 + ',' + this.value2,
					status: this.checked ? 1 : 0
				})
				if (data.code == 1) {
					this.$message({
						message: '添加成功',
						type: 'success'
					});
					this.names = ''
					this.phones = ''
					this.dizhis = ''
					this.value = ''
					this.value1 = ''
					this.value2 = ''
					this.kaitonghui = false
					this.getDizhi()
				}
			},
			// 获取地址
			async getDizhi() {
				this.morensss = 0
				let data = await getDiliest()
				if (data.code == 1) {
					this.dizhiList = data.data.list
					if (this.mioasha == false) {
						this.miaosJiage()
					} else {
						this.dingdanJiag()
					}
				} else {
					this.dizhiList = []
					this.$message.error('请添加收货地址')
				}
			},
			// 跳转成功
			async routerWers() {
				let data = await shoppXiangqs({
					id: this.diss
				})
				if (data.data.status == 1) {
					this.$message.error('请支付后，再点击');
					return
				}
				if (this.mioasha === false) {
					this.$router.push({
						path: '/succePage',
						query: {
							id: this.diss,
							data: JSON.stringify(this.miaosDasss)
						}
					})
				} else {
					this.$router.push({
						path: '/succePage',
						query: {
							id: this.diss,
							states: true
						}
					})
				}
			},
			clickOpen() {
				this.codeShao = false
			},
			clcierss() {
				this.statess = true
				this.kaitonghui = true
			},
			openGoumnai() {
				this.kaitonghui = false
			},
			clickOne(e) {
				this.zhifus = e
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-checkbox__inner {
		width: 20px;
		height: 20px;
	}

	/deep/ .el-checkbox__label {
		font-size: 17px;
		color: #999999;
		position: relative;
		top: 1px;
	}

	/deep/.el-checkbox__inner::after {
		border: 3px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 5px;
		top: 1px;
	}

	/deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
		transform: rotate(50deg) scaleY(1.3);
	}

	///////
	/deep/ .el-input-number {
		width: 140px;
	}

	/deep/ .el-input__inner {
		height: 41px;
	}

	/deep/.goumaiHui {
		width: 640px;
	}

	/deep/ .el-select {
		width: 160px;
	}

	/deep/.popuAddzi input:focus-visible {
		border-color: #3B99FE !important;
		outline: 0;
	}

	/deep/.el-input--suffix .el-input__inner {
		height: 60px;
		border-radius: 10px;
		font-size: 18px;
	}

	.shaomazhif {
		text-align: center;

		.bottomsd {
			cursor: pointer;
			width: 360px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			line-height: 60px;
			text-align: center;
			color: #FFFFFF;
			margin: 0 auto 20px auto;
		}

		.textDun {
			font-size: 18px;
			color: #333333;
			margin: 30px 0 46px 0;
		}

		img {
			width: 300px;
			height: 300px;
		}
	}

	.popuFoter {
		padding: 50px 0 20px 0;
		display: flex;
		justify-content: space-between;

		.lefts {
			width: 240px;
			height: 60px;
			border: 1px solid #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			text-align: center;
			line-height: 60px;
			color: #3B99FE;
		}

		.rights {
			width: 240px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			text-align: center;
			line-height: 60px;
			color: #FFFFFF;
		}
	}

	.popuAddzi {
		.dferee {
			padding-top: 20px;
		}

		&>div {
			width: 500px;
			margin: 0 auto 10px auto;

			&>input::placeholder {
				color: #B2B2B2;
			}
		}

		.cascade {
			display: flex;
			justify-content: space-between;
		}

		input {
			width: 474px;
			height: 60px;
			border: 1px solid #E5E5E5;
			border-radius: 12px;
			padding-left: 20px;
			font-size: 20px;
		}
	}

	.dunerws {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
		margin-top: 22px;

		.right {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #B2B2B2;

			div {
				cursor: pointer;
			}

			.delese {
				margin-right: 10px;
			}
		}

		.left {
			display: flex;
			align-items: center;

			.yuan {
				width: 6px;
				height: 6px;
				background: #3B99FE;
				border-radius: 50%;
				margin-right: 6px;
			}

			.moren {
				font-size: 16px;
				color: #3B99FE;
			}
		}

	}



	.ensheshs {
		margin-top: 30px;
		overflow-y: auto;
		display: flex;
		align-items: center;

		.wuyuwzhu {
			zoom: 0.8;
			min-width: 350px;
			height: 160px;
			background: #FFFFFF;
			border: 1px solid #e8e8e8;
			border-radius: 12px;
			margin-right: 10px;

			.xiang {
				font-size: 16px;
				color: #666666;
				margin-left: 20px;
			}

			.cheng {
				font-size: 16px;
				color: #666666;
				margin: 0 0 8px 20px;
			}

			.namese {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				margin: 16px 0 12px 20px;
			}
		}

		.boajsijds {
			border-color: #3B99FE;
		}
	}

	.bottonserw {
		display: flex;
		justify-content: right;

		.deert {
			cursor: pointer;
			zoom: 0.76;
			width: 240px;
			height: 68px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 20px;
			text-align: center;
			line-height: 68px;
			font-weight: bold;
			color: #FFFFFF;
			margin-top: 40px;
		}
	}

	.lineder {
		zoom: 0.8;
		display: flex;
		justify-content: right;
		text-align: right;

		.ontts {
			font-size: 20px;
			color: #999999;
		}

		.twoss {
			width: 170px;
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}
	}

	.linesers {
		zoom: 0.8;
		display: flex;
		justify-content: right;
		margin-bottom: 18px;
		text-align: right;

		.oneText {
			font-size: 16px;
			color: #999999;
		}

		.twoText {
			width: 170px;
			font-size: 16px;
			font-weight: bold;
			color: #333333;
		}
	}

	.zhifux {
		display: flex;
		padding-top: 36px;
		border-bottom: 1px solid #E5E5E5;
		padding-bottom: 40px;
		margin-bottom: 36px;

		.left {
			zoom: 0.8;
			width: 360px;
			height: 120px;
			border: 1px solid #232B4A;
			border-radius: 12px;
			display: flex;
			align-items: center;
			margin-right: 20px;

			.zhiText {
				font-size: 14px;
				color: #333333;
				width: 200px;
			}

			img {
				width: 88px;
				height: 30px;
				margin: 0 30px 0 20px;
			}
		}

		.right {
			zoom: 0.8;
			width: 360px;
			height: 120px;
			border: 1px solid #E5E5E5;
			border-radius: 12px;
			display: flex;
			align-items: center;
			margin-right: 20px;

			.zhiText {
				font-size: 14px;
				color: #333333;
				width: 200px;
			}

			img {
				width: 88px;
				height: 30px;
				margin: 0 30px 0 20px;
			}
		}
	}

	.twoBox {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		margin: 20px 0 60px 0;
		padding: 0 30px 30px 30px;

		.lineser {
			zoom: 0.7;
			font-size: 24px;
			font-weight: bold;
			color: #333333;
			padding-top: 30px;

			&>span {
				font-size: 14px;
				color: #999999;
			}

			img {
				width: 28px;
				height: 28px;
				position: relative;
				top: 6px;
			}
		}
	}

	.bottonBox {
		zoom: 0.8;
		display: flex;
		align-items: center;
		margin: 0 20px;

		.delese {
			width: 28px;
			height: 28px;
			margin-left: 80px;
		}

		.shuluangs {
			color: #333333;
			font-size: 18px;
			width: 100px;
			text-align: center;
			margin-left: 16px;
		}

		.pricTexts {
			color: #333333;
			font-size: 18px;
			width: 245px;
			text-align: center;
			margin-left: 36px;

			&>span {
				font-size: 16px;
				font-weight: bold;

			}
		}

		.pricText {
			color: #333333;
			font-size: 18px;
			width: 195px;
			text-align: center;
			margin-left: 120px;

			&>span {
				font-size: 16px;
				font-weight: bold;

			}
		}

		.characters {
			margin-left: 20px;
			margin-right: 88px;

			.titl {
				width: 460px;
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

			.text {
				margin-top: 20px;
				width: 290px;
				font-size: 14px;
				color: #999999;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}
		}

		img {
			width: 120px;
			height: 120px;
		}
	}

	.nizaiGan {
		padding-bottom: 40px;

		.topBoxs {
			zoom: 0.8;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #999999;
			margin: 46px 20px 20px 20px;
			border-bottom: 1px solid #E5E5E5;
			padding-bottom: 26px;

			.ones {
				margin: 0 660px 0 50px;
			}

			.twos {
				margin-right: 230px;
			}

			.threes {
				margin-right: 230px;
			}
		}
	}

	.defaults {
		cursor: pointer;
		zoom: 0.8;
		min-width: 360px;
		height: 160px;
		background: #F8F8F8;
		border-radius: 12px;
		text-align: center;

		&>div {
			font-size: 16px;
			color: #B2B2B2;
		}

		img {
			width: 28px;
			height: 28px;
			margin: 46px 0 10px 0;
		}
	}

	.oneBox {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 0 30px;

		.lines {
			zoom: 0.7;
			font-size: 24px;
			font-weight: bold;
			color: #333333;
			padding-top: 30px;

			img {
				width: 28px;
				height: 28px;
				position: relative;
				top: 5px;
			}
		}
	}

	.title {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		margin: 40px 0 30px 0;
	}

	article {
		width: 1240px;
		margin: auto;
	}
</style>