<template>
	<div class="wiherss" id="ssjdijdi">
		<div class="guanyu">师资团队</div>
		<div class="aricles" v-if="dataList.length >0">
			<div class="laoBox" v-for="(item,index) in dataList" :key="index" @click="routers(item.id)">
				<div class="leftd">
					<div class="shudfhu">
						<div class="namess">{{item.title}}</div>
						<div class="jieshao">{{item.Courseteachercate.title}}</div>
					</div>
					<img :src="item.image" alt="" />
				</div>
				<div class="rights">
					<div class="zhiwus">{{item.text}}</div>
				</div>
			</div>
		</div>
		<div v-else class="zhanwuShu">
			<img src="../../assets/quesheng.png" alt="" />
			<div>暂无数据</div>
		</div>
		<div class="paging" v-if="dataList.length >0">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="10" @current-change="pagese"
				@prev-click='pearDta' @next-click='mextDat'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		laoshiKList
	} from "../../api/xingMok.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				page: 1,
				total: 0,
				dataList: []
			}
		},
		mounted() {
			this.getDatas()
		},
		methods: {
			routers(e) {
				this.$router.push({
					path: '/laosDeliss',
					query: {
						id: e
					}
				})
			},
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#ssjdijdi')
				});
				let data = await laoshiKList({
					page: 1,
					limit: 10
				})
				if (data.code == 1) {
					this.dataList = data.data.list
					this.total = data.data.total
				} else {
					this.dataList = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getDatas()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getDatas()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getDatas()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.zhanwuShu {
		text-align: center;
		padding: 200px 0 220px 0;

		&>img {
			width: 200px;
			height: 100px;
		}

		&>div {
			margin-top: 10px;
			font-size: 14px;
			color: #B2B2B2;
		}
	}

	.paging {
		display: flex;
		justify-content: center;
		padding: 55px 0 20px 0;
		zoom: 0.76;
	}

	.aricles {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		// justify-content: space-between;

		.laoBox {
			border-radius: 8px;
			border-radius: 12px;
			background: #ffffff;
			margin-bottom: 15px;
			display: flex;
			align-items: center;

			&>.rights {
				padding: 15px;

				.zhiwus {
					color: #666666;
					font-size: 16px;
					display: -webkit-box;
					-webkit-line-clamp: 7;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}


			}

			&>.leftd {
				background: #dcdfe6;
				padding: 15px;
				// margin-right: 20px;
				display: flex;
				align-items: center;
				min-width: 350px;
				border-radius: 8px;

				.shudfhu {
					width: 198px;
					margin-right: 20px;

					.jieshao {
						text-align: right;
						margin: 8px 0 10px 0;
						color: #333333;
						font-size: 16px;
					}

					.namess {
						text-align: right;
						font-weight: bold;
						color: #333333;
						font-size: 18px;
					}
				}


				&>img {
					border-radius: 8px;
					width: 130px;
					height: 180px;
					vertical-align: middle;
				}
			}
		}
	}

	.guanyu {
		font-size: 20px;
		padding: 20px 0;
		font-weight: bold;
		color: #333333;
	}

	.wiherss {
		width: 1240px;
		margin: auto;
	}
</style>