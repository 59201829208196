const state = {
	states: false,
	popushui: false,
}
const getters = {
	getType: (state) => state.type,
}

const mutations = {
	setStates: (state, val) => {
		state.states = val
	},
	setPopushui: (state, val) => {
		state.popushui = val
	},
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}