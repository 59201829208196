<template>
	<div class="maxBox" id="Msewwwas">
		<div class="title">
			活动介绍
		</div>
		<article v-if="dataVie">
			<div class="header">
				<div>
					<img :src="dataVie.image" alt="" class="jieImg">
				</div>
				<div class="riBox">
					<div class="topLine">
						<div class="title">{{dataVie.title}}</div>
						<div class="text">{{dataVie.view_text}}</div>
					</div>
					<div class="textLine">报名时间：{{dataVie.date_text}}
					</div>
					<div class="textLine">
						活动时间：{{dataVie.time_text}}
					</div>
					<div class="textLine">
						活动地址：{{dataVie.address}}</div>
					<div class="ricFotter">
						<div class="prices"><span>￥</span>{{dataVie.money}}</div>
						<div class="botton" @click="clickbao" v-show="dataVie.is_type_text.v != 3">报名</div>
					</div>
				</div>
			</div>
			<div class="mainBox">
				<div class="headde">
					<div class="yuan"></div>
					<div class="text">活动介绍</div>
				</div>
				<div class="textGata">
					<div v-html="dataVie.content"></div>
				</div>
			</div>
		</article>
		<el-dialog title="请填写报名资料" :visible.sync="zhucDaat" :before-close="openZhuce" custom-class="zhuceClass"
			:close-on-click-modal="false">
			<div class="popDat">
				<div class="ahshhss"><input type="text" v-model="names" placeholder="*请输入姓名"></div>
				<div class="hengtiao">
				</div>
				<div class="ahshhss"><input type="text" maxlength="11" v-model="zhuIphoe" placeholder="*请输入手机号"></div>
				<div class="hengtiaos">
				</div>
				<div class="bottLogin" @click="clickTijiao">
					提交资料
				</div>
				<div class="hengtiao">
				</div>
			</div>
		</el-dialog>
		<el-dialog title="请确认订单" :visible.sync="dataAnse" :before-close="openQuern" custom-class="zhuceClass"
			:close-on-click-modal="false">
			<div class="popuPrice">
				<div class="minasBox">
					<div class="tanxing">
						<div class="one">{{dataVie.title}}</div>
						<div class="two">￥{{dataVie.money}}</div>
					</div>
					<div class="delText">报名时间：{{dataVie.time_text}}</div>
					<div class="delText">活动时间：{{dataVie.start_date}}-{{dataVie.end_date}}</div>
					<div class="delText">活动地址：{{dataVie.address}}</div>
				</div>
				<div class="tupian" v-if="ewmImgs">
					<img :src="ewmImgs" alt="">
				</div>
				<div class="shaopma">
					<img src="../../assets/weixing.png" alt="">
					扫码支付
				</div>
				<div class="jiage">￥{{dataVie.money}}</div>
				<div class="tishi" @click="yonghuxieyi">
					*支付视为您同意《谊华教育用户协议》
				</div>
				<div class="botton" @click="wancdianj">
					支付完成,请点击
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		huoDongDeli,
		huoddoBaomin,
		huodngYan,
		huoddoLaqi,
	} from "../../api/huoDong.js"
	import {
		myHuodongdelsi
	} from "../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				zhucDaat: false,
				dataAnse: false,
				names: '',
				zhuIphoe: '',
				id: '',
				dataVie: '',
				ewmImgs: '',
				dingdId: ''
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.getData()
		},
		methods: {
			// 完成点击
			async wancdianj() {
				let data = await myHuodongdelsi({
					id: this.dingdId
				})
				if (data.code == 1) {
					if (data.data.status !== 1) {
						this.$message({
							message: '报名成功',
							type: 'success'
						});
						setTimeout(() => {
							this.$router.push('/huoDon')
						}, 1500)
					} else {
						this.$message.error('请支付后，再点击');
					}
				}
			},
			yonghuxieyi() {
				this.dataAnse = false
				this.$router.push("/yongHxyi")
			},
			// 获取详情
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Msewwwas')
				});
				let data = await huoDongDeli({
					id: this.id
				})
				if (data.code == 1) {
					this.dataVie = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 打开报名
			async clickbao() {
				if (this.$store.state.user.token) {
					let data = await huodngYan({
						id: this.dataVie.id
					})
					if (data.data == 1) {
						this.zhucDaat = true
					} else if (data.data == 2 && data.code == 1) {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 提交资料
			async clickTijiao() {
				const regex = /^1[3456789]\d{9}$/;
				let set = regex.test(this.zhuIphoe);
				if (set == false) {
					this.$message.error('请输入正确手机号')
					return
				}
				if (this.names == '' || this.zhuIphoe == '') {
					this.$message.error("请完善报名信息");
					return
				}
				let data = await huoddoBaomin({
					id: this.dataVie.id,
					name: this.names,
					mobile: this.zhuIphoe,
					type: 2
				})
				if (data.code == 1) {
					this.dingdId = data.data
					this.laiqZzhif(data.data)
					this.zhucDaat = false
					this.dataAnse = true
				}
			},
			// 拉起支付
			async laiqZzhif(e) {
				let data = await huoddoLaqi({
					oid: e
				})
				if (data.code == 1) {
					this.ewmImgs = data.data.ewm
				}
			},
			openZhuce() {
				this.zhucDaat = false
			},
			openQuern() {
				this.dataAnse = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.zhuceClass>.el-dialog__header {
		text-align: center;
	}

	/deep/.zhuceClass>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	/deep/.zhuceClass {
		width: 640px;
	}

	.popuPrice {
		.botton {
			width: 500px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
			margin: 30px auto 30px auto;
		}

		.tishi {
			font-size: 16px;
			text-align: center;
			color: #B2B2B2;
		}

		.shaopma {
			font-size: 16px;
			text-align: center;
			color: #999999;

			&>img {
				width: 23px;
				height: 22px;
				vertical-align: middle;
			}
		}

		.jiage {
			font-size: 32px;
			font-weight: bold;
			color: #FF8400;
			text-align: center;
			margin-top: 10px;
			margin-bottom: 15px;
		}

		.tupian {
			text-align: center;
			padding-top: 20px;

			&>img {
				width: 140px;
				height: 140px;
			}
		}

		.minasBox {
			width: 524px;
			height: 114px;
			background: #F8F8F8;
			border-radius: 12px;
			padding: 22px 30px 30px 30px;
			margin: auto;

			.delText {
				font-size: 14px;
				color: #333333;
				margin-top: 10px;
			}

			.tanxing {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.one {
					font-size: 24px;
					font-weight: bold;
					color: #333333;
				}

				.two {
					font-size: 32px;
					font-weight: bold;
					color: #FF8400;
				}
			}
		}
	}

	.popDat {
		width: 500px;
		margin: auto;



		.zhangha {
			margin-top: 30px;
			text-align: center;
			font-size: 16px;
			color: #B2B2B2;
		}

		.bottLogin {
			width: 500px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			color: #FFFFFF;
			line-height: 60px;
			text-align: center;
		}

		.hengtiao {
			height: 20px;
		}

		.hengtiaos {
			height: 60px;
		}

		.ahshhss {
			width: 470px;
			height: 68px;
			border: 1px solid #E5E5E5;
			border-radius: 12px;
			display: flex;
			align-items: center;
			padding-left: 30px;

			input {
				flex: 1;
				box-shadow: none;
				border: none;
				outline: none;
				font-size: 18px;
			}
		}
	}

	.ricFotter {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.botton {
			cursor: pointer;
			zoom: 0.78;
			width: 120px;
			height: 56px;
			background: #3B99FE;
			border-radius: 28px;
			text-align: center;
			line-height: 56px;
			font-size: 24px;
			color: #FFFFFF;
		}

		.prices {
			font-size: 28px;
			font-weight: bold;
			color: #FF8400;

			&>span {
				font-size: 28px;
			}
		}
	}

	.topLine {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 36px;

		.text {
			font-size: 16px;
			color: #999999;
		}

		.title {
			font-size: 22px;
			font-weight: bold;
			color: #333333;
		}
	}

	article {
		width: 1180px;
		padding: 30px;
		background: #FFFFFF;
		border-radius: 12px;

		.mainBox {
			width: 1120px;
			background: #F8F8F8;
			border-radius: 12px;
			margin-top: 50px;
			padding: 30px;

			.textGata {
				font-size: 16px;
				color: #666666;
				line-height: 32px;
			}

			.headde {
				display: flex;
				align-items: center;
				margin-bottom: 30px;

				.yuan {
					width: 6px;
					height: 6px;
					background: #3B99FE;
					border-radius: 50%;
					margin-right: 10px;
				}

				.text {
					font-size: 20px;
					font-weight: bold;
					color: #333333
				}
			}
		}

		.header {
			display: flex;

			.riBox {
				flex: 1;
				margin-left: 36px;

				.textLine {
					font-size: 16px;
					color: #333333;
					margin-bottom: 15px;
				}
			}

			.jieImg {
				zoom: 0.7;
				width: 540px;
				height: 320px;
				border-radius: 10px;
			}
		}
	}

	.maxBox {
		width: 1240px;
		margin: auto;
		padding-bottom: 40px;

		&>.title {
			font-size: 24px;
			font-weight: bold;
			color: #333333;
			padding: 40px 0 40px 0;
		}
	}
</style>