import request from "../utils/request.js"

// 一级分类
export function keChengfen(data) {
	return request({
		url: "/course.course/get_cate",
		method: "post",
		data
	});
}
// 二级分类
export function keChenerj(data) {
	return request({
		url: "/course.course/get_cate_node",
		method: "post",
		data
	});
}
// 课程类型
export function keCheFenl() {
	return request({
		url: "/course.course/get_type",
		method: "post",
	});
}
// 课程列表
export function keChenList(data) {
	return request({
		url: "/course.course/get_list",
		method: "post",
		data
	});
}
// 课程详情
export function keCheDelis(data) {
	return request({
		url: "/course.course/get_view",
		method: "post",
		data
	});
}
// 验证是否可以学习
export function yanZhengxue(data) {
	return request({
		url: "/course.course/info_check",
		method: "post",
		data
	});
}
// 提交学习
export function tiJiaoxue(data) {
	return request({
		url: "/course.course/info_add",
		method: "post",
		data
	});
}