<template>
	<div>
		<div class="titles">
			<div>默认地址</div>
			<div class="botteons" @click="clcierss">新增地址</div>
		</div>
		<div class="moren" v-if="moremDi">
			<div class="leBox">
				<div class="lines" style="margin-top: 0;">
					<div class="let">姓名：</div>
					<div class="righ">{{moremDi.name}}</div>
				</div>
				<div class="lines">
					<div class="let">手机/电话：</div>
					<div class="righ">{{moremDi.mobile}}</div>
				</div>
				<div class="lines">
					<div class="let">所在地区：</div>
					<div class="righ">{{moremDi.address}}</div>
				</div>
				<div class="lines">
					<div class="let">详细地址：</div>
					<div class="righ">{{moremDi.detal}}</div>
				</div>
			</div>
			<div class="rigBox">
				<div @click="bianjis(moremDi)">
					<img src="../../../assets/bianj.png" alt="" />
					编辑
				</div>
				<div class="twos" @click="deleDizhi(moremDi.id)">
					<img src="../../../assets/ashan.png" alt="" />
					删除
				</div>
			</div>
		</div>
		<div class="zhanewus" v-else>
			暂无地址
		</div>
		<div class="qitasi">其他地址</div>
		<div class="moren" style="border-color: #B2B2B2;" v-for="(item,index) in dizhiList" :key="index"
			v-show="item.status == 0">
			<div class="leBox">
				<div class="lines" style="margin-top: 0;">
					<div class="let">姓名：</div>
					<div class="righ">{{item.name}}</div>
				</div>
				<div class="lines">
					<div class="let">手机/电话：</div>
					<div class="righ">{{item.mobile}}</div>
				</div>
				<div class="lines">
					<div class="let">所在地区：</div>
					<div class="righ">{{moremDi.address}}</div>
				</div>
				<div class="lines">
					<div class="let">详细地址：</div>
					<div class="righ">{{item.detal}}</div>
				</div>
			</div>
			<div class="rigBox">
				<div @click="bianjis(item)">
					<img src="../../../assets/bianj.png" alt="" />
					编辑
				</div>
				<div class="twos" @click="deleDizhi(item.id)">
					<img src="../../../assets/ashan.png" alt="" />
					删除
				</div>
			</div>
		</div>
		<div class="zhanewus" v-if="dizhiList.length == 0">
			暂无地址
		</div>
		<el-dialog :title="statess?'添加地址':'编辑地址'" :visible.sync="kaitonghui" :before-close="popuClone"
			custom-class="goumaiHui" :close-on-click-modal="false">
			<div class="popuAddzi">
				<div>
					<input type="text" placeholder="*姓名" v-model="names">
				</div>
				<div class="cascade">
					<el-select v-model="value" placeholder="请选择" @change="oneCheng" :popper-append-to-body="false">
						<el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
					<el-select v-model="value1" placeholder="请选择" @change="twoCheng" :popper-append-to-body="false">
						<el-option v-for="item in options1" :key="item.id" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
					<el-select v-model="value2" placeholder="请选择" :popper-append-to-body="false">
						<el-option v-for="item in options2" :key="item.id" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div>
					<input type="text" placeholder="*地址" v-model="dizhis">
				</div>
				<div>
					<input type="text" placeholder="*手机" maxlength="11" v-model="phones">
				</div>
				<div class="dferee">
					<el-checkbox v-model="checked">默认地址</el-checkbox>
				</div>
				<div class="popuFoters">
					<div class="lefts" @click="popuClone">取消</div>
					<div class="rights" @click="clickss">确认</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		addDizhi,
		getChengshi,
		getDiliest,
		deleDizhi,
		putDizhi,
		morenizhi
	} from "../../../api/user.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				statess: true,
				value: '',
				value1: '',
				value2: '',
				morensss: 0,
				dizhiList: [],
				dizhis: '',
				phones: '',
				options: [],
				options1: [],
				options2: [],
				names: '',
				checked: true,
				kaitonghui: false,
				moremDi: ''
			}
		},
		mounted() {
			this.morenDizhi()
			this.chengshi()
			this.getDizhi()
		},
		methods: {
			oneCheng(e) {
				let set = this.options.findIndex(item => item.title === e)
				this.options1 = this.options[set].children
				this.options2 = this.options1[0].children
				this.value1 = ''
				this.value2 = ''
			},
			twoCheng(e) {
				let set = this.options1.findIndex(item => item.title === e)
				this.options2 = this.options1[set].children
				this.value2 = ''
			},
			// 默认地址
			async morenDizhi() {
				let data = await morenizhi()
				if (data.code == 1) {
					this.moremDi = data.data
				} else {
					this.moremDi = ''
				}
			},
			// 打开编辑
			bianjis(e) {
				this.statess = false
				let sets = e.address.split(',')
				this.bianId = e.id
				this.names = e.name
				this.phones = e.mobile
				this.dizhis = e.detal
				this.value = sets[0]
				this.value1 = sets[1]
				this.value2 = sets[2]
				this.checked = e.status == 1 ? true : false
				this.kaitonghui = true
			},
			clickss() {
				if (this.statess) {
					this.tianjiadiz()
				} else {
					this.bianjizhi()
				}
			},
			// 编辑地址
			async bianjizhi() {
				if (this.value == '' || this.value1 == '' || this.value2 == '' || this.names == '' || this.phones ==
					'' || this.dizhis == '') {
					this.$message.error("请完善地址信息");
					return
				}
				const regex = /^1[3456789]\d{9}$/;
				let set = regex.test(this.phones);
				if (set == false) {
					this.$message.error('请输入正确手机号')
					return
				}
				let data = await putDizhi({
					id: this.bianId,
					city: this.value1,
					name: this.names,
					mobile: this.phones,
					detal: this.dizhis,
					address: this.value + ',' + this.value1 + ',' + this.value2,
					status: this.checked ? 1 : 0
				})
				if (data.code == 1) {
					this.$message({
						message: '添加成功',
						type: 'success'
					});
					this.names = ''
					this.phones = ''
					this.dizhis = ''
					this.value = ''
					this.value1 = ''
					this.value2 = ''
					this.kaitonghui = false
					this.getDizhi()
					this.morenDizhi()
				}
			},
			// 删除地址
			async deleDizhi(e) {
				let data = await deleDizhi({
					id: e
				})
				if (data.code == 1) {
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					this.getDizhi()
					this.morenDizhi()
				}
			},
			// 获取城市
			async chengshi() {
				let data = await getChengshi()
				this.options = data.data
				this.options1 = data.data[0].children
				this.options2 = data.data[0].children[0].children
			},
			// 添加地址
			async tianjiadiz() {
				if (this.value == '' || this.value1 == '' || this.value2 == '' || this.names == '' || this.phones ==
					'' || this.dizhis == '') {
					this.$message.error("请完善地址信息");
					return
				}
				const regex = /^1[3456789]\d{9}$/;
				let set = regex.test(this.phones);
				if (set == false) {
					this.$message.error('请输入正确手机号')
					return
				}
				let data = await addDizhi({
					name: this.names,
					city: this.value1,
					mobile: this.phones,
					detal: this.dizhis,
					address: this.value + ',' + this.value1 + ',' + this.value2,
					status: this.checked ? 1 : 0
				})
				if (data.code == 1) {
					this.$message({
						message: '添加成功',
						type: 'success'
					});
					this.names = ''
					this.phones = ''
					this.dizhis = ''
					this.value = ''
					this.value1 = ''
					this.value2 = ''
					this.kaitonghui = false
					this.getDizhi()
					this.morenDizhi()
				}
			},
			// 获取地址
			async getDizhi() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				this.morensss = 0
				let data = await getDiliest()
				if (data.code == 1) {
					this.dizhiList = data.data.list
				} else {
					this.dizhiList = []
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			clcierss() {
				this.statess = true
				this.kaitonghui = true
			},
			bianji() {
				console.log('oihjoihiohh')
				this.kaitonghui = true
			},
			// 取消
			popuClone() {
				this.names = ''
				this.phones = ''
				this.dizhis = ''
				this.value = ''
				this.value1 = ''
				this.value2 = ''
				this.kaitonghui = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-popper {
		zoom: 1 !important;
	}

	/deep/ .el-dialog {
		zoom: 0.8 !important;
	}

	/deep/.goumaiHui {
		width: 640px;
	}

	/deep/.el-checkbox__inner {
		width: 20px;
		height: 20px;
	}

	/deep/ .el-select {
		width: 160px;
	}

	/deep/.popuAddzi input:focus-visible {
		border-color: #3B99FE !important;
		outline: 0;
	}

	/deep/.el-input--suffix .el-input__inner {
		height: 60px;
		border-radius: 10px;
		font-size: 18px;
	}

	/deep/ .el-checkbox__label {
		font-size: 17px;
		color: #999999;
		position: relative;
		top: 1px;
	}

	/deep/.el-checkbox__inner::after {
		border: 3px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 5px;
		top: 1px;
	}

	/deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
		transform: rotate(50deg) scaleY(1.3);
	}

	/deep/.shangping {
		width: 640px;
	}

	/deep/.shangping>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	/deep/.shangping>.el-dialog__header {
		text-align: center;
	}

	.zhanewus {
		text-align: center;
		color: #919191;
		padding: 60px 0;
	}

	.popuFoters {
		padding: 50px 0 20px 0;
		display: flex;
		justify-content: space-between;

		.lefts {
			width: 240px;
			height: 60px;
			border: 1px solid #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			text-align: center;
			line-height: 60px;
			color: #3B99FE;
		}

		.rights {
			width: 240px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			text-align: center;
			line-height: 60px;
			color: #FFFFFF;
		}
	}

	.popuAddzi {
		.dferee {
			padding-top: 20px;
		}

		&>div {
			width: 500px;
			margin: 0 auto 10px auto;

			&>input::placeholder {
				color: #B2B2B2;
			}
		}

		.cascade {
			display: flex;
			justify-content: space-between;
		}

		input {
			width: 474px;
			height: 60px;
			border: 1px solid #E5E5E5;
			border-radius: 12px;
			padding-left: 20px;
			font-size: 18px;
		}
	}

	.popuFoter {
		display: flex;
		justify-content: center;
		margin: 30px 0 30px 0;

		.zaiXiang {
			width: 240px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			line-height: 60px;
			text-align: center;
			color: #FFFFFF;
		}

		.queREen {
			font-size: 18px;
			line-height: 60px;
			text-align: center;
			color: #3B99FE;
			width: 240px;
			height: 60px;
			border: 1px solid #3B99FE;
			border-radius: 12px;
			margin-right: 20px;
		}
	}

	.popuTitlr {
		text-align: center;
		font-size: 28px;
		color: #999999;
	}

	.poputishi {
		font-size: 26px;
		color: #333333;
	}

	.qitasi {
		font-size: 28px;
		margin: 40px 0 20px 0;
		font-weight: bold;
		color: #333333;
	}

	.moren {
		width: 1200px;
		height: 168px;
		border: 1px solid #333333;
		border-radius: 12px;
		margin: 28px 0 38px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.rigBox {
			margin-right: 46px;

			.twos {
				margin-top: 16px;
			}

			&>div {
				cursor: pointer;
				font-size: 16px;
				color: #999999;



				img {
					width: 20px;
					height: 20px;
					vertical-align: middle;
					position: relative;
					bottom: 1px;
				}
			}
		}

		.lines {
			display: flex;
			align-items: center;
			font-size: 16px;
			margin-top: 12px;

			.let {
				padding-right: 5px;
				text-align: right;
				width: 124px;
				color: #999999;
			}

			.righ {
				color: #333333;
			}
		}
	}

	.titles {
		font-size: 28px;
		font-weight: bold;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&>.botteons {
			width: 120px;
			height: 44px;
			background: #3B99FE;
			font-size: 20px;
			border-radius: 12px;
			font-weight: bold;
			color: #FFFFFF;
			line-height: 44px;
			text-align: center;
		}
	}
</style>