import request from "../utils/request.js"

// 商城首页
export function shoppHome(data) {
	return request({
		url: "/goods.index/get_index_pc",
		method: "post",
		data
	});
}
// 一级分类
export function shoppYji(data) {
	return request({
		url: "/goods.index/get_cate",
		method: "post",
		data
	});
}
// 二级分类
export function shoppErji(data) {
	return request({
		url: "/goods.index/get_cate_node",
		method: "post",
		data
	});
}
// 商品列表
export function shoppLists(data) {
	return request({
		url: "/goods.index/get_list",
		method: "post",
		data
	});
}
// 商城详情
export function shoppDelis(data) {
	return request({
		url: "/goods.index/get_view",
		method: "post",
		data
	});
}
// 秒杀详情
export function miaoshaDeli(data) {
	return request({
		url: "/play.seckill/get_view",
		method: "post",
		data
	});
}
// 规格列表
export function shoppGuiList(data) {
	return request({
		url: "/goods.index/sku_list",
		method: "post",
		data
	});
}
// 规格详情
export function shoppGuiDeli(data) {
	return request({
		url: "/goods.index/sku_view",
		method: "post",
		data
	});
}
// 加入购物车
export function addGouwce(data) {
	return request({
		url: "/goods.cart/get_add",
		method: "post",
		data
	});
}
// 秒杀支付价格
export function miaosPrice(data) {
	return request({
		url: "/play.seckill/get_money",
		method: "post",
		data
	});
}
//  秒杀下单
export function miaoshaxia(data) {
	return request({
		url: "/play.seckill/get_add",
		method: "post",
		data
	});
}
//  秒杀支付
export function miaoshazhif(data) {
	return request({
		url: "/play.seckill/get_wx_order",
		method: "post",
		data
	});
}
//  下单
export function shoppXiadan(data) {
	return request({
		url: "/goods.order/get_add",
		method: "post",
		data
	});
}
//  拉起支付
export function shoppZhifu(data) {
	return request({
		url: "/goods.order/get_wx_order",
		method: "post",
		data
	});
}
//  订单计算价格
export function shoppPricesa(data) {
	return request({
		url: "/goods.order/get_money",
		method: "post",
		data
	});
}
//  订单详情
export function shoppXiangqs(data) {
	return request({
		url: "/goods.order/get_view",
		method: "post",
		data
	});
}