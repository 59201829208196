<template>
	<div>
		<div class="titles">
			<div>我的团队</div>
			<div @click="fanhyi" style="font-size: 18px;">返回 ></div>
		</div>
		<div class="lineser">
			<div class="lefts">
				<div>
					<img src="../../../assets/code.png" alt="" />
				</div>
				<div class="derferf">
					<div class="tit">{{datae.nickname}}</div>
					<div class="tex">{{datae.group_title}}（ID：{{datae.id}}）</div>
				</div>
			</div>
			<div class="rights">
				<div class="number">{{datae.order_count_all}}</div>
				<div class="text">总订单量</div>
			</div>
		</div>
		<div class="tipser">
			<div class="lefts">
				<div :class="{'bjyanse':valus == 1}" @click="clickIn(1)">商城订单</div>
				<div :class="{'bjyanse':valus == 2}" @click="clickIn(2)">活动订单</div>
			</div>
			<div class="rights">
				<img src="../../../assets/sjpuss.png" alt="" />
				<input type="text" placeholder="请输入订单编号" v-model="dingdamBian" />
			</div>
			<div class="shoushu" @click="shoushuoss">搜索</div>
		</div>
		<div class="linesser">
			<div class="ones">订单编号</div>
			<div class="twos">下单时间</div>
			<div class="threes">订单总额</div>
			<div class="fours">订单状态</div>
			<div class="fivrs">
				佣金提成
			</div>
			<div>订单详情</div>
		</div>
		<div v-if="dataList.length >0">
			<div class="minLine" v-for="(item,index) in dataList" :key="index">
				<div class="ons">{{item.order_up}}</div>
				<div class="tws">{{item.create_time}}</div>
				<div class="thrs">￥{{item.money}}</div>
				<div class="fos">{{item.status_text}}</div>
				<div class="fis">
					￥{{item.d_money}}
				</div>
				<div class="chakan" @click="shangcheng(item.id)">查看</div>
			</div>
			<div class="paging">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
					@current-change="pagese" @prev-click='pearDta' @next-click='mextDat'>
				</el-pagination>
			</div>
		</div>
		<!-- 商城订单 -->
		<div class="quesheng" v-else>
			<img src="../../../assets/quesheng.png" alt="" />
			<div>暂无内容~</div>
		</div>
		<el-dialog title="订单详情" :visible.sync="kaitonghui" custom-class="goumaiHui" :close-on-click-modal="false">
			<div v-if="xiangqings">
				<div class="tupans">
					<img :src="xiangqings.Goodsorderinfo[0].goods_image" alt="" />
				</div>
				<div class="popuser">
					{{xiangqings.Goodsorderinfo[0].goods_title}}
				</div>
				<div class="fubiaot">
					{{xiangqings.Goodsorderinfo[0].goods_sku_text}}
				</div>
				<div class="hengdeee">
					<div class="ones">订单编号</div>
					<div class="twos">
						{{xiangqings.order_up}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">下单时间</div>
					<div class="twos">
						{{xiangqings.create_time}}
					</div>
				</div>
				<!-- <div class="hengdeee">
					<div class="ones">数量</div>
					<div class="twos">
						{{xiangqings.create_time}}
					</div>
				</div> -->
				<div class="hengdeee">
					<div class="ones">订单总额</div>
					<div class="twos">
						{{xiangqings.money}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">支付方式</div>
					<div class="twos">
						{{xiangqings.type_text}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">订单状态</div>
					<div class="twos">
						{{xiangqings.status_text}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">佣金提成</div>
					<div class="twos" style="color: #F5B53A;">
						￥{{xiangqings.de_money}}
					</div>
				</div>
				<div class="botterss" @click="kaitonghui = false">
					返回
				</div>
			</div>
		</el-dialog>
		<el-dialog title="订单详情" :visible.sync="serdddsss" custom-class="goumaiHui" :close-on-click-modal="false">
			<div v-if="huodongDELIS">
				<div class="tupans">
					<img :src="huodongDELIS.Coursebag.image" alt="" />
				</div>
				<div class="popuser">
					{{huodongDELIS.title}}
				</div>
				<!-- <div class="fubiaot">
					报名时间：{{huodongDELIS.Coursebag.date_text}}
				</div>
				<div class="fubiaot">
					活动时间：{{huodongDELIS.Coursebag.time_text}}
				</div>
				<div class="fubiaot">
					活动地址：{{huodongDELIS.Coursebag.address}}
				</div> -->
				<div class="hengdeee">
					<div class="ones">报名信息</div>
					<div class="twos">
						{{huodongDELIS.name}} {{huodongDELIS.mobile}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">订单编号</div>
					<div class="twos">
						{{huodongDELIS.order_up}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">下单时间</div>
					<div class="twos">
						{{huodongDELIS.create_time}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">订单总额</div>
					<div class="twos">
						{{huodongDELIS.money}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">支付方式</div>
					<div class="twos">
						{{huodongDELIS.type_text}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">订单状态</div>
					<div class="twos">
						{{huodongDELIS.status_text}}
					</div>
				</div>
				<div class="hengdeee">
					<div class="ones">佣金提成</div>
					<div class="twos" style="color: #F5B53A;">
						￥ {{huodongDELIS.d_money}}
					</div>
				</div>
				<div class="botterss" @click="serdddsss = false">
					返回
				</div>

			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		shangcListse,
		huodongListse,
		shangchengDeli,
		hudongDelis
	} from "../../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				huodongDELIS: '',
				serdddsss: false,
				kaitonghui: false,
				total: 0,
				page: 1,
				valus: 1,
				datae: '',
				dataList: '',
				xiangqings: '',
				dingdamBian: ''
			}
		},
		watch: {
			datae() {
				this.getLists()
			}
		},
		methods: {
			// 搜索
			shoushuoss() {
				this.page = 1
				if (this.valus == 1) {
					this.getLists()
				} else {
					this.huodngList()
				}
			},
			// 商城
			async getLists() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let data = await shangcListse({
					id: this.datae.id,
					page: this.page,
					limit: 10,
					title: this.dingdamBian
				})
				if (data.code == 1) {
					this.dataList = data.data.list
					this.total = data.data.total
				} else {
					this.dataList = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 活动
			async huodngList() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let data = await huodongListse({
					id: this.datae.id,
					page: this.page,
					limit: 10,
					title: this.dingdamBian
				})
				if (data.code == 1) {
					this.dataList = data.data.list
					this.total = data.data.total
				} else {
					this.dataList = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 查看
			async shangcheng(e) {
				console.log(this.valus == 1, this.valus)
				if (this.valus == 1) {
					// 商城订单详情
					let data = await shangchengDeli({
						id: e
					})
					if (data.code == 1) {
						this.xiangqings = data.data
						this.kaitonghui = true
					}
				} else {
					// 活动订单详情
					let data = await hudongDelis({
						id: e
					})
					if (data.code == 1) {
						this.huodongDELIS = data.data
						this.serdddsss = true
					}
				}
			},
			openGoumnai() {
				this.kaitonghui = false
			},
			fanhyi() {
				this.$emit('niganma', '')
			},
			// 上一页
			pearDta(e) {
				console.log(e)
			},
			// 下一页
			mextDat(e) {
				console.log(e)
			},
			// 跳页
			pagese(e) {
				console.log(e)
			},
			// 切换
			clickIn(e) {
				this.valus = e
				this.page = 1
				this.dingdamBian = ''
				if (e == 1) {
					this.getLists()
				} else {
					this.huodngList()
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.goumaiHui {
		width: 640px;
	}

	.botterss {
		margin: 40px auto 10px auto;
		width: 500px;
		height: 60px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 18px;
		text-align: center;
		line-height: 60px;
		color: #FFFFFF;
	}

	.hengdeee {
		display: flex;
		align-items: center;
		margin-top: 30px;

		.ones {
			margin-left: 200px;
			font-size: 18px;
			color: #B2B2B2;
			width: 100px;
		}

		.twos {
			font-size: 18px;
			color: #333333;
		}
	}

	.fubiaot {
		text-align: center;
		font-size: 16px;
		color: #999999;
	}

	.quesheng {
		text-align: center;
		padding: 200px 0 240px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.popuser {
		margin: 20px 0;
		font-size: 20px;
		text-align: center;
		font-weight: bold;
		color: #333333;
	}

	.tupans {
		text-align: center;

		img {
			width: 200px;
			height: 120px;
		}
	}

	.paging {
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}

	.minLine {
		margin-top: 28px;
		display: flex;
		font-size: 16px;
		color: #999999;

		.chakan {
			color: #3B99FE;
		}

		.fis {
			text-align: center;
			width: 140px;
			margin-right: 280px;
			color: #F5B53A;
		}

		.fos {
			text-align: center;
			width: 180px;
		}

		.thrs {
			text-align: center;
			width: 160px;
		}

		.tws {
			text-align: center;
			width: 228px;
		}

		.ons {
			text-align: center;
			width: 122px;
			margin-right: 20px;
		}
	}

	.linesser {
		margin-top: 40px;
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		color: #333333;

		.ones {
			margin: 0 130px 0 30px;
		}

		.twos {
			margin-right: 130px;
		}

		.threes {
			margin-right: 110px;
		}

		.fours {
			margin-right: 99px;
		}

		.fivrs {
			margin-right: 300px;
		}
	}

	.tipser {
		display: flex;
		align-items: center;
		margin-top: 30px;
		justify-content: space-between;

		.shoushu {
			width: 120px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			font-weight: bold;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
		}

		.rights {
			width: 500px;
			height: 60px;
			background: #F8F8F8;
			border-radius: 12px;
			display: flex;
			align-items: center;

			&>input {
				font-size: 18px;
				outline: none;
				border: none;
				background-color: #F8F8F8;
			}

			&>img {
				width: 28px;
				height: 28px;
				margin: 0 10px 0 19px;
			}
		}

		.lefts {
			padding: 0 8px;
			width: 544px;
			height: 60px;
			background: #F8F8F8;
			border-radius: 12px;
			display: flex;
			align-items: center;

			.bjyanse {
				background: #ffffff;
			}

			&>div {
				width: 180px;
				height: 48px;
				border-radius: 8px;
				text-align: center;
				line-height: 48px;
				font-size: 18px;
				font-weight: bold;
				color: #333333;
			}
		}
	}

	.lineser {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 38px;

		.rights {
			text-align: center;
			padding-right: 26px;

			.number {
				font-size: 48px;
				font-weight: bold;
				color: #3B99FE;
			}

			.text {
				font-size: 16px;
				color: #B2B2B2;
			}
		}

		.lefts {
			display: flex;
			align-items: center;

			.derferf {
				margin-left: 19px;

				.tit {
					font-size: 28px;
					font-weight: bold;
					color: #333333;
				}

				.tex {
					font-size: 16px;
					margin-top: 16px;
					color: #333333;
				}
			}

			img {
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}
		}

	}

	.titles {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 28px;
		font-weight: bold;
		color: #333333;
	}
</style>