<template>
	<div class="whole" ref="scrollWrapper" @click="openClisLas">
		<div class="dingbus">
			<div class="hendersLine">
				<div class="headers">
					<div class="headers-left" @click="addFavorite" style="cursor: pointer;">
						<i class="el-icon-star-off"></i>
						收藏本站
					</div>
					<div class="headers-right">
						<div v-if="ipenshees" style="cursor: pointer;" @click="gerenzxing">账号：{{ipenshees}}</div>
						<div @click="login" v-else style="cursor: pointer;">登录/注册</div>
						<div class="separate"></div>
						<div @click="clickRoue" style="cursor: pointer;">关于我们</div>
					</div>
				</div>
			</div>
			<div class="contentHer">
				<div class="contentHerOne">
					<div>
						<img src="../../src/assets/home/login.png" alt="" @click="routHome" class="imgSerOne">
					</div>
					<div class="searchFor">
						<div class="searchFor-input">
							<div class="zhuan">
								<div class="antsss" @click.stop="fanersssed">
									<div>{{tetxs}}</div>
									<i class="el-icon-caret-bottom el-icon--right" style="color: #E5E5E5;"></i>
									<div class="xuanzq" v-show="faslePopu">
										<div @click.stop="clickXiala(item)" v-for="(item,index) in xcialass"
											:key="index">
											{{item.title}}
										</div>
									</div>
								</div>
							</div>
							<div class="hengtio"></div>
							<input type="text" v-model="valuese">
							<div class="searchs" @click="shoushuos">搜索</div>
						</div>
						<div class="searchFor-botton">
							热门搜索： <div @click="clickREse(det.title)" v-for="(det,ind) in datas.search" :key="ind">
								{{det.title}}
							</div>
						</div>
					</div>
					<div class="imgIphoe">
						<img src="../../src/assets/home/phone.png" alt="" class="imgSerTwo">
					</div>
					<div class="rightSer" v-if="datas">
						<div>{{datas.config.web_phone1}}</div>
						<div>{{datas.config.web_phone2}}</div>
					</div>
				</div>
				<div class="contentHerTwo">
					<div class="shgouyKc">
						课程分类<img src="../../src/assets/mpsee.png" alt="" style="width: 20px;height: 20px;" />
						<div class="erjicai">
							<div v-for="(item,index) in list" :key="index" style="position: relative;"
								@click="diyiugtau(item.id)">
								<div style="margin-left: 20px;">{{item.title}}</div>
								<i class="el-icon-arrow-right" style="margin-right: 20px;"></i>
								<div class="sanjicai" v-if="item.children">
									<div @click="diersse(item.id,den.id)" v-for="(den,der) in item.children"
										:key="der + 'san'">
										<div style="margin-left: 20px;">{{den.title}}</div>
									</div>
								</div>
								<div v-else class="sanjicai">
									<div style="margin-left: 20px;">暂无</div>
								</div>
							</div>
						</div>
					</div>
					<div style="display: flex;justify-content: space-between;flex: 1;">
						<div :class="{'rlickll':heaValues != index,'rlickllsswe':heaValues == index}"
							v-for="(item,index) in routData" :key="index">
							<div v-show="heaValues== index" @click="clickrout(item,index)">{{item.title}}</div>
							<div v-show="heaValues!= index" @click="clickrout(item,index)">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="tiancon"></div> -->
		<router-view></router-view>
		<div class="fotterseeds">
			<!-- 	<div class="jiequImsge">
				<img src="../../src/assets/home/dibbua.jpg" alt="">
			</div> -->
			<div class="foMaxBox" v-if="datas">
				<div class="limkBox">
					<img src="../../src/assets/home/minLoin.png" alt="">
					<div class="names" style="cursor: pointer;" v-for="(item,index) in routData"
						@click="clickrout(item,index)" :key="index">
						{{item.title}}
					</div>
				</div>
				<div style="display: flex;justify-content: space-between;" v-if="datas">
					<div>
						<div class="dianhuas">
							<div>
								<img src="../../src/assets/home/baihua.png" alt="">
							</div>
							<div class="shhss">
								<div>{{datas.config.web_phone1}}</div>
								<div>{{datas.config.web_phone2}}</div>
							</div>
						</div>
					</div>
					<div>
						<div class="dizhis">
							地址：{{datas.config.web_address}}
						</div>
						<div class="fotText">邮箱：{{datas.config.web_email}}</div>
						<div class="fotText">
							传真：{{datas.config.web_cz}}
						</div>
					</div>
					<div>
						<img :src="datas.config.web_ewm" alt="" class="wcodema">
						<div class="guanzhu">微信关注我们</div>
					</div>
				</div>
				<div class="linkboxs">
					友情链接：<div style="cursor: pointer;" v-for="(item,index) in datas.link" :key="index"
						@click="rouWindeo(item.url)">
						{{item.title}}
					</div>
				</div>
				<div class="xians"></div>
				<div class="beians">
					<div>{{datas.config.web_bq}} {{datas.config.web_key}} {{datas.config.web_beian}}</div>
					<div>技术支持：风雨同舟</div>
				</div>
			</div>
		</div>
		<el-dialog title="快速登录" :visible.sync="staters" :before-close="handleClose" custom-class="personClass"
			:close-on-click-modal="false">
			<div class="popDat">
				<div class="qiehuanlog">
					<div :class="{'shshshd':stateVal == 1}" @click="clickAntys(1)">快速登录</div>
					<div class="anyseds"></div>
					<div :class="{'shshshd':stateVal == 2}" @click="clickAntys(2)">密码登录</div>
				</div>
				<div class="ahshhss"><input type="text" v-model="denIphone" placeholder="请输入手机号" maxlength="11"></div>
				<div class="hengtiao">
				</div>
				<div class="ahshhss" v-show="stateVal == 1"><input type="text" v-model="denYanzen" placeholder="请输入验证码">
					<div class="huoquyanz" @click="logiNgetCodes" v-if="numbersser ==0">获取验证码</div>
					<div class="huoquyanz" v-else>{{numbersser}}后发送</div>
				</div>
				<div class="ahshhss" v-show="stateVal == 2"><input type="text" v-model="denPsswer"
						placeholder="请输入6-18位字母加数字组合密码"></div>
				<div class="hengtiao">
				</div>
				<div class="ahshhss" v-show="stateVal == 1"><input type="text" v-model="yaoqingma"
						placeholder="请输入邀请码(非必填)"></div>
				<div class="hengtiao">
					<div class="yonghuxie">
						<el-radio v-model="radio" label=""></el-radio>
						我已阅读并同意
						<span style="color: #FF8400;" @click="yonghuxieyi">
							《谊华教育用户协议》
						</span>
					</div>
					<div class="bottLogin" @click="logins">
						登录
					</div>
					<div class="zhangha">
						没有账号？<span style="color: #3B99FE;" @click="zhucher">立即注册</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="注册账号" :visible.sync="zhucDaat" :before-close="openZhuce" custom-class="zhuceClass"
			:close-on-click-modal="false">
			<div class="popDat" style="height: 556px;">
				<div class="ahshhss"><input type="text" v-model="zhuIphoe" placeholder="请输入手机号" maxlength="11"></div>
				<div class="hengtiao">
				</div>
				<div class="ahshhss"><input type="text" v-model="zhuYzheng" placeholder="请输入验证码">
					<div class="huoquyanz" @click="getCodes" v-if="numbers ==0">获取验证码</div>
					<div class="huoquyanz" v-else>{{numbers}}后发送</div>
				</div>
				<div class="hengtiao">
				</div>
				<div class="ahshhss"><input type="text" v-model="zhuPsswer" placeholder="请输入6-18位字母加数字组合密码"></div>
				<div class="hengtiao">
				</div>
				<div class="ahshhss"><input type="text" v-model="yaoqingma" placeholder="请输入邀请码(非必填)"></div>
				<div class="yonghuxie">
					<el-radio v-model="radios" label=""></el-radio>
					我已阅读并同意
					<span style="color: #FF8400;" @click="yonghuxieyi">
						《谊华教育用户协议》
					</span>
				</div>
				<div class="bottLogin" @click="clickzhuce">
					注册
				</div>
				<div class="zhangha">
					已有账号？<span style="color: #3B99FE;" @click="denglu">立即登录</span>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="开通VIP 免费观看海量学习视频" :visible.sync="huiyuankai" :before-close="openGoumnai"
			custom-class="goumaiHui" :close-on-click-modal="false">
			<div>
				<div class="huiyuanpcr">
					<div class="left" v-for="(item,index) in huiyuanka" :key="index"
						:class="{'rightss':huiValue== index}" @click="huival(index)">
						<div class="gengduoi" v-show="item.id == 5">
							更多人选择
						</div>
						<div class="title">
							{{item.title_fake}}
						</div>
						<div class="price">
							<span>￥</span>{{item.money}}
						</div>
						<div class="yuanjia">
							￥{{item.money_fake}}
						</div>
						<div class="boxin" v-html="item.text">
						</div>
					</div>
				</div>
				<div class="erweima" v-if="erWeima">
					<img :src="erWeima" alt="" />
				</div>
				<div class="zhiofus">
					<img src="../../src/assets/weixing.png" alt="" />
					扫码支付
				</div>
				<div class="jaigeishe" v-if="huiyuanka.length >0">
					￥{{huiyuanka[huiValue].money}}
				</div>
				<div class="shaoMazhif" v-if="erWeima" @click="wanchengzhif">完成支付，请点击</div>
				<div class="shaoMazhif" @click="huiyuanx" v-else>扫码支付</div>
				<div class="fotterssw" @click="yonghuxieyi">*支付视为您同意《谊华教育用户协议》</div>
			</div>
		</el-dialog>
		<div class="hengtiaos">
			<div class="tops">
				<div class="lianxiWen">
					<img src="../../src/assets/dianse.png" alt="" />
					<div>联系我们</div>
					<div class="laixniDInh" v-if="datas">
						<div>{{datas.config.web_phone1}}</div>
						<div>{{datas.config.web_phone2}}</div>
					</div>
				</div>
				<div class="guanzhuWen">
					<img src="../../src/assets/heWei.png" alt="" />
					<div>关注我们</div>
					<div class="codeMa" v-if="datas">
						<img :src="datas.config.web_ewm" alt="" class="codeERma" />
					</div>
				</div>
			</div>
			<div class="botons" @click="suhsuh">
				<img src="../../src/assets/fanhuid.png" alt="" class="dhduih" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		login,
		loginse,
		register,
		messages,
		homesPage,
		huioyuanka,
		sabjiCaidan,
		hiyuanxiadan,
		huiyuanlazhif,
		gerenzxing
	} from "../api/user.js"
	export default {
		data() {
			return {
				erWeima: '',
				faslePopu: false,
				tetxs: '',
				huiValue: 0,
				numbers: 0,
				stateVal: 1,
				denIphone: '',
				denYanzen: '',
				denPsswer: '',
				zhuIphoe: '',
				zhuYzheng: '',
				zhuPsswer: '',
				radio: '2',
				radios: '2',
				yaoqingma: '',
				zhucDaat: false,
				kaitonghui: false,
				xcialass: [{
					name: '在线课程',
					path: '/zaixKe'
				}, {
					name: '直播课',
					path: '/zhiBo'
				}, {
					name: '学习资料',
					path: '/xuexi'
				}, {
					name: '活动培训',
					path: '/huoDon'
				}, {
					name: '在线商城',
					path: '/zaiXidel'
				}, {
					name: '在线题库',
					path: '/answerPage'
				}, ],
				list: [],
				value: '',
				zhuanti: '',
				routData: [],
				pathss: '',
				valuese: '',
				datas: '',
				numbersser: '',
				screenWidth: document.body.clientWidth,
				huiyuanka: []
			}
		},
		computed: {
			heaValues() {
				return this.$store.state.user.type
			},
			huiyuankai() {
				return this.$store.state.statess.popushui
			},
			staters() {
				return this.$store.state.statess.states
			},
			ipenshees() {
				return this.$store.state.user.iphens
			},
		},
		watch: { // 监听,当路由发生变化的时候执行
			'$route'() {
				this.valuese = ''
			},
			screenWidth(val) {
				if (val < 992) {
					if (this.$store.state.user.token) {
						window.location.href = `https://shop.yhjtedu.com/h5/#/?token='${this.$store.state.user.token}`
					} else {
						window.location.href = 'https://shop.yhjtedu.com/h5/#/'
					}
				}
			},
			huiyuankai() {
				this.getHuiyuanka()
			}
		},
		mounted() {
			if (document.documentElement.clientWidth < 992) {
				if (this.$store.state.user.token) {
					window.location.href = `https://shop.yhjtedu.com/h5/#/?token='${this.$store.state.user.token}`
				} else {
					window.location.href = 'https://shop.yhjtedu.com/h5/#/'
				}
				return
			}
			const that = this;
			window.screenWidth = document.body.clientWidth;
			that.screenWidth = window.screenWidth;
			window.onresize = () =>
				(() => {
					window.screenWidth = document.body.clientWidth;
					that.screenWidth = window.screenWidth;
				})();
			this.getData()
			this.getCaidan()
		},
		methods: {
			// 完成支付
			async wanchengzhif() {
				let data = await gerenzxing()
				if (data.code == 1) {
					if (data.data.user.groupid != 1) {
						this.getData()
						this.$message({
							message: '会员卡购买成功',
							type: 'success'
						});
					} else {
						this.$message.error('请支付后，再点击');
					}
				}
			},
			openClisLas() {
				if (this.faslePopu) {
					this.faslePopu = false
				}
			},
			routHome() {
				if (this.$route.path !== '/') {
					this.$router.push('/')
				}
			},
			fanersssed() {
				this.faslePopu = !this.faslePopu
			},
			// 会员下单
			async huiyuanx() {
				let data = await hiyuanxiadan({
					gid: this.huiyuanka[this.huiValue].id,
					type: 2
				})
				if (data.code == 1) {
					this.huiyuanlaz(data.data.id)
				}
			},
			async huiyuanlaz(e) {
				let data = await huiyuanlazhif({
					oid: e
				})
				if (data.code == 1) {
					this.erWeima = data.data.ewm
				}
			},
			rouWindeo(e) {
				window.location.href = e
			},
			clickREse(e) {
				this.valuese = e
				this.shoushuos()
			},
			diyiugtau(e) {
				this.$router.push({
					path: "/zaixKe",
					query: {
						idse: e,
					}
				})
			},
			diersse(e, b) {
				this.$router.push({
					path: "/zaixKe",
					query: {
						idse: e,
						idsws: b
					}
				})
			},
			// 级联菜单
			async getCaidan() {
				let data = await sabjiCaidan()
				if (data.code == 1) {
					this.list = data.data.list
				}
			},
			// 收藏本站
			addFavorite() {
				var url = window.location;
				var title = document.title;
				var ua = navigator.userAgent.toLowerCase();
				if (ua.indexOf("360se") > -1) {
					alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
				} else if (ua.indexOf("msie 8") > -1) {
					window.external.AddToFavoritesBar(url, title); //IE8
				} else if (document.all) { //IE类浏览器
					try {
						window.external.addFavorite(url, title);
					} catch (e) {
						alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
					}
				} else if (window.sidebar) { //firfox等浏览器；
					window.sidebar.addPanel(title, url, "");
				} else {
					alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
				}
			},

			async getHuiyuanka() {
				let data = await huioyuanka()
				if (data.code == 1) {
					this.huiyuanka = data.data.list
				}
			},
			yonghuxieyi() {
				if (this.$route.path == "/yongHxyi") {
					return
				}
				this.openZhuce()
				this.openGoumnai()
				this.handleClose()
				this.$router.push("/yongHxyi")
			},
			scrollToBottom() {
				const wrapper = this.$refs.scrollWrapper;
				window.scrollTo(0, wrapper.scrollHeight)
			},
			suhsuh() {
				window.scrollTo(0, 0)
			},
			//  首页数据
			async getData() {
				let data = await homesPage()
				if (data.code == 1) {
					this.datas = data.data
					this.routData = data.data.menu
					this.tetxs = data.data.sellist[0].title
					this.pathss = data.data.sellist[0].url
					this.xcialass = data.data.sellist
				}
			},
			clickXiala(e) {
				this.faslePopu = false
				this.tetxs = e.title
				this.pathss = e.url
			},
			shoushuos() {
				if (this.$route.path == this.pathss) {
					if (this.$route.query.title == this.valuese) {
						return
					}
				}
				this.$router.push({
					path: this.pathss,
					query: {
						title: this.valuese
					}
				})
			},
			// 切换会员
			huival(e) {
				this.huiValue = e
			},
			// 获取验证码
			async getCodes() {
				if (this.zhuIphoe == '') {
					this.$message.error('请输入手机号')
					return
				} else {
					const regex = /^1[3456789]\d{9}$/;
					let set = regex.test(this.zhuIphoe);
					if (set == false) {
						this.$message.error('请输入正确手机号')
						return
					}
				}
				let data = await messages()
				if (data.code == 1) {
					this.startCountdown()
				}
			},
			// 登录获取验证码
			async logiNgetCodes() {
				if (this.denIphone == '') {
					this.$message.error('请输入手机号')
					return
				} else {
					const regex = /^1[3456789]\d{9}$/;
					let set = regex.test(this.denIphone);
					if (set == false) {
						this.$message.error('请输入正确手机号')
						return
					}
				}
				let data = await messages()
				if (data.code == 1) {
					this.ssestartCountdown()
				}
			},
			// 密码验证
			validate(str) {
				// 8-16位字母加数字组合的正则表达式  
				var regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
				return regex.test(str)
			},
			// 倒计时
			startCountdown() {
				let that = this
				that.numbers = 60;
				var countdownId = setInterval(function() {
					if (that.numbers > 0) {
						that.numbers = that.numbers - 1;
					} else {
						clearInterval(countdownId);
					}
				}, 1000);
			},
			// 登录倒计时
			ssestartCountdown() {
				let that = this
				that.numbersser = 60;
				var countdownId = setInterval(function() {
					if (that.numbersser > 0) {
						that.numbersser = that.numbersser - 1;
					} else {
						clearInterval(countdownId);
					}
				}, 1000);
			},
			clickAntys(e) {
				this.stateVal = e
			},
			// 跳转关我于们
			clickRoue() {
				if (this.$route.path == '/guanMys') {
					return
				}
				this.$router.push('/guanMys')
			},
			// 打开注册
			zhucher() {
				this.yaoqingma = ''
				this.denIphone = ''
				this.denPsswer = ''
				this.denYanzen = ''
				this.$store.commit('statess/setStates', false)
				this.zhucDaat = true
			},
			// 跳转登录
			denglu() {
				this.yaoqingma = ''
				this.zhuIphoe = ''
				this.zhuPsswer = ''
				this.zhuYzheng = ''
				this.$store.commit('statess/setStates', true)
				this.zhucDaat = false
			},
			// 登录
			logins() {
				if (this.radio == '2') {
					this.$message.error('请阅读并同意用户协议')
					return
				}
				if (this.stateVal == 1) {
					this.codeLogin()
				} else {
					this.persLogin()
				}
			},
			// 密码登录
			async persLogin() {
				if (this.denIphone == '' || this.denPsswer == '') {
					this.$message.error('请补充登录信息')
					return
				}
				let data = await login({
					mobile: this.denIphone,
					password: this.denPsswer,
				})
				if (data.code == 1) {
					this.$message({
						message: '登录成功',
						type: 'success'
					});
					this.$store.commit('user/setToken', data.data.token)
					this.$store.commit('statess/setStates', false)
					this.$store.commit('user/setIphens', data.data.mobile)
					// setTimeout(() => {
					// 	window.location.reload()
					// }, 1000)
				} else {
					this.$message.error(data.msg)
					this.denIphone = ''
					this.denYanzen = ''
					this.denPsswer = ''
				}
			},
			// 验证码登录
			async codeLogin() {
				if (this.denIphone == '' || this.denYanzen == '') {
					this.$message.error('请补充登录信息')
					return
				}
				let data = await loginse({
					mobile: this.denIphone,
					code: this.denYanzen,
					pid: this.yaoqingma == "" ? 0 : this.yaoqingma
				})
				if (data.code == 1) {
					this.$message({
						message: '登录成功',
						type: 'success'
					});
					this.$store.commit('user/setToken', data.data.token)
					this.$store.commit('statess/setStates', false)
					this.$store.commit('user/setIphens', data.data.mobile)
					// setTimeout(() => {
					// 	window.location.reload()
					// }, 1000)
				} else {
					this.$message.error(data.msg)
					this.denIphone = ''
					this.denYanzen = ''
					this.denPsswer = ''
				}
			},
			// 注册
			async clickzhuce() {
				this.validate(this.zhuPsswer)
				if (this.zhuPsswer == '' || this.zhuYzheng == '' || this.zhuIphoe == '') {
					this.$message.error('请补充注册信息')
					return
				}
				if (this.radios == '2') {
					this.$message.error('请阅读并同意用户协议')
					return
				}
				if (this.validate(this.zhuPsswer) == false) {
					this.$message.error('请输入8-16位字母加数字组合密码')
					return
				}
				let data = await register({
					code: this.zhuYzheng,
					mobile: this.zhuIphoe,
					password: this.zhuPsswer,
					pid: this.yaoqingma == "" ? 0 : this.yaoqingma
				})
				if (data.code == 1) {
					this.$message({
						message: '账号注册成功',
						type: 'success'
					});
					this.denIphone = this.zhuIphoe
					this.zhuPsswer = ''
					this.zhuYzheng = ''
					this.zhuIphoe = ''
					setTimeout(() => {
						this.denglu()
					}, 2000)
				} else {
					this.$message.error(data.msg)
				}
			},
			openGoumnai() {
				this.$store.commit('statess/setPopushui', false)
			},
			handleClose() {
				this.$store.commit('statess/setStates', false)
			},
			openZhuce() {
				this.zhuPsswer = ''
				this.zhuYzheng = ''
				this.zhuIphoe = ''
				this.zhucDaat = false
			},
			gerenzxing() {
				if (this.$route.path == '/memberPage') {
					return
				}
				this.$router.push({
					path: '/memberPage',
					query: {
						unms: 0
					}
				})
			},
			clickrout(b, e) {
				if (b.id == '11') {
					window.location.href = b.url
					return
				}
				let ser = b.url
				if (this.$route.path !== b) {
					if (e == 8) {
						if (this.$store.state.user.token) {
							this.$router.push({
								path: ser,
								query: {
									unms: 2
								}
							})
						} else {
							this.$store.commit('statess/setStates', true)
						}

					} else if (e == 7) {
						if (this.$store.state.user.token) {
							this.$router.push({
								path: ser,
								query: {
									unms: 0
								}
							})
						} else {
							this.$store.commit('statess/setStates', true)
						}

					} else {
						if (e == 0) {
							ser = '/'
						}
						if (e == 0 && this.$route.path == '/') {
							return
						}
						this.$router.push(ser)
					}
					return
				}
				if (e == 8) {
					if (this.$store.state.user.token) {
						this.$router.push({
							path: ser,
							query: {
								unms: 2
							}
						})
					} else {
						this.$store.commit('statess/setStates', true)
					}
				} else if (e == 7) {
					if (this.$store.state.user.token) {
						this.$router.push({
							path: ser,
							query: {
								unms: 0
							}
						})
					} else {
						this.$store.commit('statess/setStates', true)
					}
				}
			},
			login() {
				this.$store.commit('statess/setStates', true)
			}
		},
	}
</script>
<style>
	.el-message__content {
		font-size: 20px !important;
	}

	.el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title {
		background: rgba(0, 0, 0, 0.5) !important;
	}

	.el-menu--horizontal .el-menu .el-menu-item {
		background: rgba(0, 0, 0, 0.5) !important;
	}

	.el-menu--popup-bottom-start {
		background: transparent !important;
	}

	.el-submenu .el-menu {
		background: transparent !important;
	}

	.el-icon-arrow-right:before {
		color: #ffffff;
	}

	.el-menu--popup {
		padding: 0 !important;
	}

	.el-submenu.is-active .el-submenu__title {
		width: 219px !important;
	}

	.el-menu--popup-bottom-start {
		top: -13px;
	}
</style>
<style lang="scss" scoped>
	.lianxiWen {
		position: relative;

		.laixniDInh::before {
			content: '';
			position: absolute;
			left: 198px;
			top: 35px;
			border-top: 15px solid transparent;
			border-right: 20px solid transparent;
			border-left: 20px solid #FFFFFF;
			border-bottom: 15px solid transparent;
		}

		.laixniDInh {
			color: #3B99FE;
			display: none;
			box-shadow: 0 0 3px #bfbfbf;
			text-align: center;
			border-radius: 10px;
			width: 200px;
			height: 100px;
			background: #FFFFFF;
			position: absolute;
			right: 70px;
			bottom: -10px;

			&>div:hover {
				background: #3B99FE;
				color: #FFFFFF;
			}

			&>div {
				width: 200px;
				font-size: 24px;
				line-height: 50px;
				height: 50px;
			}
		}
	}

	.lianxiWen:hover {
		.laixniDInh {
			display: block;
		}
	}

	.guanzhuWen:hover {
		.codeMa {
			display: block;
		}
	}

	.guanzhuWen {
		position: relative;

		.codeMa::before {
			content: '';
			position: absolute;
			left: 198px;
			top: 82px;
			border-top: 15px solid transparent;
			border-right: 20px solid transparent;
			border-left: 20px solid #FFFFFF;
			border-bottom: 15px solid transparent;
		}

		.codeMa {
			display: none;

			.codeERma {
				width: 170px;
				height: 170px;
				position: relative;
				bottom: 15px;
			}

			box-shadow: 0 0 3px #bfbfbf;
			text-align: center;
			border-radius: 10px;
			width: 200px;
			height: 200px;
			background: #FFFFFF;
			position: absolute;
			right: 70px;
			bottom: -70px;
		}
	}

	.antsss {
		position: relative;
		width: 86px;
		display: flex;
		align-items: center;
		justify-content: right;
		font-size: 14px;
		color: #999999;
	}

	.hengtiaos {
		z-index: 666;
		zoom: 0.7;
		position: fixed;
		bottom: 10vh;
		right: 20px;
		text-align: center;
		font-size: 16px;
		color: #748396;
		border-radius: 32px;

		.tops {
			cursor: pointer;
			box-shadow: 0 0 2px #999999;
			width: 64px;
			height: 240px;
			background: #FFFFFF;
			border-radius: 32px;

			img {
				width: 30px;
				height: 30px;
				margin-top: 30px;
			}

			&>div {
				width: 46px;
				margin: auto;
			}
		}

		.botons {
			cursor: pointer;
			box-shadow: 0 0 2px #999999;
			margin-top: 10px;

			.dhduih {
				width: 24px;
				height: 24px;
				margin-top: 18px;
			}

			width: 64px;
			height: 64px;
			background: #FFFFFF;
			border-radius: 32px;
		}
	}

	.fotterssw {
		font-size: 16px;
		text-align: center;
		color: #B2B2B2;
		margin: 26px 0 10px 0;
	}

	.jaigeishe {
		margin-top: 12px;
		text-align: center;
		font-size: 32px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FF8400;
	}

	.zhiofus {
		margin-top: 20px;
		font-size: 16px;
		text-align: center;
		color: #999999;

		&>img {
			width: 23px;
			height: 22px;
			margin-right: 5px;
			vertical-align: middle;
			position: relative;
			bottom: 2px;
		}
	}

	.shaoMazhif {
		cursor: pointer;
		margin: auto;
		width: 360px;
		height: 60px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 18px;
		text-align: center;
		line-height: 60px;
		color: #FFFFFF;
		margin-top: 20px;
	}

	.erweima {
		margin-top: 20px;
		text-align: center;

		img {
			width: 150px;
			height: 150px;
		}

		margin: 20px 0 10px 0;
	}

	.huiyuanpcr {
		width: 590px;
		margin: auto;
		display: flex;
		justify-content: space-between;

		.left {
			width: 280px;
			height: 320px;
			background: #FFFFFF;
			border-radius: 12px;
			box-shadow: 0 0 4px #b8b8b8;
			padding-bottom: 30px;
			position: relative;

			.gengduoi {
				width: 110px;
				height: 32px;
				background: linear-gradient(250deg, #FF8400 0%, #FF5A00 100%);
				border-radius: 12px 0px 12px 0px;
				font-size: 18px;
				font-weight: bold;
				color: #FFFFFF;
				text-align: center;
				line-height: 32px;
				position: absolute;
			}


			.lsisjs {
				margin-top: 10px;
			}

			.boxin {
				padding-left: 30px;

				&>div {
					width: 6px;
					height: 6px;
					background: #BBBBBB;
					border-radius: 50%;
					margin-right: 10px;
				}
			}

			.yuanjia {
				font-size: 20px;
				text-decoration: line-through;
				color: #B2B2B2;
				text-align: center;
				padding: 0 0 36px 0;
			}

			.title {
				font-size: 26px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				text-align: center;
				padding: 50px 0 28px 0
			}

			.price {
				font-size: 60px;
				font-weight: bold;
				color: #FF8400;
				text-align: center;

				&>span {
					font-size: 32px;
				}
			}
		}

		.rightss {
			background: #FFFBF7;
			border: 2px solid #FF8400;
		}
	}

	.qiehuanlog {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;

		.anyseds {
			width: 26px;
		}

		.shshshd {
			color: #3B99FE;
			font-size: 28px;
		}

		&>div {
			font-size: 26px;
			color: #333333;
		}
	}

	body {
		margin: 0;
	}

	/deep/.el-dialog {
		border-radius: 10px;
	}

	.tiancon {
		height: 178px;
	}

	.dingbus {
		width: 100%;
	}

	/deep/.goumaiHui {
		width: 1000px;
	}

	/deep/.goumaiHui>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	/deep/.goumaiHui>.el-dialog__header {
		text-align: center;
	}

	////////////////////
	/deep/.zhuceClass>.el-dialog__header {
		text-align: center;
	}

	/deep/.zhuceClass>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	/deep/.yonghuxie>.el-radio {
		margin-right: 10px;
		position: relative;
		top: 1px;
	}

	/deep/.yonghuxie>.el-radio>.el-radio__input>.el-radio__inner {
		width: 22px;
		height: 20px;
	}

	/deep/.zhuceClass {
		width: 640px;
	}

	///////////////////////////////////
	/deep/.personClass>.el-dialog__header {
		text-align: center;
	}

	/deep/.personClass>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	/deep/.yonghuxie>.el-radio {
		margin-right: 10px;
		position: relative;
		top: 1px;
	}

	/deep/.yonghuxie>.el-radio>.el-radio__input>.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	/deep/.personClass {
		width: 640px;
	}

	.popDat {
		width: 500px;
		margin: auto;
		min-height: 560px;

		.zhangha {
			margin-top: 30px;
			text-align: center;
			font-size: 16px;
			color: #B2B2B2;
		}

		.bottLogin {
			width: 500px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			color: #FFFFFF;
			line-height: 60px;
			text-align: center;
		}

		.yonghuxie {
			display: flex;
			align-items: center;
			margin: 30px 0 56px 0;
			font-size: 16px;
			color: #333333;
		}

		.hengtiao {
			height: 20px;
		}

		.ahshhss {
			width: 470px;
			height: 68px;
			border: 1px solid #E5E5E5;
			border-radius: 12px;
			display: flex;
			align-items: center;
			padding-left: 30px;

			.huoquyanz {
				height: 68px;
				width: 150px;
				font-size: 18px;
				color: #3B99FE;
				border-left: 1px solid #E5E5E5;
				text-align: center;
				line-height: 68px;
			}

			input {
				flex: 1;
				box-shadow: none;
				border: none;
				outline: none;
				font-size: 18px;
			}
		}
	}

	.xuanzq {
		position: absolute;
		z-index: 66;
		top: 28px;
		font-size: 14px;
		color: #FFFFFF;
		background: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 4px #B2B2B2;
		padding: 4px 0;
		text-align: center;

		&>div:hover {
			background: #3B99FE;
			color: #FFFFFF;
		}

		&>div {
			text-align: center;
			height: 26px;
			line-height: 26px;
			width: 96px;
		}
	}

	.wcodema {
		margin-top: 20px;
		width: 146px;
		height: 146px;
	}

	.beians {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: #8DBDF1;
	}

	.xians {
		height: 1px;
		background: #62A4E9;
		margin: 20px 0;
	}

	.linkboxs {
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #8DBDF1;
		margin-top: 0px;
	}

	.guanzhu {
		font-size: 16px;
		margin-top: 14px;
		text-align: center;
		color: #FFFFFF;
	}

	.fotText {
		margin-top: 10px;
		font-size: 16px;
		color: #FFFFFF;
	}

	.dizhis {
		font-size: 16px;
		color: #FFFFFF;
		margin-top: 36px;
	}

	.dianhuas {
		display: flex;
		align-items: center;
		margin-top: 36px;

		.shhss {
			font-size: 22px;
			font-weight: 400;
			color: #FFFFFF;
		}

		img {
			width: 38px;
			height: 38px;
			position: relative;
			top: 3px;
			margin-right: 12px;
		}
	}

	.fotterseeds {
		background: linear-gradient(90deg, #1D74D1 0%, #3B99FE 100%);

		.foMaxBox {
			width: 1240px;
			margin: auto;
			padding: 40px 0 30px 0;

			.limkBox {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.names {
					font-size: 14px;
					font-weight: 500;
					color: #FFFFFF;
				}

				&>img {
					width: 180px;
					height: 53px;
					margin-right: 120px;
				}
			}

		}

		.jiequImsge {
			position: relative;
			height: 80px;
			overflow: hidden;

			&>img {
				position: absolute;
				top: 0;
				left: calc(50% - 960px);
				width: 1920px;
				height: 80px;
			}
		}

	}

	.el-icon-arrow-down:before {
		display: none;
	}

	.el-menu--horizontal>.el-submenu {
		width: 216px;
	}

	.el-icon-arrow-right:before {
		color: #FFFFFF;
	}

	.el-menu-item * {
		font-size: 18px;
	}

	.el-submenu__title * {
		font-size: 18px;
		color: #FFFFFF;
	}

	.el-menu--horizontal .el-menu .el-menu-item.is-active {
		color: #FFFFFF;
		font-size: 18px;
	}

	.el-menu {
		background-color: transparent;
	}

	.el-menu.el-menu--horizontal {
		border-bottom: none;
	}

	.whole {
		min-width: 1280px;
		background-color: #F8F8F8;
	}

	.el-dropdown {
		font-size: 14px;
		color: #999999;
	}

	.el-col-5 {
		display: flex;
		align-items: center;
		width: 60px;
	}

	.searchFor {
		margin: 0 180px 0 200px;
		padding-top: 24px;
		cursor: pointer;

		.searchFor-botton {
			margin-top: 10px;
			padding-left: 42px;
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: 500;
			color: #333333;

			&>div {

				margin: 0 8px;
			}
		}

		.searchFor-input {
			width: 500px;
			height: 32px;
			border: 1px solid #3B99FE;
			border-radius: 22px;
			display: flex;
			align-items: center;

			.searchs {
				border-radius: 0 22px 22px 0;
				width: 80px;
				height: 32px;
				font-size: 14px;
				color: #FFFFFF;
				background: #3B99FE;
				text-align: center;
				line-height: 32px;
				margin-left: 20px;
			}

			.zhuan {
				width: 80px;
				margin-right: 12px;
			}

			.hengtio {
				width: 1px;
				height: 20px;
				background: #E5E5E5;
			}

			&>input {
				font-size: 16px;
				border: none;
				outline: none;
				width: 280px;
				margin: 0 20px 0 16px;
			}
		}
	}

	.erjicai {
		z-index: 88;
		display: none;
		position: absolute;
		width: 180px;
		padding: 4px 0;
		background: rgba(0, 0, 0, 0.5);
		top: 44px;
		left: 0;

		&>div:hover {
			background-color: #3B99FE;

			div {
				display: block;
			}
		}

		&>div {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			font-size: 15px;
			color: #FFFFFF;
			font-weight: 400;
		}
	}



	.sanjicai {
		display: none;
		position: absolute;
		width: 180px;
		left: 180px;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		border-left: 2px solid white;

		&>div:hover {
			background-color: #3B99FE;
		}

		&>div {
			cursor: pointer;
			font-size: 15px;
			color: #FFFFFF;
			font-weight: 400;
			padding: 10px 0;
		}
	}

	.contentHer {
		width: 100%;
		background: #FFFFFF;

		.contentHerTwo {
			display: flex;
			align-items: center;
			width: 1240px;
			margin: auto;

			.rlickll:hover {
				color: #3B99FE;
				transform: scale(1.1);
			}

			.rlickll {
				color: #333333;
				// transition: transform 0.3s ease;
				cursor: pointer;
				font-size: 15px;
			}

			.rlickllsswe:hover {
				transform: scale(1.1);
			}

			.rlickllsswe {
				color: #3B99FE;
				cursor: pointer;
				font-size: 15px;
			}

			.shgouyKc:hover {
				.erjicai {
					display: block;
				}
			}

			.shgouyKc {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 15px;
				padding: 0 20px;
				font-weight: bold;
				color: #FFFFFF;
				width: 140px;
				height: 44px;
				background: #3B99FE;
				margin-right: 50px;

			}
		}

		.contentHerOne {
			display: flex;
			width: 1240px;
			margin: auto;
			align-items: center;
			padding: 10px 0;

			.imgSerOne {
				cursor: pointer;
				width: 148px;
				height: 50px;
			}

			.imgSerTwo {
				width: 34px;
				height: 34px;
				vertical-align: middle;
			}

			.rightSer {
				font-size: 20px;
				font-weight: 400;
				color: #3B99FE;
				margin-left: 15px;
			}

			.imgIphoe {
				// margin-left: 117px;
			}
		}
	}

	.hendersLine {
		background: #F8F8F8;
		border: 1px solid #E5E5E5;
		height: 28px;
	}

	.headers {
		width: 1240px;
		height: 28px;
		background: #F8F8F8;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		color: #999999;
		line-height: 140px;
		margin: auto;

		.headers-left {
			&>img {
				width: 16px;
				height: 16px;
				vertical-align: middle;
			}
		}

		.headers-right {
			display: flex;
			align-items: center;
			font-size: 12px;

			.separate {
				width: 1px;
				height: 12px;
				background: #E5E5E5;
				margin: 0 30px;
			}
		}
	}
</style>