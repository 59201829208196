<template>
	<div class="mainx" id="MaxDuens">
		<div class="shangping">
			商品详情
		</div>
		<div class="haeder">
			<div class="left">
				<div class="oneImg">
					<img :src="src" alt="" v-show="src">
				</div>
				<div class="twoImg">
					<div :style="{border:valuessed == index? '1px solid red':'none'}"
						v-for="(item,index) in dataList.pic_list" :key="index" @click="clickImg(item,index)">
						<img :src="item" alt="">
					</div>
				</div>
			</div>
			<div class="right">
				<div class="titlt">
					{{dataList.title}}
				</div>
				<div class="text" v-if="guigeDeli">
					<span>￥</span>{{pricesw}}
				</div>
				<div class="text" v-else>
					<span>￥</span>{{pricesw*num}}
				</div>
				<!-- 第一规格 -->
				<div v-if="guige.length >0">
					<div class="guige">{{guige[0].title}}</div>
					<div class="specific">
						<div :class="{'boxs':index !== inde,'boxss':index == inde}"
							v-for="(item,index) in guige[0].child" :key="index" @click="clickRe(index,item.id)">
							{{item.title}}
						</div>
					</div>
				</div>
				<div v-else class="hshjshs"></div>
				<!-- 第二规格 -->
				<div v-if="guige.length >1">
					<div class="guige">{{guige[1].title}}</div>
					<div class="specific">
						<div :class="{'boxs':index !== indsse,'boxss':index == indsse}"
							v-for="(item,index) in guige[1].child" :key="index" @click="clickResse(index,item.id)">
							{{item.title}}
						</div>
					</div>
				</div>
				<div class="shulian">
					<div class="jianjei" v-if="guigeDeli">
						{{guigeDeli}}
					</div>
					<div class="jianjei" v-else>{{tetxLise}}</div>
					<div class="price" v-if="guigeDeli">
						￥<span>{{pricesw*num}}</span>
					</div>
					<el-input-number v-model="num" @change="handleChange" :min="1" :max="100"
						label="描述文字"></el-input-number>
				</div>
				<div class="bottomfor">
					<div class="onBotton" @click="addgouwces" v-show="sattess">加入购物车</div>
					<div class="toBotton" @click="routJie">立即购买</div>
				</div>
			</div>
		</div>
		<article class="maxbox">
			<div class="left">
				<div class="jiehao">
					详情介绍
				</div>
				<div v-html="fuwenb"></div>
			</div>
			<div class="right">
				<div class="tuijian">
					推荐商品
				</div>
				<div class="minBox" v-for="(item,index) in tijian" :key="index" :class="{'margings': index == 2}"
					@click="routers(item.id)">
					<img :src="item.image" alt="">
					<div class="titllr">{{item.title}}</div>
					<div class="fotters">
						<div class="left">
							<span>￥</span>{{item.money}}
						</div>
						<div class="botts">已售{{item.sale}}</div>
					</div>
				</div>
			</div>
		</article>
		<el-dialog title="已添加至购物车" :visible.sync="kaitonghui" :before-close="openGoumnai" custom-class="goumaiHui"
			:close-on-click-modal="false">
			<div class="suhduhd">
				<div class="popuBox">
					<div>
						<img :src="dataList.image" alt="">
					</div>
					<div class="right">
						<div class="title">{{dataList.title}}</div>
						<div class="text" v-show="skuId">
							<span>规格：</span>
							{{guigeDeli}}
						</div>
						<div class="text">
							<span>数量：</span>
							{{num}}
						</div>
						<div class="price">
							<span>￥</span>{{pricesw*num}}
						</div>
					</div>
				</div>
				<div class="popuBottons" @click="cjakanGouche">查看购物车</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		shoppDelis,
		shoppGuiList,
		shoppGuiDeli,
		shoppLists,
		addGouwce,
		miaoshaDeli
	} from "../../api/shoppIng.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				guigeDeli: '',
				onePath: '',
				twoPath: '',
				guige: [],
				skuId: '',
				fuwenb: '',
				pricesw: 0,
				id: '',
				dataList: 0,
				kaitonghui: false,
				num: 1,
				inde: 0,
				indsse: 0,
				src: '',
				tijian: [],
				tetxLise: '',
				guixiangq: '',
				sattess: true,
				valuessed: 0
			}
		},
		mounted() {
			this.id = this.$route.query.id
			if (this.$route.query.state) {
				this.miaoShaDeli()
				this.sattess = false
			} else {
				this.getDatas()
			}
			this.shangpLists()
		},
		methods: {
			// 秒杀详情
			async miaoShaDeli() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#MaxDuens')
				});
				let data = await miaoshaDeli({
					id: this.id
				})
				this.dataList = data.data
				this.src = data.data.Goods.image
				this.fuwenb = this.formatRichText(data.data.Goods.content)
				this.pricesw = data.data.money
				this.tetxLise = data.data.Goods.title
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 获取详情
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#MaxDuens')
				});
				let data = await shoppDelis({
					id: this.id
				})
				this.dataList = data.data
				this.src = data.data.pic_list[0]
				this.fuwenb = this.formatRichText(data.data.content)
				this.guigeList()
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 推荐商品
			async shangpLists() {
				let data = await shoppLists({
					limit: 3,
					is_inx: 1
				})
				this.tijian = data.data.list
			},
			// 规格列表
			async guigeList() {
				let data = await shoppGuiList({
					gid: this.dataList.id
				})
				this.guige = data.data
				if (this.guige.length == 2) {
					this.onePath = this.guige[0].child[0].id
					this.twoPath = this.guige[1].child[0].id
				} else if (this.guige.length == 1) {
					this.onePath = this.guige[0].child[0].id
				}
				this.guigeXiang()
			},
			// 规格详情
			async guigeXiang() {
				let set
				if(this.twoPath) {
					set = this.onePath + "-" + this.twoPath
				} else if (this.onePath) {
					set = this.onePath
				} else {
					this.pricesw = this.dataList.money
					this.tetxLise = this.dataList.title
					return
				}
				let data = await shoppGuiDeli({
					path: set,
					gid: this.dataList.id
				})
				this.guixiangq = data.data
				this.skuId = data.data.id
				this.guigeDeli = data.data.list_text
				this.pricesw = data.data.money
			},
			// 加入购物车
			async addgouwces() {
				if (this.$store.state.user.token) {
					let ser = {
						gid: this.dataList.id,
						sum: this.num
					}
					if (this.skuId) {
						ser.sku_id = this.skuId
					}
					let data = await addGouwce(ser)
					if (data.code == 1) {
						this.kaitonghui = true
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 查看购物车
			cjakanGouche() {
				this.$router.push({
					path: '/memberPage',
					query: {
						unms: 4
					}
				})
			},
			formatRichText(html) { // 富文本内容格式化
				return html && html.replace(/<img[^>]*>/gi, function(match) { // 查找所有的 img 元素
					return match.replace(/style=".*"/gi, '').replace(/style='.*'/gi,
						'') // 删除找到的所有 img 元素中的 style 属性
				}).replace(/<img/gi, '<img style="width:100%;"') // 对 img 元素增加 style 属性，并设置宽度为 100%
			},
			// 立即购买
			routJie() {
				if (this.$store.state.user.token) {
					let set = this.dataList
					set.unmse = this.num
					if (this.guixiangq) {
						set.guigexiang = this.guixiangq
					}
					let data = JSON.stringify(set)
					if (this.sattess) {
						this.$router.push({
							path: '/settLement',
							query: {
								data: encodeURIComponent(data)
							}
						})
					} else {
						this.$router.push({
							path: '/settLement',
							query: {
								data: encodeURIComponent(data),
								state: true
							}
						})
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			openGoumnai() {
				this.kaitonghui = false
			},
			handleChange(e) {
				console.log(e)
			},
			clickRe(e, b) {
				this.inde = e
				this.onePath = b
				this.guigeXiang()
			},
			clickResse(e, b) {
				this.indsse = e
				this.twoPath = b
				this.guigeXiang()
			},
			clickImg(e, b) {
				this.src = e
				this.valuessed = b
			},
			routers(e) {
				if (this.id == e) {
					return
				}
				this.id = e
				this.onePath = ''
				this.twoPath = ''
				this.$router.push({
					path: '/deloePage',
					query: {
						id: e
					}
				})
				this.getDatas()
				this.shangpLists()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.hshjshs {
		height: 180px;
	}

	/deep/ .el-input__inner {
		height: 41px;
	}

	/deep/ .el-input-number {
		width: 140px;
	}

	/deep/.goumaiHui {
		width: 640px;
	}

	/deep/.goumaiHui>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	.popuBottons {
		width: 500px;
		height: 60px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 18px;
		text-align: center;
		line-height: 60px;
		color: #FFFFFF;
		margin: 60px auto 30px auto;
	}

	.suhduhd {
		padding: 0 30px;
	}

	.popuBox {
		display: flex;

		.right {
			margin-left: 10px;

			.price {
				margin-top: 20px;
				font-size: 24px;
				color: #CD1212;

				&>span {
					font-size: 20px;
					font-weight: bold;

				}
			}

			.text {
				margin-bottom: 8px;
				width: 340px;
				font-size: 16px;
				color: #333333;

				&>span {
					color: #B2B2B2;
				}

				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

			.title {
				margin-bottom: 24px;
				width: 360px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}
		}

		img {
			width: 180px;
			height: 180px;
		}
	}

	.shangping {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		padding: 40px 0 39px 0;
	}

	.minBox {
		zoom: 0.8;
		padding-bottom: 30px;
		border-bottom: 1px solid #E5E5E5;
		margin-top: 30px;

		&>img {
			width: 350px;
			height: 350px;
			border-radius: 12px;
		}

		.fotters {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;

			.botts {
				font-size: 16px;
				color: #B2B2B2;
			}

			&>.left {
				font-size: 28px;
				color: #CD1212;

				&>span {
					font-size: 18px;
					font-weight: bold;
					color: #CD1212;
				}
			}
		}

		.titllr {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			width: 350px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}

	.maxbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 60px;

		&>.left {
			width: 800px;
			padding: 30px;
			background: #FFFFFF;
			border-radius: 12px;

			img {
				width: 992px;
			}

			.jiehao {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				margin: 10px 0 20px 0;
				text-align: center;
			}
		}

		&>.right {
			padding: 0 30px;
			width: 291px;
			// height: 1150px;
			background: #FFFFFF;
			border-radius: 12px;

			.tuijian {
				margin-bottom: 30px;
				padding: 30px 0 0 0;
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}
		}
	}

	.margings {
		border-bottom: 0;
	}

	.bottomfor {
		display: flex;
		margin-top: 40px;
		zoom: 0.76;

		.onBotton {
			margin-right: 10px;
			width: 260px;
			height: 60px;
			border: 2px solid #3B99FE;
			border-radius: 12px;
			text-align: center;
			line-height: 60px;
			font-size: 24px;
			font-weight: bold;
			color: #3B99FE;
		}

		.toBotton {
			width: 260px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			text-align: center;
			line-height: 60px;
			font-size: 24px;
			font-weight: bold;
			color: #FFFFFF;
		}
	}

	.shulian {
		display: flex;
		align-items: center;
		margin-top: 20px;
		zoom: 0.8;

		.price {
			margin-right: 60px;
			font-size: 20px;
			font-weight: bold;
			color: #CD1212;

			&>span {
				font-size: 24px;
				font-weight: 400
			}
		}

		.jianjei {
			width: 240px;
			font-size: 16px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
			color: #666666;
			margin-right: 20px;
		}
	}

	.specific {
		display: flex;
		flex-wrap: wrap;

		.boxss {
			cursor: pointer;
			zoom: 0.8;
			padding: 0 20px;
			height: 48px;
			background: #3B99FE;
			border-radius: 8px;
			font-size: 16px;
			text-align: center;
			line-height: 48px;
			color: #FFFFFF;
			margin: 0 10px 10px 0;
		}

		.boxs {
			cursor: pointer;
			zoom: 0.7;
			padding: 0 20px;
			height: 48px;
			border: 1px solid #E5E5E5;
			border-radius: 8px;
			font-size: 16px;
			text-align: center;
			line-height: 48px;
			color: #999999;
			margin: 0 10px 10px 0;
		}
	}

	.haeder {
		padding: 30px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-bottom: 30px;
		display: flex;

		.right {
			margin-left: 56px;

			.guige {
				font-size: 16px;
				color: #333333;
				margin-bottom: 22px
			}

			.text {
				font-size: 28px;
				color: #CD1212;
				margin: 30px 0 30px 0;

				&>span {
					font-size: 18px;
					font-weight: bold;
					color: #CD1212;
				}
			}

			.titlt {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2
			}
		}

		.left {
			zoom: 0.6;

			.oneImg {
				&>img {
					width: 460px;
					height: 460px;
				}

			}

			.twoImg {
				display: flex;
				align-items: center;

				&>div {
					img {
						width: 110px;
						height: 110px;
						vertical-align: middle;
						border-radius: 6px;
						margin-right: 6px;
					}
				}

			}
		}

	}

	.mainx {
		width: 1240px;
		margin: auto;
	}
</style>