<template>
	<div>
		<div class="header">
			<div @click="clickDat(index)" :class="{'tipsBox':index == valuer}" v-for="(item,index) in textData"
				:key="index">
				{{item}}
			</div>
		</div>
		<div class="linese">
			<div class="ones">课程</div>
			<div class="twos">答题时间</div>
			<div class="threes">总题数</div>
			<div>答题状态</div>
		</div>
		<div v-if="dataliss.length >0">
			<div class="maxBNox" v-for="(item,index) in dataliss" :key="index">
				<div class="onesTit">
					{{item.Coursequestion.title}}
				</div>
				<div class='biaoqian'>{{item.create_time}}</div>
				<div class="itemse">{{item.Coursequestion.sum_list.count}}题</div>
				<div class="leixing">{{item.cut == 1? '未完成':'已完成'}}</div>
				<div class="lioanjie" @click="routsssers(item.question_id,item.id)" v-if="item.cut == 1">继续答题
				</div>
				<div class="chakanjie" @click="routssduns(item.question_id,item.id)" v-else>
					查看解析
				</div>
			</div>
		</div>
		<div class="quesheng" v-else>
			<img src="../../../assets/quesheng.png" alt="" />
			<div>暂无内容~</div>
		</div>
		<div class="paging" v-if="dataliss.length >0">
			<el-pagination background layout="prev, pager, next" :page-size="10" :total="total" @current-change="pagese"
				@prev-click='pearDta' @next-click='mextDat'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		myDatis
	} from "../../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				page: 1,
				total: 0,
				valuer: 0,
				textData: ['全部', '待交卷', '已完成'],
				dataliss: [],
			}
		},
		mounted() {
			this.getDatas()
		},
		methods: {
			// 获取列表
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let sers = {
					page: this.page,
					limit: 10
				}
				if (this.valuer != 0) {
					sers.cut = this.valuer
				}
				let data = await myDatis(sers)
				if (data.code == 1) {
					this.dataliss = data.data.list
					this.total = data.data.total
				} else {
					this.dataliss = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 继续答题
			async routsssers(e, b) {
				this.$router.push({
					path: '/jixuDatais',
					query: {
						id: e,
						logid: b
					}
				})
			},
			// 查看解析
			async routssduns(e, b) {
				this.$router.push({
					path: '/scorePage',
					query: {
						id: e,
						logid: b
					}
				})
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getDatas()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getDatas()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getDatas()
			},
			clickDat(e) {
				this.valuer = e
				this.getDatas()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.chakanjie {
		cursor: pointer;
		font-size: 16px;
		font-family: PingFang SC;
		text-decoration: underline;
		color: #F5B53A;
	}

	.paging {
		margin-top: 50px;
		display: flex;
		justify-content: center;
	}

	.quesheng {
		text-align: center;
		padding: 200px 0 240px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.maxBNox {
		display: flex;
		align-items: center;
		margin-top: 38px;

		img {
			width: 150px;
			height: 90px;
		}

		.lioanjie {
			font-size: 16px;
			font-weight: bold;
			text-decoration: underline;
			color: #3B99FE;
		}

		.leixing {
			margin-right: 220px;
			width: 126px;
			font-size: 16px;
			text-align: center;
			color: #333333;
		}

		.itemse {
			text-align: center;
			width: 260px;
			font-size: 16px;
			color: #333333;
		}

		.biaoqian {
			font-size: 16px;
			color: #333333;
		}

		.onesTit {
			width: 336px;
			font-size: 20px;
			font-weight: bold;
			color: #333333;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}

	.ones {
		margin: 0 258px 0 95px;
	}

	.twos {
		margin-right: 151px;
	}

	.threes {
		margin-right: 136px;
	}

	.linese {

		display: flex;
		align-items: center;
		border-bottom: 1px solid #E5E5E5;
		padding: 46px 0 26px 0;
		font-size: 16px;
		color: #999999;
	}

	.header {
		width: 1200px;
		height: 60px;
		background: #F8F8F8;
		border-radius: 12px;
		display: flex;
		justify-content: center;

		&>div {
			cursor: pointer;
			padding-top: 15px;
			width: 90px;
			font-size: 20px;
			color: #333333;
			margin-right: 50px;
			text-align: center;
		}

		.tipsBox {
			position: relative;
		}

		.tipsBox::before {

			position: absolute;
			content: '';
			width: 65px;
			height: 2px;
			background: #3B99FE;
			bottom: 0;
			left: 12px;
		}
	}
</style>