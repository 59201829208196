<template>
	<div>
		<div class="Rightbox" v-show="stater">
			<div class="header">
				<div @click="clickDat(index)" :class="{'tipsBox':index == valuer}" v-for="(item,index) in textData"
					:key="index">
					{{item}}
				</div>
			</div>
			<div class="textBiao">
				<div class="onesB">产品</div>
				<div class="twoB">下单时间</div>
				<div class="threeB">订单号</div>
				<div class="fourB">总计</div>
				<div class="fiveB">状态</div>
			</div>
			<div v-if="dataliss.length > 0">
				<div class="shangBox" @click="deliClck(item.id)" v-for="(item,index) in dataliss" :key="index">
					<div>
						<img :src="item.Goodsorderinfo[0].goods_image" alt="" />
					</div>
					<div class="antse">
						<div class="titir">{{item.Goodsorderinfo[0].goods_title}}</div>
						<div class="shuzhuer">{{item.Goodsorderinfo[0].goods_title}}</div>
					</div>
					<div class="items">{{item.create_time}}</div>
					<div class="dingdan">{{item.order_up}}</div>
					<div class="prices">￥{{item.money}}</div>
					<div class="zuiyou" v-if="item.status == 1">
						<div class="fukuan">待付款</div>
						<div>
							<div class="liji" @click.stop="dingdanzfs(item.id)">
								立即付款
							</div>
							<div class="quxiao" @click.stop="quxiaoDings(item.id)">取消订单</div>
						</div>
					</div>
					<div class="daofahuo" v-else-if="item.status == 2">
						<div class="faHuo">
							待发货
						</div>
						<div class="shengqin" @click.stop="quxiaoDings(item.id)">
							取消订单
						</div>
					</div>
					<div class="daiShou" v-else-if="item.status == 3">
						<div class="faHuo">
							待收货
						</div>
						<div class="shouhuo" @click.stop="qurenshou(item.id)">
							确认收货
						</div>
					</div>
					<div class="cssErst" v-else-if="item.status == 4">
						已完成
					</div>
					<div class="cssErst" v-else-if="item.status == 9">
						已取消
					</div>
				</div>
			</div>
			<div class="quesheng" v-else>
				<img src="../../../assets/quesheng.png" alt="" />
				<div>暂无内容~</div>
			</div>
			<div class="paging" v-if="dataliss.length > 0">
				<el-pagination background layout="prev, pager, next" :page-size="5" :total="total"
					@current-change="pagese" @prev-click='pearDta' @next-click='mextDat'>
				</el-pagination>
			</div>
		</div>
		<deliPage v-show="!stater" @returnClick="stateSr" ref="desserrsd" />
		<el-dialog title="取消订单" :visible.sync="kaitonghui" :before-close="openGoumnai" custom-class="shangping"
			:close-on-click-modal="false">
			<div class="popuTitlr">
				您的订单还未支付成功
			</div>
			<div class="popuText">是否确认取消</div>
			<div class="popuFoter">
				<div class="queREen">确认</div>
				<div class="zaiXiang" @click="openGoumnai">再想想</div>
			</div>
		</el-dialog>
		<el-dialog title="取消订单" :visible.sync="applyfor" :before-close="applyClick" custom-class="shangping"
			:close-on-click-modal="false">
			<div class="popuSheng">取消订单后将无法撤销</div>
			<div class="cascInput">
				<el-select v-model="dataVasl" @change="handleChange" :popper-append-to-body="false"
					placeholder="请选择退款原因">
					<el-option v-for="item in listwwe" :key="item" :label="item" :value="item">
					</el-option>
				</el-select>
			</div>
			<div class="popuFoter">
				<div class="queREen" @click="quxiaoding">确认</div>
				<div class="zaiXiang" @click="applyClick">再想想</div>
			</div>
		</el-dialog>
		<el-dialog title="扫码支付" :visible.sync="codeShao" :before-close="clickOpen" custom-class="goumaiHui"
			:close-on-click-modal="false">
			<div class="shaomazhif">
				<img :src="codeEwm" alt="">
				<div class="textDun">请使用微信app扫描支付，支付完成后请等待页面自动跳转。</div>
				<div class="bottomsd" @click="routerWers">支付成功，请点击</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import deliPage from './detailsPage.vue'
	import {
		shoppZhifu,
		shoppXiangqs
	} from "../../../api/shoppIng.js"
	import {
		peizhixiang
	} from "../../../api/user.js"
	import {
		myDingdan,
		quxiaoDing,
		querenShouh
	} from "../../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		components: {
			deliPage
		},
		data() {
			return {
				ids: '',
				codeShao: false,
				dataVasl: '',
				applyfor: false,
				kaitonghui: false,
				stater: true,
				page: 1,
				total: 0,
				textData: [
					'全部', '待付款', '待发货', '待收货', '已完成', '已取消', '售后'
				],
				valuer: 0,
				dataliss: [],
				oid: '',
				listwwe: [],
				diss: '',
				codeEwm: ''
			}
		},
		mounted() {
			this.getDatas()
			this.dinganyany()
		},
		watch: {
			ids(val) {
				this.ants(val)
			}
		},
		methods: {
			ants(e) {
				this.$refs.desserrsd.state = e
			},
			async dinganyany() {
				let data = await peizhixiang({
					"keylist": [
						"refund_list",
					]
				})
				this.listwwe = data.data.refund_list
			},
			// 获取列表
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let sers = {
					page: this.page,
					limit: 5
				}
				if (this.valuer != 0) {
					sers.vip = this.valuer
				}
				let data = await myDingdan(sers)
				if (data.code == 1) {
					this.dataliss = data.data.list
					this.total = data.data.total
				} else {
					this.dataliss = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 订单支付
			async dingdanzfs(e) {
				this.diss = e
				let data = await shoppZhifu({
					oid: e
				})
				if (data.code == 1) {
					this.codeShao = true
					this.codeEwm = data.data.ewm
				}
			},
			// 跳转成功
			async routerWers() {
				let data = await shoppXiangqs({
					id: this.diss
				})
				if (data.data.status == 1) {
					this.$message.error('请先支付后，再点击');
					return
				}
				this.$router.push({
					path: '/succePage',
					query: {
						id: this.diss,
						states: true
					}
				})

			},
			// 取消订单
			async quxiaoding() {
				let data = await quxiaoDing({
					id: this.oid,
					refund_title: this.dataVasl
				})
				if (data.code == 1) {
					this.applyfor = false
					this.getDatas()
				}
			},
			// 确认收获
			async qurenshou(e) {
				let data = await querenShouh({
					id: e
				})
				if (data.data == 1) {
					this.getDatas()
				}
			},
			// 选择框
			handleChange(e) {
				console.log(e)
			},
			// 关闭付款
			clickOpen() {
				this.codeShao = false
			},
			// 打开退款
			shengTui(e) {
				this.oid = e
				this.applyfor = true
			},
			// 关闭退款
			applyClick() {
				this.dataVasl = ''
				this.applyfor = false
			},
			// 打开取消
			quxiaoDings(e) {
				this.oid = e
				this.applyfor = true
			},
			// 关闭详情
			stateSr() {
				this.stater = true
			},
			// 打开详情
			deliClck(e) {
				this.ids = e
				this.stater = false
			},
			// 关闭取消
			openGoumnai() {
				this.kaitonghui = false
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getDatas()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getDatas()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getDatas()
			},
			clickDat(e) {
				this.valuer = e
				this.getDatas()
			}
		}
	}
</script>
<style lang="scss" scoped>
	/deep/.el-popper {
		zoom: 1 !important;
	}

	/deep/ .el-dialog {
		zoom: 0.9 !important;
	}

	/deep/.el-select-dropdown__empty {
		font-size: 20px !important;
	}

	/deep/.el-select {
		width: 500px;
	}

	/deep/.el-input--suffix .el-input__inner {
		height: 60px;
		border: 1px solid #E5E5E5;
		border-radius: 12px;
		font-size: 18px;
	}

	/deep/.goumaiHui {
		width: 640px;
	}

	/deep/.shangping {
		width: 640px;
	}

	/deep/.shangping>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	/deep/.shangping>.el-dialog__header {
		text-align: center;
	}

	.shaomazhif {
		text-align: center;

		.bottomsd {
			cursor: pointer;
			width: 360px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			line-height: 60px;
			text-align: center;
			color: #FFFFFF;
			margin: 0 auto 20px auto;
		}

		.textDun {
			font-size: 18px;
			color: #333333;
			margin: 30px 0 46px 0;
		}

		img {
			width: 300px;
			height: 300px;
		}
	}

	.cascInput {
		width: 500px;
		margin: auto;
	}

	.popuSheng {
		text-align: center;
		font-size: 20px;
		color: #999999;
		margin: 0 0 20px 0;
	}

	.popuFoter {
		display: flex;
		justify-content: center;
		margin: 30px 0 30px 0;

		.zaiXiang {
			width: 240px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			line-height: 60px;
			text-align: center;
			color: #FFFFFF;
		}

		.queREen {
			font-size: 18px;
			line-height: 60px;
			text-align: center;
			color: #3B99FE;
			width: 240px;
			height: 60px;
			border: 1px solid #3B99FE;
			border-radius: 12px;
			margin-right: 20px;
		}
	}

	.popuTitlr {
		text-align: center;
		font-size: 20px;
		color: #999999;
	}

	.popuText {
		text-align: center;
		font-size: 20px;
		color: #999999;
		margin-top: 10px;
	}

	.quesheng {
		text-align: center;
		padding: 200px 0 200px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.paging {
		display: flex;
		justify-content: center;
	}

	.shangBox {
		display: flex;
		align-items: center;
		margin-bottom: 40px;

		.cssErst {
			padding-left: 62px;
			font-size: 16px;
			color: #B2B2B2;
		}

		.daiShou {
			display: flex;
			align-items: center;

			.shouhuo {
				cursor: pointer;
				width: 76px;
				height: 28px;
				background: #3B99FE;
				border-radius: 4px;
				font-size: 16px;
				text-align: center;
				line-height: 28px;
				color: #FFFFFF;
			}

			.faHuo {
				margin-right: 10px;
				font-size: 16px;
				color: #3B99FE;
			}
		}

		.daofahuo {
			display: flex;
			align-items: center;

			.shengqin {
				cursor: pointer;
				width: 76px;
				height: 28px;
				background: #FFFFFF;
				border: 1px solid #3B99FE;
				border-radius: 4px;
				font-size: 16px;
				text-align: center;
				line-height: 28px;
				color: #3B99FE;
			}

			.faHuo {
				margin-right: 10px;
				font-size: 16px;
				color: #3B99FE;
			}
		}

		.zuiyou {
			display: flex;
			align-items: center;

			.quxiao {
				cursor: pointer;
				margin-top: 6px;
				font-size: 16px;
				color: #3B99FE;
				width: 76px;
				height: 28px;
				background: #FFFFFF;
				border: 1px solid #3B99FE;
				border-radius: 4px;
				text-align: center;
				line-height: 28px;
			}

			.liji {
				cursor: pointer;
				text-align: center;
				line-height: 30px;
				width: 76px;
				height: 30px;
				background: #3B99FE;
				border-radius: 4px;
				font-size: 16px;
				color: #FFFFFF;
			}

			.fukuan {
				margin-right: 15px;
				font-size: 16px;
				color: #DD1D1D;
			}
		}

		.prices {
			text-align: center;
			width: 185px;
			font-size: 16px;
			color: #333333;
		}

		.dingdan {
			text-align: center;
			width: 160px;
			font-size: 16px;
			color: #333333;
		}

		.items {
			text-align: center;
			width: 260px;
			font-size: 16px;
			color: #333333;
		}

		.antse {
			margin-left: 20px;

			.titir {
				width: 250px;
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

			.shuzhuer {
				width: 245px;
				margin-top: 14px;
				font-size: 16px;
				color: #999999;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}
		}

		img {
			width: 120px;
			height: 120px;
		}
	}

	.textBiao {
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #999999;
		padding: 46px 0 26px 0;
		border-bottom: 1px solid #E5E5E5;
		margin-bottom: 30px;

		.onesB {
			margin: 0 410px 0 50px;
		}

		.twoB {
			margin-right: 150px;
		}

		.threeB {
			margin-right: 135px;
		}

		.fourB {
			margin-right: 145px;
		}
	}

	.Rightbox {
		.header {
			width: 1200px;
			height: 60px;
			background: #F8F8F8;
			border-radius: 12px;
			display: flex;
			justify-content: center;

			&>div {
				padding-top: 15px;
				width: 65px;
				font-size: 20px;
				color: #333333;
				margin-right: 50px;
				text-align: center;
			}

			.tipsBox {
				position: relative;
			}

			.tipsBox::before {
				position: absolute;
				content: '';
				width: 65px;
				height: 2px;
				background: #3B99FE;
				bottom: 0;
				left: 0;
			}
		}
	}
</style>