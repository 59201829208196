<template>
	<article id="Msshjhd">
		<div class="maxBox" v-if="tiMussL.length>0 && sdhyhdhf">
			<div class="title">{{sdhyhdhf.title}}</div>
			<div class="text">总题数：{{sdhyhdhf.sum_list.count}}题</div>
			<div class="numbers">第{{indesss +1}}题 <span>（{{tiMussL[indesss].type_text}}）</span>：</div>
			<div class="timu">{{tiMussL[indesss].title}}</div>
			<div v-if="tiMussL[indesss].type == 3">
				<div class="Multiple" v-for="(item,index) in tiMussL[indesss].tag_data_one" :key="index"
					@click="multipless(index)">
					<div class="imgBox">
						<img src="../../assets/kongx.png" alt="" v-show="index !== vasll">
						<img src="../../assets/cheng.png" alt="" v-show="index === vasll">
					</div>
					<div class="denText">{{item.key}}: {{item.title}}
					</div>
				</div>
			</div>
			<div v-if="tiMussL[indesss].type == 2">
				<div class="Multiple" @click="multiple('a')">
					<div class="imgBox">
						<img src="../../assets/kongx.png" alt="" v-show="'a' != values">
						<img src="../../assets/cheng.png" alt="" v-show="'a' == values">
					</div>
					<div class="denText">{{tiMussL[indesss].a}}
					</div>
				</div>
				<div class="Multiple" @click="multiple('b')">
					<div class="imgBox">
						<img src="../../assets/kongx.png" alt="" v-show="'b' != values">
						<img src="../../assets/cheng.png" alt="" v-show="'b' == values">
					</div>
					<div class="denText">{{tiMussL[indesss].b}}
					</div>
				</div>
			</div>
			<div v-else-if="tiMussL[indesss].type == 4">
				<div class="Questions" v-for="(item,index) in tiMussL[indesss].tag_data_two" :key="index"
					@click="choiceDu(index,item.title)">
					<div class="imgBox">
						<img src="../../assets/kongx.png" alt="" v-show="!choice.includes(index)">
						<img src="../../assets/cheng.png" alt="" v-show=" choice.includes(index)">
					</div>
					<div class="denText">{{item.key}}: {{item.title}}
					</div>
				</div>
			</div>
			<div class="blanks" v-else-if="tiMussL[indesss].type == 1">
				<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 4}" placeholder="请输入内容" v-model="textareas">
				</el-input>
			</div>
			<div class="fotter">
				<div class="lefts" @click="shangyiti">
					<img src="../../assets/sanjiao.png" alt="">
					<div>上一题</div>
				</div>
				<div class="rights" @click="xiaytio">
					<div>下一题</div>
					<img src="../../assets/sanjiao.png" alt="">
				</div>
			</div>
		</div>
		<div class="tijiao" @click="srsddhdjh">我要提交</div>
		<el-dialog title="温馨提示" :visible.sync="prompts" :before-close="promptClick" custom-class="prompoter"
			:close-on-click-modal="false">
			<div class="shifoujiao">您是否确认交卷？</div>
			<div class="popuFotter">
				<div class="quxiao" @click="prompts = false">取消</div>
				<div class="queren" @click="tiujiaoshi">确认</div>
			</div>
		</el-dialog>
	</article>
</template>

<script>
	import {
		datiDelis,
		tijiaoshijaun,
		juxudatiser
	} from "../../api/daTi.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				duoData: [],
				indesss: 0,
				prompts: false,
				values: '',
				vasll: '',
				choice: [],
				textareas: '',
				id: '',
				tiMussL: [],
				sdhyhdhf: '',
				logid: '',
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.logid = this.$route.query.logid
			this.juxudatios()
			this.shijuanxq()
		},
		methods: {
			// 继续答题
			async juxudatios() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Msshjhd')
				});
				let data = await juxudatiser({
					log_id: this.logid,
					question_id: this.id
				})
				let ones = []
				let twos = []
				data.data.forEach(item => {
					if (!item.log_check) {
						item.daants = ''
						ones.push(item)
					} else {
						item.daants = ""
						twos.push(item)
					}
				})
				this.tiMussL = [...ones, ...twos]
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			srsddhdjh() {
				// 填空
				if (this.tiMussL[this.indesss].type == 1) {
					if (this.textareas == '') {
						this.$message.error('请作答后再提交');
						return
					}
					this.tiMussL[this.indesss].daants = this.textareas
				} else if (this.tiMussL[this.indesss].type == 2) {
					// 判断
					if (this.values === '') {
						this.$message.error('请作答后再提交');
						return
					}
					this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss][this.values]
					this.tiMussL[this.indesss].vallsers = this.values
				} else if (this.tiMussL[this.indesss].type == 3) {
					// 单选
					if (this.vasll === '') {
						this.$message.error('请作答后再提交');
						return
					}
					this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].tag_data_one[this.vasll].title
					this.tiMussL[this.indesss].vallsers = this.vasll
				} else if (this.tiMussL[this.indesss].type == 4) {
					// 多选
					if (this.duoData.length == 0) {
						this.$message.error('请作答后再提交');
						return
					}
					this.tiMussL[this.indesss].daants = this.duoData
					this.tiMussL[this.indesss].vallsers = this.choice
				}
				this.prompts = true
			},
			// 提交试卷
			async tiujiaoshi() {
				if (this.indesss == this.tiMussL.length - 1) {
					// 填空
					if (this.tiMussL[this.indesss].type == 1) {
						this.tiMussL[this.indesss].daants = this.textareas
					} else if (this.tiMussL[this.indesss].type == 2) {
						// 判断
						this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss][this.values]
						this.tiMussL[this.indesss].vallsers = this.values
					} else if (this.tiMussL[this.indesss].type == 3) {
						// 单选
						this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].tag_data_one[this.vasll].title
						this.tiMussL[this.indesss].vallsers = this.vasll
					} else if (this.tiMussL[this.indesss].type == 4) {
						// 多选
						this.tiMussL[this.indesss].daants = this.duoData
						this.tiMussL[this.indesss].vallsers = this.choice
					}
				}
				let set = []
				console.log(this.tiMussL, 'ohnohouhuo')
				this.tiMussL.forEach(item => {
					if (item.daants) {
						if (item.type == 4) {
							if (typeof item.daants == 'object') {
								set.push({
									info_id: item.id,
									value: item.daants.join(',')
								})
							} else {
								set.push({
									info_id: item.id,
									value: item.daants
								})
							}
						} else {
							set.push({
								info_id: item.id,
								value: item.daants
							})
						}
					} else if (item.log_check) {
						set.push({
							info_id: item.id,
							value: item.log_check
						})
					}
				})
				let data = await tijiaoshijaun({
					question_id: this.sdhyhdhf.id,
					info: set
				})
				if (data.code == 1) {
					this.prompts = false
					this.$message({
						message: data.msg,
						type: 'success'
					});
					setTimeout(() => {
						this.$router.push('/answerPage')
					}, 1500)
				}
			},
			// 上一题
			shangyiti() {
				if (this.indesss == 0) {
					return
				}
				// 填空
				if (this.tiMussL[this.indesss - 1].type == 1) {
					this.textareas = this.tiMussL[this.indesss - 1].daants
				} else if (this.tiMussL[this.indesss - 1].type == 2) {
					// 判断
					this.values = this.tiMussL[this.indesss - 1].vallsers
				} else if (this.tiMussL[this.indesss - 1].type == 3) {
					// 单选
					this.vasll = this.tiMussL[this.indesss - 1].vallsers
				} else if (this.tiMussL[this.indesss - 1].type == 4) {
					// 多选
					this.choice = this.tiMussL[this.indesss - 1].vallsers
					this.duoData = this.tiMussL[this.indesss - 1].daants
				}
				this.indesss -= 1
			},
			// 下一题
			xiaytio() {
				if (this.indesss == this.tiMussL.length - 1) {
					return
				}
				// 填空
				if (this.tiMussL[this.indesss].type == 1) {
					this.tiankong()
				} else if (this.tiMussL[this.indesss].type == 2) {
					// 判断
					this.panduans()
				} else if (this.tiMussL[this.indesss].type == 3) {
					// 单选
					this.danxuanti()
				} else if (this.tiMussL[this.indesss].type == 4) {
					// 多选
					this.duoxuanti()
				}
			},
			// 多选题
			duoxuanti() {
				if (this.tiMussL[this.indesss + 1].daants === '') {
					if (this.duoData.length == 0) {
						this.$message.error('请完成试题');
						return
					}
					this.tiMussL[this.indesss].daants = this.duoData
					this.tiMussL[this.indesss].vallsers = this.choice
					this.indesss += 1
					this.duoData = []
					this.choice = []
					if (this.tiMussL[this.indesss].log_check) {
						// 填空
						if (this.tiMussL[this.indesss].type == 1) {
							this.forssss()
						} else if (this.tiMussL[this.indesss].type == 2) {
							// 判断
							this.thresss()
						} else if (this.tiMussL[this.indesss].type == 3) {
							// 单选
							this.twossss()
						} else if (this.tiMussL[this.indesss].type == 4) {
							// 多选
							this.onessss()
						}
					}
				} else {
					this.indesss += 1
					this.choice = this.tiMussL[this.indesss].vallsers
				}
			},
			// 单选题
			danxuanti() {
				if (this.tiMussL[this.indesss + 1].daants === '') {
					if (this.vasll === '') {
						this.$message.error('请完成试题');
						return
					}
					this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].tag_data_one[this.vasll].title
					this.tiMussL[this.indesss].vallsers = this.vasll
					this.indesss += 1
					this.vasll = ''
					if (this.tiMussL[this.indesss].log_check) {
						// 填空
						if (this.tiMussL[this.indesss].type == 1) {
							this.forssss()
						} else if (this.tiMussL[this.indesss].type == 2) {
							// 判断
							this.thresss()
						} else if (this.tiMussL[this.indesss].type == 3) {
							// 单选
							this.twossss()
						} else if (this.tiMussL[this.indesss].type == 4) {
							// 多选
							this.onessss()
						}
					}
				} else {
					this.indesss += 1
					this.vasll = this.tiMussL[this.indesss].vallsers
				}
			},
			// 判断
			panduans() {
				if (this.tiMussL[this.indesss + 1].daants === '') {
					if (this.values === '') {
						this.$message.error('请完成试题');
						return
					}
					this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss][this.values]
					this.tiMussL[this.indesss].vallsers = this.values
					this.indesss += 1
					this.values = ''
					if (this.tiMussL[this.indesss].log_check) {
						// 填空
						if (this.tiMussL[this.indesss].type == 1) {
							this.forssss()
						} else if (this.tiMussL[this.indesss].type == 2) {
							// 判断
							this.thresss()
						} else if (this.tiMussL[this.indesss].type == 3) {
							// 单选
							this.twossss()
						} else if (this.tiMussL[this.indesss].type == 4) {
							// 多选
							this.onessss()
						}
					}
				} else {
					this.indesss += 1
					this.values = this.tiMussL[this.indesss].vallsers
				}
			},
			// 填空
			tiankong() {
				if (this.tiMussL[this.indesss + 1].daants === '') {
					if (this.textareas === '') {
						this.$message.error('请完成试题');
						return
					}
					this.tiMussL[this.indesss].daants = this.textareas
					this.indesss += 1
					this.textareas = ''
					if (this.tiMussL[this.indesss].log_check) {
						// 填空
						if (this.tiMussL[this.indesss].type == 1) {
							this.forssss()
						} else if (this.tiMussL[this.indesss].type == 2) {
							// 判断
							this.thresss()
						} else if (this.tiMussL[this.indesss].type == 3) {
							// 单选
							this.twossss()
						} else if (this.tiMussL[this.indesss].type == 4) {
							// 多选
							this.onessss()
						}
					}
				} else {
					this.indesss += 1
					this.textareas = this.tiMussL[this.indesss].daants
				}
			},
			// 填空
			forssss() {
				this.textareas = this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].log_check

				this.textareas = this.tiMussL[this.indesss].daants
			},
			// 判断
			thresss() {
				let serrs = this.tiMussL[this.indesss].log_check
				this.tiMussL[this.indesss].vallsers = serrs == '不是' ? 'a' : 'b'
				this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].log_check

				this.values = this.tiMussL[this.indesss].vallsers

			},
			// 单选
			twossss() {
				let serrs = this.tiMussL[this.indesss].log_check
				this.tiMussL[this.indesss].tag_data_one.forEach((des, ind) => {
					if (serrs == des.title) {
						this.tiMussL[this.indesss].vallsers = ind
					}
				})
				this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].log_check

				this.vasll = this.tiMussL[this.indesss].vallsers
			},
			// 多选
			onessss() {
				let serrs = this.tiMussL[this.indesss].log_check.split(',')
				let ssdd = []
				serrs.forEach(item => {
					this.tiMussL[this.indesss].tag_data_one.forEach((des, ind) => {
						if (item == des.title) {
							ssdd.push(ind)
						}
					})
				})
				this.tiMussL[this.indesss].vallsers = ssdd
				this.tiMussL[this.indesss].daants = this.tiMussL[this.indesss].log_check.split(',')
				this.choice = this.tiMussL[this.indesss].vallsers
				this.duoData = this.tiMussL[this.indesss].daants
			},

			// 试卷详情
			async shijuanxq() {
				let data = await datiDelis({
					id: this.id
				})
				this.sdhyhdhf = data.data
			},
			qurenser() {
				this.prompts = false
				this.$router.push('/scorePage')
			},
			promptClick() {
				this.prompts = false
			},
			choiceDu(e, b) {
				if (this.choice.length == 0) {
					this.choice.push(e)
					this.duoData.push(b)
				} else {
					let set = this.choice.indexOf(e)
					if (set == -1) {
						this.choice.push(e)
						this.duoData.push(b)
					} else {
						this.choice.splice(set, 1)
						this.duoData.splice(set, 1)
					}
				}
			},
			multipless(e) {
				this.vasll = e
			},
			multiple(e) {
				this.values = e
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-textarea__inner {
		font-size: 14px;
	}

	/deep/.prompoter {
		width: 680px;
	}

	/deep/.prompoter>.el-dialog__header {
		text-align: center;
	}

	/deep/.prompoter>.el-dialog__header>.el-dialog__title {
		font-size: 32px;
	}

	article {
		height: 800px;
	}

	.popuFotter {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 30px;

		.quxiao {
			width: 180px;
			height: 60px;
			background: #D9D9D9;
			border-radius: 12px;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
		}

		.queren {
			width: 180px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
			margin-left: 20px;
		}
	}

	.shifoujiao {
		font-size: 20px;
		color: #B2B2B2;
		text-align: center;
		margin: 0 0 50px 0;
	}

	.blanks {
		width: 1000px;
		padding-bottom: 30px;
	}

	.Questions {
		display: flex;
		align-items: center;
		margin-bottom: 22px;

		.denText {
			font-size: 16px;
			color: #333333;
		}

		.imgBox {
			margin-right: 20px;

			&>img {
				width: 16px;
				height: 16px;
				vertical-align: middle;
				position: relative;
				bottom: 2px
			}
		}
	}

	.tijiao {
		width: 200px;
		height: 50px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 16px;
		text-align: center;
		line-height: 50px;
		color: #FFFFFF;
		margin: 0 auto 60px auto;
	}

	.fotter {
		display: flex;
		align-items: center;
		justify-content: center;

		&>div {
			display: flex;
			align-items: center;

			&>div {
				font-size: 18px;
				color: #B2B2B2;
			}

			&>img {
				width: 12px;
				height: 12px;
			}
		}

		.rights {
			zoom: 0.8;

			img {
				margin-left: 8px;
				transform: rotate(180deg);
			}
		}

		.lefts {
			zoom: 0.8;
			margin-right: 78px;

			img {
				margin-right: 8px;
			}
		}
	}

	.Multiple {
		display: flex;
		align-items: center;
		margin-bottom: 22px;

		.denText {
			font-size: 14px;
			color: #333333;
		}

		.imgBox {
			margin-right: 20px;

			&>img {
				width: 16px;
				height: 16px;
				vertical-align: middle;
				position: relative;
				bottom: 2px;
			}
		}
	}

	.timu {
		font-size: 15px;
		margin: 30px 0 40px 0;
	}

	.numbers {
		font-size: 16px;
		color: #333333;

		&>span {
			color: #3B99FE;
		}
	}

	.text {
		font-size: 14px;
		color: #999999;
		margin: 20px 0 40px 0;
	}

	.title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
	}

	.maxBox {
		width: 1180px;
		min-height: 500px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 30px 30px 50px 30px;
		margin: 30px 0 39px 0;
	}

	article {
		width: 1240px;
		margin: auto;
	}
</style>