import Vue from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import VueRouter from 'vue-router'
import store from "./store/index.js"
import ElementUI from 'element-ui'
// import "swiper/swiper-bundle.css"
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI)
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')