import request from "../utils/request.js"

// 我的活动
export function myHuodong(data) {
	return request({
		url: "/course.coursebag/get_order_list",
		method: "post",
		data
	});
}
// 我的活动详情
export function myHuodongdelsi(data) {
	return request({
		url: "/course.coursebag/get_order_view",
		method: "post",
		data
	});
}
// 取消活动
export function quxiaoHuong(data) {
	return request({
		url: "/course.coursebag/get_over",
		method: "post",
		data
	});
}
// 完成活动
export function wancHuong(data) {
	return request({
		url: "/course.coursebag/get_send",
		method: "post",
		data
	});
}
//、、、、、、、、、、、、、、、、、、、、、、
// 我的课程
export function myKencheng(data) {
	return request({
		url: "/course.course/info_list",
		method: "post",
		data
	});
}
///////////////////////////////////
// 我的答题
export function myDatis(data) {
	return request({
		url: "/course.coursequestion/get_log_list",
		method: "post",
		data
	});
}
////////////////
// 我的订单
export function myDingdan(data) {
	return request({
		url: "/goods.order/get_list",
		method: "post",
		data
	});
}
// 售后
export function dingDanShou(data) {
	return request({
		url: "/goods.refund/get_add",
		method: "post",
		data
	});
}
// 取消订单
export function quxiaoDing(data) {
	return request({
		url: "/goods.order/get_over",
		method: "post",
		data
	});
}
// 确认收获
export function querenShouh(data) {
	return request({
		url: "/goods.order/get_out",
		method: "post",
		data
	});
}
////////////////////
// 我的佣金
export function myYongjing(data) {
	return request({
		url: "/user.Usermoney/get_list",
		method: "post",
		data
	});
}
// 提现记录
export function tixianJilu(data) {
	return request({
		url: "/user.apply/get_list",
		method: "post",
		data
	});
}
// 钱包统计
export function qianbaoJlu(data) {
	return request({
		url: "/user.money/get_count",
		method: "post",
		data
	});
}
// 提现申请
export function tixianShengqs(data) {
	return request({
		url: "/user.apply/get_add",
		method: "post",
		data
	});
}
// 银行列表
export function yingHanglist(data) {
	return request({
		url: "/user.apply/blank",
		method: "post",
		data
	});
}
////////////////////////
// 我的团队
export function myTuanduis(data) {
	return request({
		url: "/user.level/get_user_list",
		method: "post",
		data
	});
}
// 商城订单列表
export function shangcListse(data) {
	return request({
		url: "/user.level/get_order_list",
		method: "post",
		data
	});
}
// 商城订单详情
export function shangchengDeli(data) {
	return request({
		url: "/user.level/get_order_view",
		method: "post",
		data
	});
}
// 活动订单列表
export function huodongListse(data) {
	return request({
		url: "/user.level/get_bag_order_list",
		method: "post",
		data
	});
}
// 活动订单详情
export function hudongDelis(data) {
	return request({
		url: "/user.level/get_bag_order_view",
		method: "post",
		data
	});
}