import request from "../utils/request.js"

// 一级
export function xueOnes(data) {
	return request({
		url: "/course.coursedetail/get_cate",
		method: "post",
		data
	});
}
// 二级
export function xueTwos(data) {
	return request({
		url: "/course.coursedetail/get_cate_node",
		method: "post",
		data
	});
}
// 列表
export function xueList(data) {
	return request({
		url: "/course.coursedetail/get_list",
		method: "post",
		data
	});
}
// 验证下载
export function yanzXia(data) {
	return request({
		url: "/course.coursedetail/info_check",
		method: "post",
		data
	});
}
//我的下载
export function myXiazai(data) {
	return request({
		url: "/course.coursedetail/get_down",
		method: "post",
		data
	});
}