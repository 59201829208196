import request from "../utils/request.js"

// 一级
export function huoYiji(data) {
	return request({
		url: "/course.coursebag/get_cate",
		method: "post",
		data
	});
}
// 二级
export function huoTwoji(data) {
	return request({
		url: "/course.coursebag/get_cate_node",
		method: "post",
		data
	});
}
// 活动列表
export function huoDongList(data) {
	return request({
		url: "/course.coursebag/get_list",
		method: "post",
		data
	});
}
// 活动详情
export function huoDongDeli(data) {
	return request({
		url: "/course.coursebag/get_view",
		method: "post",
		data
	});
}
// 下单验证
export function huodngYan(data) {
	return request({
		url: "/course.coursebag/info_check",
		method: "post",
		data
	});
}
// 下单
export function huoddoBaomin(data) {
	return request({
		url: "/course.coursebag/get_order_add",
		method: "post",
		data
	});
}
// 拉起支付
export function huoddoLaqi(data) {
	return request({
		url: "/course.coursebag/get_wx_order",
		method: "post",
		data
	});
}