<template>
	<div class="xiangqin" v-if="datas">
		<div class="heder">
			<div>订单详情</div>
			<div class="fanhui" @click="clickser" style="font-size: 20px;">返回 ></div>
		</div>
		<div class="dingdan">订单信息</div>
		<div class="textdat">
			订单号：<span>{{datas.order_up}}</span>下单时间：<span>{{datas.create_time}}</span>总金额：<span>{{datas.money}}</span>支付方式：<span>{{datas.type_text}}</span>状态：<span>{{datas.status_text}}</span>
		</div>
		<div class="shouhuo">报名信息</div>
		<div class="dizhis">{{datas.User.nickname}} {{datas.User.mobile}}</div>
		<div class="shouhuo">报名活动</div>
		<div class="commodity">
			<div><img :src="datas.Coursebag.image" alt="" /></div>
			<div class="rightse">
				<div class="title">{{datas.title}}</div>
				<div class="texts">报名时间：{{delis.date_text}}</div>
				<div class="texts">活动时间：{{delis.start_time}}~{{delis.end_time}}</div>
				<div class="texts">活动地址：{{delis.address}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		myHuodongdelsi
	} from "../../../api/infos.js"
	import {
		huoDongDeli
	} from "../../../api/huoDong.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		name: 'detalisPage',
		data() {
			return {
				ids: '',
				datas: '',
				delis: '',
			}
		},
		watch: {
			ids() {
				this.getData()
			}
		},
		methods: {
			async getData() {
				let data = await myHuodongdelsi({
					id: this.ids
				})
				if (data.code == 1) {
					this.datas = data.data
					this.getDelis(data.data.Coursebag.id)
				}
			},
			async getDelis(e) {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let data = await huoDongDeli({
					id: e
				})
				if (data.code == 1) {
					this.delis = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			clickser() {
				this.$emit('returnClick', '')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.commodity {
		display: flex;
		align-items: center;
		margin-top: 20px;

		.rightse {
			margin-left: 20px;

			.texts {
				font-size: 16px;
				margin-top: 10px;
				color: #333333;
			}

			.title {
				font-size: 28px;
				font-weight: bold;
				color: #333333;
			}
		}

		img {
			border-radius: 12px;
			width: 280px;
			height: 166px;
		}
	}

	.dizhis {
		font-size: 16px;
		margin-top: 16px;
		color: #666666;
	}

	.shouhuo {
		font-size: 20px;
		margin-top: 46px;
		font-weight: bold;
		color: #333333;
	}

	.textdat {
		font-size: 16px;
		color: #999999;
		margin-top: 16px;

		&>span {
			color: #333333;
			margin-right: 40px;
		}
	}

	.dingdan {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		margin-top: 42px;
	}

	.heder {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 28px;
		font-weight: bold;
		color: #333333;
		border-bottom: 1px solid #E5E5E5;
		padding-bottom: 26px;

		.fanhui {
			font-size: 18px;
		}
	}
</style>