<template>
	<div>
		<div class="title">
			我的资料
		</div>
		<div class="texts">
			*代表必填信息
		</div>
		<div class="names">*姓名</div>
		<div class="inputBox">
			<el-input v-model="names" placeholder="请填写您的姓名"></el-input>
		</div>
		<div class="names">*手机号</div>
		<div class="inputBox">
			<el-input v-model="iphone" placeholder="请填写您的手机号"></el-input>
		</div>
		<div class="names">性别</div>
		<div class="absye"></div>
		<input type="radio" name="radios" value="1" v-model="radio"><label>男</label>
		<input type="radio" name="radios" value="2" v-model="radio"><label>女</label>
		<div class="dnegji">等级</div>
		<div class="puTong">{{huiyua}}（ID：{{ids}})</div>
		<div class="bottons" @click="gengxing">更新资料</div>
	</div>
</template>

<script>
	import {
		myzhilaos,
		putXingxi
	} from "../../../api/user.js"
	export default {
		data() {
			return {
				radio: 1,
				names: '',
				iphone: '',
				huiyua: '',
				ids: ''
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			async getData() {
				let data = await myzhilaos()
				if (data.code == 1) {
					this.huiyua = data.data.group_title
					this.ids = data.data.id
					this.names = data.data.nickname
					this.iphone = data.data.mobile
					this.radio = data.data.gender
				}
			},
			// 更新信息
			async gengxing() {
				if (this.names == '' || this.iphone == '') {
					this.$message.error('请完整信息再提交');
					return
				}
				let data = await putXingxi({
					gender: this.radio,
					mobile: this.iphone,
					nickname: this.names,
				})
				if (data.code == 1) {
					this.$message({
						message: '更新成功',
						type: 'success'
					});
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	/deep/.el-input__inner {

		font-size: 16px;
	}

	.bottons {
		cursor: pointer;
		margin-top: 40px;
		text-align: center;
		line-height: 52px;
		width: 160px;
		height: 52px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 20px;
		font-weight: bold;
		color: #FFFFFF;
	}

	.dnegji {
		font-size: 18px;
		margin-top: 20px;
		color: #333333;
	}

	.puTong {
		font-size: 16px;
		color: #999999;
		margin-top: 16px;
	}

	label {
		font-size: 17px;
		color: #333333;
		margin: 0 30px 0 10px;
		position: relative;
		bottom: 4px;
	}

	.absye {
		margin-bottom: 14px;
	}

	.inputBox {
		width: 360px;
		margin: 8px 0 26px 0;
	}

	.names {
		font-size: 18px;
		color: #333333;
	}

	.texts {
		font-size: 16px;
		color: #B2B2B2;
		margin: 22px 0 28px 0;
	}

	.title {
		font-size: 28px;
		font-weight: bold;
		color: #333333;
	}
</style>