<template>
	<div class="mainx" id="Msersdd">
		<div class="haeder">
			<div>
				<img :src="dataLi.image" alt="">
			</div>
			<div class="right">
				<div class="laist">
					<div class="onew">{{dataLi.title}}</div>
					<div class="twow">
						<img src="../../assets/logo.png" alt="">
						{{dataLi.view_text}}
					</div>
				</div>
				<div class="botton" v-if="dataLi.vip == 1">
					免费
				</div>
				<div class="bottons" v-else>
					VIP
				</div>
				<div class="jiehao">
					<div v-html="dataLi.content"></div>
				</div>
			</div>
		</div>
		<article class="maxbox">
			<div class="left">
				<div class="mengbans" @click="clickMneban" v-show="staerwww">
					<div class="menbanees">
						<img src="../../assets/bofnag.png" alt="" />
					</div>
					<img :src="dataLi.video_banner" alt="" class="minImg" v-if="dataLi.video_banner" />
				</div>
				<video v-show="!staerwww" width="100%" height="100%" controls controlsList="nodownload"
					:src="dataLi.video" id="imgss">
					<source src="movie.mp4" type="video/mp4">
					<source src="movie.ogg" type="video/ogg">
					<source src="movie.webm" type="video/webm">
					您的浏览器不支持 video 标签。
				</video>
			</div>
			<div class="right">
				<div class="tuijian">
					推荐课程
				</div>
				<div class="minBox" v-for="(item,index) in datalist" :key="index" :class="{'margings': index == 2}"
					@click="routers(item.id)">
					<img :src="item.image" alt="">
					<div class="titllr">{{item.title}}</div>
					<div class="fotters">
						<div class="left"><img src="../../assets/logo.png" alt="">{{item.view_text}}</div>
						<div class="bott" v-if="item.vip == 1">免费</div>
						<div class="botts" v-else>VIP</div>
					</div>
				</div>
			</div>
		</article>
	</div>
</template>

<script>
	import {
		keCheDelis,
		keChenList,
		yanZhengxue,
		tiJiaoxue
	} from "../../api/keChen.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				id: '',
				dataLi: '',
				datalist: '',
				staerwww: true
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.getData()
			this.getLists()
		},
		methods: {
			// 点击播放
			async clickMneban() {
				var myVideo = document.getElementById('imgss');
				if (this.$store.state.user.token) {
					let data = await yanZhengxue({
						id: this.id
					})
					if (data.data == 1) {
						this.getData()
						this.tijiaoxuexi()
						this.staerwww = false
						setTimeout(() => {
							myVideo.play();
						}, 1000)
					} else if (data.data == 2 && data.code == 1) {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 获取列表
			async getLists() {
				let ser = {
					limit: 3,
					is_hot: 1
				}
				let data = await keChenList(ser)
				this.datalist = data.data.list
			},
			// 获取详情
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Msersdd')
				});
				this.staerwww = true
				let data = await keCheDelis({
					id: this.id
				})
				console.log('pihjihohhui', data)
				this.dataLi = data.data
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 提交学习
			async tijiaoxuexi() {
				await tiJiaoxue({
					id: this.id
				})
			},
			routers(e) {
				if (this.id == e) {
					return
				}
				this.id = e
				this.$router.push({
					path: '/zaiXingq',
					query: {
						id: e
					}
				})
				this.getData()
			},
		}
	}
</script>
<style lang="scss" scoped>
	.mengbans {
		width: 800px;
		background: rgba(255, 255, 255, 0.4);
		height: 100%;
		position: relative;
		top: 0;
		z-index: 666;
		display: flex;
		align-items: center;
		justify-content: center;

		.menbanees {
			cursor: pointer;
			top: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(51, 51, 51, 0.6);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&>img {
			width: 100%;
		}
	}

	.minBox {
		zoom: 0.8;
		padding-bottom: 30px;
		border-bottom: 1px solid #E5E5E5;
		margin-bottom: 30px;

		&>img {
			width: 350px;
			height: 210px;
			border-radius: 12px;
		}

		.fotters {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;

			.bott {
				width: 60px;
				height: 32px;
				border: 1px solid #3B99FE;
				border-radius: 16px;
				font-size: 18px;
				text-align: center;
				line-height: 32px;
				font-weight: bold;
				color: #3B99FE;
			}

			.botts {
				width: 60px;
				height: 32px;
				border: 1px solid #FF9000;
				border-radius: 16px;
				font-size: 18px;
				font-weight: bold;
				color: #FF9000;
				text-align: center;
				line-height: 32px;
			}

			&>.left {
				font-size: 16px;
				color: #333333;

				&>img {
					width: 21px;
					height: 21px;
					vertical-align: middle;
					margin-right: 5px;
					position: relative;
					bottom: 2px;
				}
			}
		}

		.titllr {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			width: 350px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}

	.maxbox {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;

		&>.left {
			position: relative;
			width: 800px;
			padding: 30px;
			background: #FFFFFF;
			border-radius: 12px;
		}

		&>.right {
			padding: 0 30px;
			width: 291px;
			// height: 1150px;
			background: #FFFFFF;
			border-radius: 12px;

			.tuijian {
				margin-bottom: 30px;
				padding: 30px 0 0 0;
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}
		}
	}

	.margings {
		border-bottom: 0;
	}

	.haeder {
		padding: 30px;
		background: #FFFFFF;
		border-radius: 12px;
		margin: 30px 0;
		display: flex;

		.right {
			margin-left: 40px;
			flex: 1;

			.jiehao {
				font-size: 16px;
				color: #666666;
				line-height: 32px;
			}

			.botton {
				zoom: 0.6;
				width: 80px;
				height: 44px;
				border: 1px solid #3B99FE;
				border-radius: 22px;
				font-size: 24px;
				text-align: center;
				line-height: 44px;
				color: #3B99FE;
				margin: 20px 0 40px 0;
			}

			.bottons {
				zoom: 0.6;
				width: 80px;
				height: 44px;
				border: 1px solid #F5B53A;
				border-radius: 22px;
				font-size: 24px;
				text-align: center;
				line-height: 44px;
				color: #F5B53A;
				margin: 20px 0 40px 0;
			}

			.laist {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.onew {
					width: 600px;
					font-size: 16px;
					font-weight: bold;
					color: #333333;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
				}

				.twow {
					zoom: 0.76;

					&>img {
						width: 21px;
						height: 21px;
						vertical-align: middle;
						position: relative;
						bottom: 2px;
					}

					font-size: 16px;
					font-weight: 500;
					color: #333333;
				}
			}
		}

		&>div>img {
			zoom: 0.7;
			width: 540px;
			height: 320px;
			border-radius: 12px;
		}
	}

	.mainx {
		width: 1240px;
		margin: auto;
	}
</style>