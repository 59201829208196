<template>
	<div class="maxiing" id="Mshjhd">
		<div class="guanyu">关于我们</div>
		<div class="conter" v-html="dats"></div>
	</div>
</template>

<script>
	import {
		peizhixiang
	} from "../api/user.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				dats: '',
			}
		},
		mounted() {
			this.getdata()
		},
		methods: {
			async getdata() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Mshjhd')
				});
				let data = await peizhixiang({
					"keylist": [
						'about'
					]
				})
				if (data.code == 1) {
					this.dats = this.formatRichText(data.data.about)
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			formatRichText(html) { // 富文本内容格式化
				return html && html.replace(/<img[^>]*>/gi, function(match) { // 查找所有的 img 元素
					return match.replace(/style=".*"/gi, '').replace(/style='.*'/gi,
						'') // 删除找到的所有 img 元素中的 style 属性
				}).replace(/<img/gi, '<img style="width:100%;"') // 对 img 元素增加 style 属性，并设置宽度为 100%
			},
		}
	}
</script>

<style lang="scss" scoped>
	.conter {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 30px;
		margin-bottom: 60px;
	}

	.guanyu {
		font-size: 20px;
		padding: 20px 0;
		font-weight: bold;
		color: #333333;
	}

	.maxiing {
		width: 1240px;
		margin: auto;
	}
</style>