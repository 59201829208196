<template>
	<article>
		<div class="zhifjie">
			支付结果
		</div>
		<div class="seccBox">
			<div class="imgers"><i class="el-icon-circle-check"></i></div>
			<div class="chengts">支付成功，感谢您的惠顾！</div>
			<div class="bujuBox">
				<div class="ones">订单编号</div>
				<div class="twos">{{lister.order_up}}</div>
				<div class="thres">实付金额</div>
				<div class="fouers">￥{{lister.money}}</div>
				<div class="fives">支付方式</div>
				<div class="sixs">{{lister.type == 4? '支付宝支付':'微信支付'}}</div>
			</div>
			<div class="minTxt">
				支付成功，我们将尽快为您发货！您可以在订单中心中查看此订单状态
			</div>
			<div class="fotterBox">
				<div class="cakan" @click="xiangqs">查看订单</div>
				<div class="gouwu" @click="reoyerew">继续购物</div>
			</div>
		</div>
	</article>
</template>

<script>
	import {
		shoppXiangqs
	} from "../../api/shoppIng.js"
	export default {
		data() {
			return {
				lister: '',
				id: ''
			}
		},
		mounted() {
			this.id = this.$route.query.id
			if (this.$route.query.states) {
				this.getData()
			} else {
				this.lister = JSON.parse(this.$route.query.data);
			}
		},
		methods: {
			async getData() {
				let data = await shoppXiangqs({
					id: this.id
				})
				if (data.code == 1) {
					this.lister = data.data
				}
			},
			reoyerew() {
				this.$router.push('/zaiXian')
			},
			xiangqs() {
				this.$router.push({
					path: '/memberPage',
					query: {
						unms: 0,
						delis: true,
						idss:this.id
					}
				})
			}
		}

	}
</script>

<style lang="scss" scoped>
	/deep/.el-icon-circle-check:before {
		font-size: 80px
	}

	.fotterBox {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		.cakan {
			cursor: pointer;
			zoom: 0.76;
			width: 240px;
			height: 60px;
			border: 1px solid #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			color: #3B99FE;
			text-align: center;
			line-height: 60px;
			margin-right: 20px;
		}

		.gouwu {
			cursor: pointer;
			zoom: 0.76;
			width: 240px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
		}
	}

	.bujuBox {
		display: flex;
		justify-content: center;
		font-size: 14px;
		padding: 34px 0 56px 0;

		.ones {
			color: #999999;
		}

		.twos {
			color: #333333;
			margin: 0 79px 0 25px;
		}

		.thres {
			color: #999999;
		}

		.fouers {
			color: #333333;
			margin: 0 79px 0 25px;
		}

		.fives {
			color: #999999;
			margin-right: 25px;
		}

		.sixs {
			color: #333333;
		}
	}

	.chengts {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		text-align: center;
		margin-top: 15px;
	}

	.seccBox {
		width: 1240px;
		height: 480px;
		background: #FFFFFF;
		border-radius: 12px;

		.minTxt {
			font-size: 14px;
			color: #333333;
			text-align: center;
		}

		.imgers {
			padding-top: 40px;
			display: flex;
			justify-content: center;
		}
	}

	.zhifjie {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		padding: 40px 0 32px 0;
	}

	article {
		width: 1240px;
		margin: auto;
		padding-bottom: 30px;
	}
</style>