import { render, staticRenderFns } from "./detailsPage.vue?vue&type=template&id=cc5b8964&scoped=true"
import script from "./detailsPage.vue?vue&type=script&lang=js"
export * from "./detailsPage.vue?vue&type=script&lang=js"
import style0 from "./detailsPage.vue?vue&type=style&index=0&id=cc5b8964&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc5b8964",
  null
  
)

export default component.exports