import VueRouter from 'vue-router'
import store from '../store/index.js'
// import Layout from '../layout/layIndex.vue'
import homepage from '../view/homePage.vue'
import zaixKe from '../view/zaixKen/indexPage.vue'
import zaiXingq from '../view/zaixKen/detailsPage.vue'
import zhiBo from '../view/zhibo/indexPage.vue'
import zhiboXq from '../view/zhibo/detailsPage.vue'
import xuexi from '../view/xuexiz/indexPage.vue'
import huoDon from '../view/huodong/indexPage.vue'
import huodXq from '../view/huodong/detailsPage.vue'

import gouWuChes from '../view/zaixschen/gouWuChes.vue'
import zaiXian from '../view/zaixschen/indexPage.vue'
import zaiXidel from '../view/zaixschen/detailsPage.vue'
import deloePage from '../view/zaixschen/deloePage.vue'
import settLement from '../view/zaixschen/settLement.vue'
import succePage from '../view/zaixschen/succePage.vue'
import answerPage from '../view/answer/indexPage.vue'
import answDeli from '../view/answer/detailsPage.vue'
import scorePage from '../view/answer/scorePage.vue'
import memberPage from '../view/member/indexPage.vue'
import guanMys from '../view/guanMys.vue'
import yongHxyi from '../view/yongHxyi.vue'
import jixuDatais from "../view/answer/juxuDat.vue"
// 学校详情laosDeliss
import xuexiaoPage from "../view/xueLaos/xuexiPage.vue"
import laoshiPage from "../view/xueLaos/laoshiPage.vue"
import laosDeliss from "../view/xueLaos/laosDeliss.vue"
const routes = [
	// {
	// path: '/',
	// // component: Layout,
	// // redirect: '/homePage',
	// children: [
	{
		path: '/',
		component: homepage,
		meta: {
			state: 0,
		},
	}, {
		path: '/zaixKe',
		component: zaixKe,
		meta: {
			state: 1,
		},
	}, {
		path: '/zaiXingq',
		component: zaiXingq,
		meta: {
			state: 1,
		},
	}, {
		path: '/zhiBo',
		component: zhiBo,
		meta: {
			state: 2,
		},
	}, {
		path: '/zhiboXq',
		component: zhiboXq,
		meta: {
			state: 2,
		},
	}, {
		path: '/xuexi',
		component: xuexi,
		meta: {
			state: 3,
		},
	}, {
		path: '/huoDon',
		component: huoDon,
		meta: {
			state: 4,
		},
	}, {
		path: '/huodXq',
		component: huodXq,
		meta: {
			state: 4,
		},
	}, {
		path: '/zaiXian',
		component: zaiXian,
		meta: {
			state: 5,
		},
	},
	{
		path: '/gouWuChes',
		component: gouWuChes,
		meta: {
			state: 5,
		},
	},
	{
		path: '/zaiXidel',
		component: zaiXidel,
		meta: {
			state: 5,
		},
	}, {
		path: '/deloePage',
		component: deloePage,
		meta: {
			state: 5,
		},
	}, {
		path: '/settLement',
		component: settLement,
		meta: {
			state: 5,
		},
	}, {
		path: '/succePage',
		component: succePage,
		meta: {
			state: 5,
		},
	}, {
		path: '/answerPage',
		component: answerPage,
		meta: {
			state: 6,
		},
	}, {
		path: '/answDeli',
		component: answDeli,
		meta: {
			state: 6,
		},
	}, {
		path: '/scorePage',
		component: scorePage,
		meta: {
			state: 6,
		},
	}, {
		path: '/jixuDatais',
		component: jixuDatais,
		meta: {
			state: 6,
		},
	}, {
		path: '/memberPage',
		component: memberPage,
		meta: {
			state: 7,
		},
	}, {
		path: '/laoshiPage',
		component: laoshiPage,
		meta: {
			state: 9,
		},
	}, {
		path: '/laosDeliss',
		component: laosDeliss,
		meta: {
			state: 9,
		},
	}, {
		path: '/guanMys',
		component: guanMys,
		meta: {
			state: 12,
		},
	}, {
		path: '/yongHxyi',
		component: yongHxyi,
		meta: {
			state: 12,
		},
	}, {
		path: '/xuexiaoPage',
		component: xuexiaoPage,
		meta: {
			state: 12,
		},
	},
]
// }, ]

const router = new VueRouter({
	routes, // `routes: routes` 的缩写
	// return 期望滚动到哪个的位置
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			// vue2.0  x  y  控制
			// vue3.0  left  top 控制
			return {
				x: 0,
				y: 0
			}
		}
	}
})
// 全局前置路由守卫，表示在每次切换路由之前调用，针对所有的路由
router.beforeEach((to, from, next) => { // to表示去哪个路由，from表示来自哪个路由，next表示放行
	// console.log(to, from)
	if (to.query.unms) {
		if (to.query.unms == '2') {
			store.commit('user/setType', 8)
			next()
			return
		}
	}
	store.commit('user/setType', to.meta.state)
	next()
})
export default router;