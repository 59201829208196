<template>
	<div class="xiangqin">
		<div v-if="data">
			<div class="heder">
				<div>订单详情</div>
				<div class="fanhui" @click="clickser">返回 ></div>
			</div>
			<div class="dingdan">订单信息</div>
			<div class="textdat">
				订单号：<span>{{data.order_up}}</span>下单时间：<span>{{data.create_time}}</span>数量：<span>{{data.sum}}</span>总金额：<span>{{data.money}}</span>支付方式：<span>{{data.type_text}}</span>状态：<span>{{data.status_text}}</span>
			</div>
			<div class="shouhuo">收货地址</div>
			<div class="dizhis">{{data.User.nickname}} {{data.User.mobile}}</div>
			<div class="dizhiser">{{data.address_address}}{{data.address_detal}}</div>
			<div class="shouhuo">购买商品</div>
			<div class="commodity" v-for="(dds,ssd) in data.Goodsorderinfo" :key="ssd">
				<div><img :src="dds.goods_image" alt="" /></div>
				<div class="textData">
					<div class="titl">{{dds.goods_title}}</div>
					<div class="text">{{dds.goods_sku_text}}</div>
				</div>
				<div class="numer">x{{dds.sum}}</div>
				<div class="jiage">￥{{dds.goods_money}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		shoppXiangqs
	} from "../../../api/shoppIng.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		name: 'detalisPage',
		data() {
			return {
				data: '',
				state: ''
			}
		},
		watch: {
			state() {
				this.getData()
			}
		},
		methods: {
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let data = await shoppXiangqs({
					id: this.state
				})
				if (data.code == 1) {
					this.data = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			clickser() {
				this.$emit('returnClick', '')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.commodity {
		display: flex;
		align-items: center;
		margin-top: 20px;

		.jiage {
			font-size: 18px;
			color: #333333;
		}

		.numer {
			font-size: 18px;
			color: #999999;
			margin-right: 30px;
		}

		.textData {
			width: 930px;
			margin-left: 20px;

			.titl {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}

			.text {
				font-size: 16px;
				color: #999999;
				margin-top: 18px;
			}
		}

		img {
			width: 120px;
			height: 120px;
		}
	}

	.dizhiser {
		font-size: 16px;
		margin-top: 12px;
		color: #666666;
	}

	.dizhis {
		font-size: 16px;
		margin-top: 16px;
		color: #666666;
	}

	.shouhuo {
		font-size: 20px;
		margin-top: 46px;
		font-weight: bold;
		color: #333333;
	}

	.textdat {
		font-size: 16px;
		color: #999999;
		margin-top: 16px;

		&>span {
			color: #333333;
			margin-right: 40px;
		}
	}

	.dingdan {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		margin-top: 42px;
	}

	.heder {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		border-bottom: 1px solid #E5E5E5;
		padding-bottom: 26px;

		.fanhui {
			font-size: 18px;
		}
	}
</style>