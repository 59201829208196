<template>
	<div class="maxin" id="MsDati">
		<!-- 标签 -->
		<div class="tiprsHer">
			<div class="lines">
				<div class="left">一级分类：</div>
				<div class="right">
					<div v-for="(item,index) in oneDat" :key="index" :class="{'bottonsre':ones== item.id}"
						@click="clickOne(item.id)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="lines">
				<div class="left">二级分类：</div>
				<div class="right">
					<div v-for="(item,index) in twoDat" :key="index" :class="{'bottonsre':twos== item.id}"
						@click="clickTwo(item.id)">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="biaoqian">
			<div class="title">在线题库</div>
			<div class="text">共找到<span style="color: #333333;">{{total}}</span>个结果</div>
		</div>
		<div class="arlicle" v-if="datalist.length >0">
			<div class="lines" v-for="(item,index) in datalist" :key="index">
				<div class="left">
					{{item.title}}
				</div>
				<div class="right">
					<div class="number">
						总题数：{{item.sum_list.count}}题
					</div>
					<div class="tupian" @click="routers(item.id)" v-if="item.log_list === null">开始答题</div>
					<div class="tupiasnsew" @click="routssduns(item.id,item.log_list.id)"
						v-else-if="item.log_list.cut == 2">答题解析</div>
					<div class="tupiasn" @click="routsssers(item.id,item.log_list.id)" v-else>继续答题</div>
				</div>
			</div>
		</div>
		<div v-else class="zhanwuShu">
			<img src="../../assets/quesheng.png" alt="" />
			<div>暂无数据</div>
		</div>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="20" @current-change="pagese"
				@prev-click='pearDta' @next-click='mextDat'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		datiyIJI,
		datiErji,
		datList,
		yanzDtis,
		datiTijiao
	} from "../../api/daTi.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				page: 1,
				total: 0,
				checkList: [],
				ones: 0,
				twos: 0,
				threes: 0,
				oneDat: [],
				twoDat: [],
				datalist: [],
				valueTit: ''
			}
		},
		watch: { // 监听,当路由发生变化的时候执行
			'$route'() {
				if (this.$route.query.title) {
					this.valueTit = this.$route.query.title
				}
				this.getLists()
			},
		},
		mounted() {
			if (this.$route.query.title) {
				this.valueTit = this.$route.query.title
			}
			this.getData()
			this.getDaters()
			this.getLists()
		},
		methods: {
			// 一级分类
			async getData() {
				let data = await datiyIJI()
				this.oneDat = data.data.list
				this.oneDat.unshift({
					title: '全部',
					id: 0
				})

			},
			//二级分类
			async getDaters() {
				let data = await datiErji()
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取二级分类
			async getDsherss() {
				let data = await datiyIJI({
					pid: this.ones
				})
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取列表
			async getLists() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#MsDati')
				});
				let ser = {
					page: this.page,
					limit: 20,
					title: this.valueTit
				}
				if (this.ones) {
					ser.cid = this.ones
				} else if (this.twos) {
					ser.cid = this.twos
				}
				let data = await datList(ser)
				if (data.code == 1) {
					this.datalist = data.data.list
					this.total = data.data.total
				} else {
					this.datalist = []
					this.total = 0
				}
				this.valueTit = ''
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 继续答题
			async routsssers(e, b) {
				if (this.$store.state.user.token) {
					let data = await yanzDtis({
						id: e
					})
					if (data.data == 1) {
						this.tijiaodat(e)
						this.$router.push({
							path: '/jixuDatais',
							query: {
								id: e,
								logid: b
							}
						})
					} else if (data.data == 2 && data.code == 1) {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			async routssduns(e, b) {
				if (this.$store.state.user.token) {
					let data = await yanzDtis({
						id: e
					})
					if (data.data == 1) {
						this.tijiaodat(e)
						this.$router.push({
							path: '/scorePage',
							query: {
								id: e,
								logid: b
							}
						})
					} else {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 开始答题
			async routers(e) {
				if (this.$store.state.user.token) {
					let data = await yanzDtis({
						id: e
					})
					if (data.data == 1) {
						this.tijiaodat(e)
						this.$router.push({
							path: '/answDeli',
							query: {
								id: e
							}
						})
					} else {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 提交答题
			async tijiaodat(e) {
				await datiTijiao({
					id: e
				})
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getLists()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getLists()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getLists()
			},
			clickOne(e) {
				this.ones = e
				this.twos = ''
				if (this.ones == 0) {
					this.getDaters()
				} else {
					this.getDsherss()
				}
				this.getLists()
			},
			clickTwo(e) {
				this.twos = e
				this.ones = ''
				this.getLists()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.zhanwuShu {
		text-align: center;
		padding: 200px 0 220px 0;

		&>img {
			width: 200px;
			height: 100px;
		}

		&>div {
			margin-top: 10px;
			font-size: 14px;
			color: #B2B2B2;
		}
	}

	.arlicle {
		width: 1180px;
		padding: 30px 30px 16px 30px;
		background: #FFFFFF;
		border-radius: 12px;

		.lines {
			margin-bottom: 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&>.right {
				zoom: 0.8;
				display: flex;
				align-items: center;

				&>img {
					width: 21px;
					height: 21px;
					position: relative;
					top: 1px;
				}

				.number {
					font-size: 16px;
					color: #999999;
					margin-right: 50px;
				}

				.tupiasnsew {
					cursor: pointer;
					width: 82px;
					height: 28px;
					background: #F5B53A;
					border-radius: 4px;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 28px;
				}

				.tupiasn {
					cursor: pointer;
					width: 82px;
					height: 28px;
					background: #56ac00;
					border-radius: 4px;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 28px;
				}

				.tupian {
					cursor: pointer;
					width: 82px;
					height: 28px;
					background: #3B99FE;
					border-radius: 4px;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 28px;
				}
			}

			&>.left {
				font-size: 14px;
				color: #333333;
			}
		}
	}

	.paging {
		display: flex;
		justify-content: center;
		padding: 55px 0 20px 0;
		zoom: 0.76;
	}

	.biaoqian {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px 0 20px 0;

		.title {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}

		.text {
			font-size: 14px;
			color: #999999;
		}
	}


	.tiprsHer {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 20px 30px 10px 30px;
	
		.lines {
			display: flex;
	
			.right {
				font-size: 14px;
				color: #666666;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
	
				&>div {
					font-size: 14px;
					margin-right: 22px;
					margin-bottom: 10px;
					padding: 0 5px;
					height: 36px;
					line-height: 36px;
				}
	
				.bottonsre {
					padding: 0 6px;
					height: 28px;
					background: #DEEEFF;
					border-radius: 4px;
					font-size: 14px;
					line-height: 28px;
					color: #3B99FE;
				}
			}
	
			.left {
				padding-top: 6px;
				min-width: 100px;
				font-size: 14px;
				color: #333333;
			}
		}
	}

	.maxin {
		padding-top: 30px;
		width: 1240px;
		margin: auto;
	}
</style>