<template>
	<div class="maxiing" id="Mshjhd">
		<div class="guanyu">用户协议</div>
		<div class="conter" v-html="dats"></div>
	</div>
</template>

<script>
	import {
		peizhixiang
	} from "../api/user.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				dats: '',
			}
		},
		mounted() {
			this.getdata()
		},
		methods: {
			async getdata() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Mshjhd')
				});
				let data = await peizhixiang({
					"keylist": [
						'user_pact'
					]
				})
				if (data.code == 1) {
					this.dats = data.data.user_pact
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.conter {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 30px;
		margin-bottom: 60px;
	}

	.guanyu {
		font-size: 20px;
		padding: 20px 0;
		font-weight: bold;
		color: #333333;
	}

	.maxiing {
		width: 1240px;
		margin: auto;
	}
</style>