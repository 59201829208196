<template>
	<article>
		<div class="maxBoxs" id="MsDatis">
			<div class="headers">
				<div class="lefter"><img src="../../assets/naCheng.png" alt=""></div>
				<div class="antsers">
					<div class="title">提交成功！</div>
					<div class="text">您可以按照对应题目，查看答案解析</div>
				</div>
			</div>
			<div class="answer" v-for="(item,index) in dataList" :key="index">
				<div class="bioaopee">{{item[0].type_text}}</div>
				<div v-for="(val,ind) in item" :key="ind + 'se'">
					<div class="timus">
						{{ind+1}}: {{val.title}}
					</div>
					<!-- <div class="daanshji" v-for="(dde,ssr) in ">
						A.弈秋，通国之善弈者也 译文：弈秋是全国最善于下围棋的人
					</div> -->
					<div class="template">
						<div class="lefts">
							<div class="one">正确答案</div>
							<div class="two" v-if="index == 'type1'">{{val.ok1}}</div>
							<div class="two" v-else-if="index == 'type2'">{{val.ok2}}</div>
							<div class="two" v-else-if="index == 'type3'">{{val.ok3}}</div>
							<div class="two" v-else-if="index == 'type4'">{{val.ok4}}</div>
						</div>
						<div class="rights">
							<div class="one">我的答案</div>
							<div class="two">{{val.log_check}}</div>
						</div>
					</div>
					<div class="cankao">
						参考解析
					</div>
					<div class="kaodian" v-html="val.content"></div>
					<!-- <div class="kaodian">
						【考点】本题考察秦文学-孟子-作家常识
					</div>
					<div class="jiexi">
						【解析】“民贵君轻”是孟子提出的社会政治思想。意为从天下国家的立场来看，民是基础，是根本，民比君更加重要。“民贵君轻”是孟子仁政学说的核心，具有民本主义色彩。对中国后世的思想家有极大的影响。故本题选D。
					</div> -->
				</div>
			</div>
		</div>
	</article>
</template>

<script>
	import {
		datiKiexi
	} from "../../api/daTi.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				id: '',
				logId: '',
				dataList: []
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.logId = this.$route.query.logid
			this.getDatas()
		},
		methods: {
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#MsDatis')
				});
				let data = await datiKiexi({
					question_id: this.id,
					log_id: this.logId
				})
				if (data.code == 1) {
					this.dataList = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.template {
		display: flex;
		align-items: center;
		margin-top: 35px;

		.lefts {
			text-align: center;
			margin-right: 70px;

			.one {
				font-size: 14px;
				color: #333333;
			}

			.two {
				margin-top: 5px;
				font-size: 20px;
				font-weight: bold;
				color: #51CB65;
			}
		}

		.rights {
			text-align: center;

			.one {
				font-size: 14px;
				color: #333333;
			}

			.two {
				margin-top: 5px;
				font-size: 20px;
				font-weight: bold;
				color: #E23333;
			}
		}
	}

	.bioaopee {
		margin-top: 20px;
		font-size: 20px;
		font-weight: bold;
		color: #333333;
	}

	.answer {
		.jiexi {
			font-size: 16px;
			color: #666666;
			line-height: 32px;
		}

		.kaodian {
			margin-bottom: 10px;
			font-size: 14px;
			color: #666666;
		}

		.cankao {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			margin: 20px 0 25px 0;
		}

		.daanshji {
			color: #666666;
			font-size: 14px;
			margin-top: 16px;
		}

		.timus {
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin-top: 46px;
		}
	}

	.headers {
		width: 1180px;
		height: 100px;
		background: #F8F8F8;
		border-radius: 12px;
		display: flex;
		align-items: center;
		display: flex;
		align-items: center;

		.antsers {
			.title {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}

			.text {
				margin-top: 6px;
				font-size: 14px;
				color: #333333;
			}
		}

		.lefter {
			margin: 0 14px 0 46px;
		}

		img {
			width: 60px;
			height: 60px;
			vertical-align: middle;
		}
	}

	.maxBoxs {
		min-height: 800px;
		padding: 30px 34px;
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
	}

	article {
		width: 1240px;
		margin: auto;
		padding: 30px 0;
	}
</style>