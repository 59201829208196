import request from "../utils/request.js"

// 一级
export function datiyIJI(data) {
	return request({
		url: "/course.coursequestion/get_cate",
		method: "post",
		data
	});
}
// 二级
export function datiErji(data) {
	return request({
		url: "/course.coursequestion/get_cate_node",
		method: "post",
		data
	});
}
// 答题列表
export function datList(data) {
	return request({
		url: "/course.coursequestion/get_list",
		method: "post",
		data
	});
}
// 答题详情
export function datiDelis(data) {
	return request({
		url: "/course.coursequestion/get_view",
		method: "post",
		data
	});
}
// 验证答题
export function yanzDtis(data) {
	return request({
		url: "/course.coursequestion/info_check",
		method: "post",
		data
	});
}
// 点击提交
export function datiTijiao(data) {
	return request({
		url: "/course.coursequestion/info_add",
		method: "post",
		data
	});
}
// 题列表
export function datiListess(data) {
	return request({
		url: "/course.coursequestion/get_info_list",
		method: "post",
		data
	});
}
// 提交试卷
export function tijiaoshijaun(data) {
	return request({
		url: "/course.coursequestion/get_log_add",
		method: "post",
		data
	});
}
// 继续答题
export function juxudatiser(data) {
	return request({
		url: "/course.coursequestion/get_info_check_new",
		method: "post",
		data
	});
}
// 答题解析
export function datiKiexi(data) {
	return request({
		url: "/course.coursequestion/get_info_check",
		method: "post",
		data
	});
}
