import request from "../utils/request.js"

// 校区列表
export function xiaoQuList(data) {
	return request({
		url: "/course.course/get_school",
		method: "post",
		data
	});
}
// 校区详情
export function xiaoDelis(data) {
	return request({
		url: "/course.course/get_school_view",
		method: "post",
		data
	});
}
// 校区详情列表
export function xiaoDelistew(data) {
	return request({
		url: "/course.course/get_schoolimage",
		method: "post",
		data
	});
}
// 老师列表
export function laoshiKList(data) {
	return request({
		url: "/course.course/get_teacher",
		method: "post",
		data
	});
}
// 老师详情
export function laoshiDelis(data) {
	return request({
		url: "/course.course/get_teacher_view",
		method: "post",
		data
	});
}