<template>
	<div id="MaxHomes">
		<div v-if="datas">
			<div class="Carousel">
				<!-- :height="imgHeight" -->
				<el-carousel :interval="5000" trigger="click" :height="imgHeight">
					<el-carousel-item v-for="(item,index) in datas.banner" :key="index">
						<img :src="item.image" alt="" class="imgsser" ref="imgHeight" @load="imgLoad"
							@click="routeeHs(item.url)">
					</el-carousel-item>
				</el-carousel>
			</div>

			<div class="homeIcons" style="margin-top: 30px;">
				<div style="cursor: pointer;" class="homeIconsBox" v-for="(item,index) in datas.quick" :key="index"
					:style="{background:item.color}" @click="routerssee(item)">
					<div><img :src="item.icon" alt=""></div>
					<div class="right">
						<div class="titlle">{{item.title}}</div>
						<div class="text">{{item.text}}</div>
					</div>
				</div>
			</div>
			<div v-for="(ser,indd) in datas.body" :key="indd + 'see'">
				<div v-if="ser.type == 4">
					<!-- 活动培训 -->
					<div class="ReuseBox bnotteTwo">
						<div class="ReuseBox-header" style="margin: 40px 0 50px 0;">
							<div style="display: flex;align-items: center;">
								<div class="tit">{{ser.title}}</div>
								<div class="minText">超多精彩活动</div>
							</div>
							<div class="gengduo" @click="gengduohuodng">
								查看更多
								<img src="../../src/assets/xiaojian.png" alt="">
							</div>
						</div>
						<div class="hudong">
							<div class="huodngBox" v-for="(item,index) in ser.list"
								:style="{'marginRight':index == 3?'0':'24px'}" :key="index"
								@click="routersssws(item.id)">
								<img :src="item.image" alt="">
								<div class="titl">{{item.title}}</div>
								<div class="item">{{item.date_text}}</div>
								<div class="buju">
									<div class="text">{{item.view_text}}</div>
									<div class="botton">报名</div>
								</div>
							</div>
						</div>
					</div>
					<div class="jiequImg">
						<img :src="ser.image" alt="" class="advertisement">
					</div>
				</div>
				<div v-else-if="ser.type == 6">
					<div class="jiequImg">
						<img :src="ser.image" alt="" class="advertisement">
					</div>
					<div class="ReuseBox">
						<div class="ReuseBox-header" style="margin-bottom: 50px;">
							<div style="display: flex;align-items: center;">
								<!-- 在线商城 -->
								<div class="tit">{{ser.title}}</div>
								<div class="minText">海量书籍 学无止境</div>
							</div>
							<div class="gengduo" @click="gengdushangc">
								查看更多
								<img src="../../src/assets/xiaojian.png" alt="">
							</div>
						</div>
						<div style="display: flex;flex-wrap: wrap;">
							<div class="ReuseBoxsp" v-for="(item,index) in ser.list" :key="index"
								@click="routerXing(item.id)">
								<div>
									<img :src="item.image" alt="">
								</div>
								<div class="boxspBox">
									<div class="texts">{{item.title}}</div>
									<div class="lijigou">
										<div class="one"><span>￥</span>{{item.money}}</div>
										<div class="two">立即购买</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="ser.type == 3">
					<!-- 学习资料 -->
					<div class="ReuseBox">
						<div class="ReuseBox-header" style="margin-bottom: 50px;">
							<div style="display: flex;align-items: center;">
								<div class="tit">{{ser.title}}</div>
								<div class="minText">免费学习资料 一键下载</div>
							</div>
							<div class="gengduo" @click="routerXue">
								查看更多
								<img src="../../src/assets/xiaojian.png" alt="">
							</div>
						</div>
						<div style="display: flex;justify-content: space-between;">
							<div class="xiaokui" v-for="(dde,ssd) in ser.list" :key="ssd + 'ssdd'">
								<img src="../../src/assets/home/zhiliao.png" alt="" class="titbj" v-if="ssd == 0">
								<img src="../../src/assets/home/Information.png" alt="" class="titbj"
									v-else-if="ssd == 1">
								<img src="../../src/assets/home/zheng.png" alt="" class="titbj" v-else>
								<div class="title">{{dde.title}}</div>
								<div class="xiaBox" v-for="(items,xues) in dde.list" :key="xues +'ikj'"
									@click="xiazai(items.id)">
									<div>
										<i class="el-icon-caret-right"></i>
									</div>
									<div class="timu">
										{{items.title}}
									</div>
									<div class="hoverImg">
										<img src="../../src/assets/home/yuanxs.png" alt="" class="xiazai">
										<img src="../../src/assets/home/xiazi.png" alt="" class="xiazaiHovr">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="ser.type == 2">
					<!-- 热门直播 -->
					<div class="ReuseBox bnotteOne">
						<div class="ReuseBox-header" style="margin: 30px 0 50px 0;">
							<div style="display: flex;align-items: center;">
								<div class="tit">{{ser.title}}</div>
								<div class="minText">名师大咖 每日直播</div>
							</div>
							<div class="gengduo" @click="egngduzhibo">
								查看更多
								<img src="../../src/assets/xiaojian.png" alt="">
							</div>
						</div>
						<div style="display: flex;">
							<div class="zhibo" v-for="(item,index) in ser.list"
								:style="{'marginRight':index == 3?'0':'24px'}" :key="index"
								@click="deleiRousst(item.id)">
								<img :src="item.image" alt="">
								<div class="tit">{{item.title}}</div>
								<div class="text">直播时间：{{item.start_time}}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 精品课程 -->
				<div v-else>
					<div class="jiequImg">
						<img :src="ser.image" alt="" class="advertisement">
					</div>
					<div class="ReuseBox">
						<div class="ReuseBox-header">
							<div style="display: flex;align-items: center;">
								<div class="tit">精品课程</div>
								<div class="text" :style="{background: ser.color}">{{ser.title}}</div>
							</div>
							<div class="gengduo" @click="rouyerss()">
								查看更多
								<img src="../../src/assets/xiaojian.png" alt="">
							</div>
						</div>
						<div class="maxBox">
							<div class="minBox" v-for="(item,index) in ser.list" :key="index"
								@click="deleiRout(item.id)">
								<img :src="item.image" alt="">
								<div class="title">{{item.title}}</div>
								<div class="bottonBox">
									<div class="leftBox">
										<img src="../../src/assets/logo.png" alt="">
										{{item.view_text}}
									</div>
									<div class="botoness">
										<div class="vip" v-if="item.vip == 2">
											VIP
										</div>
										<div class="free" v-else>
											免费
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 连锁校区 -->
			<div class="lianshuox">
				<div class="headeLian">
					连锁校区
				</div>
				<div class="lianBox">
					<el-carousel type="card" height="440px" :autoplay='false'>
						<el-carousel-item v-for="(duns,indd) in xiaoQulis" :key="indd">
							<div class="lianshuoMse" @click="xuexiaorout(duns.id)">
								<div style="position: relative;">
									<img :src="duns.image" alt="" />
									<div class="hengdee">
										{{duns.title}}
										<img src="../../src/assets/home/yuanJian.png" alt="" class="dussss" />
										<img src="../../src/assets/home/lanjian.png" alt="" class="antsss" />
									</div>
									<div class="poserdddsa">
										<div>
											<div>地址：{{duns.address}}</div>
											<div>联系电话：{{duns.mobile}}</div>
										</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<!-- 师资力量 -->
			<div class="shizhiLi">
				<div class="shiziHead">
					<div class="tirss">师资团队</div>
					<div @click="laoshiLiRout" class="gengduos">
						查看更多
						<img src="../../src/assets/xiaojian.png" alt="">
					</div>
				</div>
				<div class="boxSSers" v-if="laosiList.length > 0">
					<div class="laoshiBox" id="laoshissa">
						<div class="laoshiBoox" v-for="item in laosiList" :key="item.id" @click="laoshi1Mok(item.id)">
							<img :src="item.image" alt="" />
							<!-- <div class="poserss">
								<div class="tits">{{item.title}}</div>
								<div class="text">{{item.Courseteachercate.title}}</div>
							</div> -->
						</div>
					</div>
					<div class="suspension">
						<div class="title">{{laosiList[laosValue].title}}</div>
						<div class="text">{{laosiList[laosValue].Courseteachercate.title}}</div>
						<div class="jiesao">{{laosiList[laosValue].text}}</div>
					</div>
					<div class="leftYan" @click="leYanse">
						<i class="el-icon-arrow-left"></i>
					</div>
					<div class="rightYan" @click="riYansh">
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</div>
			<div style="height: 40px;">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		yanzXia,
		myXiazai
	} from "../api/xueXi.js"
	import {
		homesPage,
		gerenzxing,
	} from "../api/user.js"
	import {
		xiaoQuList,
		laoshiKList
	} from "../api/xingMok.js"
	import {
		Loading
	} from 'element-ui';
	import axios from 'axios'
	export default {
		data() {
			return {
				laosValue: 0,
				datas: '',
				idss: '',
				huiyuanka: [],
				imgHeight: '',
				lianValue: 0,
				xiaoQulis: [],
				serDunse: 0,
				laosiList: [],
				itemFunce: '',
				falsePx: '',
				falsessPx: '',
			}
		},
		mounted() {
			if (this.$route.query.token) {
				this.$store.commit("user/setToken", this.$route.query.token)
				this.homeGerenxing()
			}
			this.getData()
			this.getXaiaoqu()
			this.laoshiList()
			setTimeout(() => {
				this.getlaoshiYshu()
				this.putSerrsssd()
			}, 1000)
		},
		methods: {
			// 左移动
			leYanse() {
				clearInterval(this.itemFunce)
				const childElement = document.getElementById('laoshissa');
				let ferst = this.falsePx
				if (this.laosValue == this.laosiList.length - 1) {
					this.laosValue = 0
				} else {
					this.laosValue += 1
				}
				for (let i = 0; i < childElement.children.length; i++) {
					childElement.children[i].style.transition = 'left 0.5s ease'
					if (childElement.children[i].style.left == '-108px') {
						childElement.children[i].style.left = ferst
						childElement.children[i].style.transition = 'none'
					} else if (childElement.children[i].style.left == '30px') {
						// 放大块变小
						childElement.children[i].style.width = '106px'
						childElement.children[i].style.height = '140px'
						childElement.children[i].style.left = '-108px'
					} else if (childElement.children[i].style.left == '276px') {
						// 第二块变大
						childElement.children[i].style.width = '212px'
						childElement.children[i].style.height = '285px'
						childElement.children[i].style.left = '30px'
					} else {
						let sdd = childElement.children[i].style.left.split('px')[0]
						childElement.children[i].style.left = sdd - 138 + 'px'
					}
				}
				this.putSerrsssd()
			},
			// 右移动
			riYansh() {
				clearInterval(this.itemFunce)
				const childElement = document.getElementById('laoshissa');
				let fersss = this.falsessPx
				if (this.laosValue == 0) {
					this.laosValue = this.laosiList.length - 1
				} else {
					this.laosValue -= 1
				}
				for (let i = 0; i < childElement.children.length; i++) {
					if (childElement.children[i].style.left == fersss) {
						childElement.children[i].style.left = '-108px'
						childElement.children[i].style.transition = 'none'
					}
				}
				setTimeout(() => {
					for (let i = 0; i < childElement.children.length; i++) {
						childElement.children[i].style.transition = 'left 0.5s ease'
						if (childElement.children[i].style.left == '-108px') {
							childElement.children[i].style.width = '212px'
							childElement.children[i].style.height = '285px'
							childElement.children[i].style.left = '30px'
						} else if (childElement.children[i].style.left == '30px') {
							childElement.children[i].style.width = '106px'
							childElement.children[i].style.height = '140px'
							childElement.children[i].style.left = '276px'
						} else {
							let sdd = childElement.children[i].style.left.split('px')[0]
							childElement.children[i].style.left = Number(sdd) + 138 + 'px'
						}
					}
				}, 1)
				this.putSerrsssd()
			},
			// 初始化定位
			getlaoshiYshu() {
				let serr = 30
				const childElement = document.getElementById('laoshissa');
				for (let i = 0; i < childElement.children.length; i++) {
					if (i == 0) {
						childElement.children[i].style.width = '212px'
						childElement.children[i].style.height = '285px'
						childElement.children[i].style.left = serr + 'px'
						serr += 246
					} else {
						childElement.children[i].style.left = serr + 'px'
						serr += 138
					}
				}
				this.falsePx = childElement.children[childElement.children.length - 2].style.left
				this.falsessPx = childElement.children[childElement.children.length - 1].style.left
			},
			// 轮播移动
			putSerrsssd() {
				const childElement = document.getElementById('laoshissa');
				let ferst = this.falsePx
				let that = this
				this.itemFunce = setInterval(() => {
					if (that.laosValue == that.laosiList.length - 1) {
						that.laosValue = 0
					} else {
						that.laosValue += 1
					}
					for (let i = 0; i < childElement.children.length; i++) {
						childElement.children[i].style.transition = 'left 0.5s ease'
						if (childElement.children[i].style.left == '-108px') {
							childElement.children[i].style.left = ferst
							childElement.children[i].style.transition = 'none'
						} else if (childElement.children[i].style.left == '30px') {
							// 放大块变小
							childElement.children[i].style.width = '106px'
							childElement.children[i].style.height = '140px'
							childElement.children[i].style.left = '-108px'
						} else if (childElement.children[i].style.left == '276px') {
							// 第二块变大
							childElement.children[i].style.width = '212px'
							childElement.children[i].style.height = '285px'
							childElement.children[i].style.left = '30px'
						} else {
							let sdd = childElement.children[i].style.left.split('px')[0]
							childElement.children[i].style.left = sdd - 138 + 'px'
						}
					}
				}, 5000)
			},
			// 老师列表跳
			laoshiLiRout() {
				this.$router.push('/laoshiPage')
			},
			// 老师详情
			laoshi1Mok(e) {
				this.$router.push({
					path: '/laosDeliss',
					query: {
						id: e
					}
				})
			},
			// 老师列表
			async laoshiList() {
				let data = await laoshiKList()
				if (data.code == 1) {
					this.laosiList = data.data.list
				}
			},
			// 学校详情
			xuexiaorout(e) {
				this.$router.push({
					path: '/xuexiaoPage',
					query: {
						id: e
					}
				})
			},
			// 校区 
			async getXaiaoqu() {
				let data = await xiaoQuList()
				if (data.code == 1) {
					this.xiaoQulis = data.data.list
					this.serDunse = Math.trunc(data.data.list.length / 3)
				}
			},
			// 获取个人信息
			async homeGerenxing() {
				let data = await gerenzxing()
				if (data.code == 1) {
					this.$store.commit('user/setIphens', data.data.user.mobile)
				}
			},
			routeeHs(e) {
				this.$router.push(e)
			},
			imgLoad() {
				// 获取图片高度
				this.$nextTick(() => {
					this.imgHeight = this.$refs.imgHeight['0'].height + 'px'
				})
			},
			gengdushangc() {
				this.$router.push('/zaiXidel')
			},
			egngduzhibo() {
				this.$router.push('/zhiBo')
			},
			gengduohuodng() {
				this.$router.push('/huoDon')
			},
			// 下载
			async xiazai(e) {
				this.idss = e
				if (this.$store.state.user.token) {
					let data = await yanzXia({
						id: this.idss
					})
					if (data.data == 1) {
						this.myXiazaiz()
					} else if (data.data == 2 && data.code == 1) {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 我的下载
			async myXiazaiz() {
				let data = await myXiazai({
					id: this.idss
				})
				if (data.code == 1) {
					let geshi = data.data.url.split('.')
					axios({
						method: 'get',
						// baseURL: 'http://localhost:8080/api/course.coursedetail/down_file',
						baseURL: 'https://shop.yhjtedu.com/api/course.coursedetail/down_file',
						params: {
							file: data.data.url,
							title: data.data.title
						},
						// 自定义请求头
						headers: {
							'token': this.$store.state.user.token
						},
						timeout: 4000, // 默认值是 `0` (永不超时)
						responseType: 'blob' // 默认值
					}).then((response) => {
						console.log(response)
						const blob = new Blob([response.data], {
							type: 'application/;charset=utf-8'
						})
						// 创建一个临时的a标签用于下载
						const a = document.createElement('a')
						a.href = URL.createObjectURL(blob)
						a.download = data.data.title + "." + geshi[geshi.length - 1]
						// // 将a标签添加到文档中，触发点击事件，然后移除a标签
						document.body.appendChild(a)
						a.click()
						document.body.removeChild(a)

					}).catch((error) => {
						console.log(error)
					})
					// window.location.assign(data.data.url);
				}
			},
			routerssee(e) {
				this.$router.push(e.url)
			},
			routersssws(e) {
				this.$router.push({
					path: '/huodXq',
					query: {
						id: e
					}
				})
			},
			routerXue() {
				this.$router.push('/xuexi')

			},
			// 更多跳转
			rouyerss() {
				this.$router.push('/zaixKe')
			},
			// 课程详情
			deleiRout(e) {
				this.$router.push({
					path: '/zaiXingq',
					query: {
						id: e
					}
				})
			},
			// 直播详情
			deleiRousst(e) {
				this.$router.push({
					path: '/zhiboXq',
					query: {
						id: e
					}
				})
			},
			// 商品详情
			routerXing(e) {
				this.$router.push({
					path: '/deloePage',
					query: {
						id: e
					}
				})
			},
			// 数据
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#MaxHomes')
				});
				let data = await homesPage()
				this.datas = data.data
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			router() {
				this.$router.push('/onLine')
			}
		}
	}
</script>

<style>
	.imgsser {
		width: 100%;
	}

	.el-carousel__button {
		width: 8px !important;
		height: 8px !important;
		border-radius: 50%;
	}

	.el-carousel__indicator.is-active button {
		width: 11px !important;
		height: 11px !important;
		border-radius: 50%;
	}
</style>
<style lang="scss" scoped>
	.poserdddsa {
		position: absolute;
		top: 338px;
		font-size: 16px;
		width: 600px;
		display: flex;
		justify-content: center;

		&>div {
			border-radius: 6px;
			padding: 4px 8px;
			background: rgba(217, 217, 217, 0.6);

		}
	}

	.suspension {
		position: absolute;
		top: 10px;
		left: 276px;
		width: 900px;

		.title {
			color: #333333;
			font-size: 24px;
			font-weight: bold;
		}

		.text {
			margin: 5px 0 5px 0;
			color: #999999;
			font-size: 18px;
		}

		.jiesao {
			color: #666666;
			font-size: 16px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}
	}

	@keyframes Animati {
		0% {
			transform: scale(0);
			opacity: 0.8;
		}

		50% {
			transform: scale(1);
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	.laoshiBoox {
		height: 140px;
		width: 106px;
		cursor: pointer;
		background: #FFFFFF;
		left: 1;
		position: absolute;
		// width: 170px;
		// margin-right: 20px;
		// padding: 8px 10px;
		// background: #FFFFFF;
		// text-align: center;
		// box-shadow: 0 0 2px #b6b6b6;
		bottom: 30px;
		transition: left 0.5s ease;

		&>img {
			border-radius: 8px;
			height: 100%;
			width: 100%;
		}

		.poserss {
			padding: 2px 0;
			top: 198px;
			width: 160px;
			left: 15px;
			border-radius: 6px;
			background: rgba(255, 255, 255, 0.5);
			position: absolute;
			text-align: center;

			.tits {
				font-size: 16px;
			}

			.text {
				margin: auto;
				width: 150px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 12px;
			}
		}
	}

	.shizhiLi {
		width: 1240px;
		margin: auto;

		.boxSSers:hover {
			.rightYan {
				display: block;
			}

			.leftYan {
				display: block;
			}
		}

		.boxSSers {
			overflow: hidden;
			position: relative;
			background: #FFFFFF;
			padding: 30px;
			border-radius: 12px;

			.leftYan:hover {
				background: rgba(31, 45, 61, 0.5);
			}

			.leftYan {
				top: 130px;
				cursor: pointer;
				display: none;
				z-index: 5;
				left: 0;
				text-align: center;
				position: absolute;
				border-radius: 50%;
				line-height: 40px;
				width: 40px;
				height: 40px;
				color: #FFF4F4;
				background: rgba(31, 45, 61, 0.23);
			}

			.rightYan:hover {
				background: rgba(31, 45, 61, 0.5);
			}

			.rightYan {
				cursor: pointer;
				display: none;
				z-index: 5;
				right: 0;
				top: 130px;
				text-align: center;
				line-height: 40px;
				position: absolute;
				border-radius: 50%;
				width: 40px;
				height: 40px;
				color: #FFF4F4;
				background: rgba(31, 45, 61, 0.23);
			}

			.laoshiBox {
				display: flex;
				width: 1240px;
				height: 258px;
			}
		}

		.shiziHead {
			padding: 30px 0 30px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.gengduos {
				zoom: 0.7;
				cursor: pointer;
				font-size: 16px;
				font-weight: 500;
				color: #999999;

				&>img {
					width: 21px;
					height: 21px;
					position: relative;
					top: 5px;
					margin-left: 2px;
				}
			}

			.tirss {
				font-size: 20px;
				font-weight: bold;
				color: #333333;

			}

		}
	}

	.lianshuoMse:hover {
		&>div {
			&>.hengdee {
				color: #1296db;

				.antsss {
					display: block;
				}

				.dussss {
					display: none;
				}
			}
		}
	}

	.lianshuoMse {
		display: flex;
		align-items: center;
		background: #FFFFFF;

		&>div {
			&>.hengdee {
				display: flex;
				align-items: center;
				justify-content: center;

				.antsss {
					display: none;
				}

				&>img {
					margin-left: 5px;
					width: 27px;
					height: 27px;
				}

				padding-top: 10px;
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}

			&>img {
				height: 400px;
				width: 620px;
			}
		}
	}

	.lianBox {
		width: 1200px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 20px;
	}

	.lianshuox {
		width: 1240px;
		margin: auto;

		.headeLian {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
			margin: 30px 0 30px 0;
		}
	}

	#MaxHomes {
		min-height: 80vh;
	}

	/deep/.el-icon-caret-right:before {
		font-size: 20px;
	}

	.xiaokui {
		zoom: 0.83;
		width: 490px;
		height: 540px;
		background: #FFFFFF;
		border-radius: 12px;
		position: relative;

		.titbjer {
			width: 490px;
			height: 88px;
			border-radius: 12px 12px 0px 0px;
			position: absolute;
			background: linear-gradient(0deg, #FFFFFF 0%, #FFF3DE 100%);
		}

		.titbj {
			width: 490px;
			height: 88px;
			background: linear-gradient(0deg, #FFFFFF 0%, #FFF4F4 100%);
			border-radius: 12px 12px 0px 0px;
			position: absolute;
		}

		.titbjss {
			width: 490px;
			height: 88px;
			background: linear-gradient(0deg, #FFFFFF 0%, #EEF7FF 100%);
			border-radius: 12px 12px 0px 0px;
			position: absolute;
		}

		.xiaBox:hover {
			.timu {
				color: #1296db;
			}

			.hoverImg {
				.xiazaiHovr {
					display: block;
				}

				.xiazai {
					display: none;
				}
			}
		}

		.xiaBox {
			display: flex;
			align-items: center;
			padding: 0 30px 0 26px;
			margin-bottom: 19px;
			height: 26px;
			cursor: pointer;

			.timu {
				font-size: 16px;
				font-weight: 500;
				color: #333333;
				flex: 1;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

			.xiazaiHovr {
				display: none;
				width: 19px;
				height: 19px;
				position: relative;
				top: 1px;
				margin-left: 5px;
			}

			.xiazai {
				width: 19px;
				height: 19px;
				position: relative;
				top: 3px;
				margin-left: 5px;
			}

		}

		.title {
			position: relative;
			font-size: 28px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			padding-top: 25px;
			padding-bottom: 32px;
			text-align: center;
		}
	}

	.hudong {
		display: flex;

		.huodngBox:hover {
			transform: translate(0, -10px);
		}

		.huodngBox {
			transition: all 0.3s;
			cursor: pointer;
			zoom: 0.8;
			width: 369px;
			height: 364px;
			background: #FFFFFF;
			border-radius: 12px;
			overflow: hidden;

			.buju {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 14px;
				padding: 0 20px;

				.botton {
					width: 60px;
					height: 32px;
					border: 1px solid #3B99FE;
					border-radius: 16px;
					text-align: center;
					line-height: 32px;
					font-size: 18px;
					font-weight: bold;
					color: #3B99FE;
				}

				.text {
					font-size: 16px;
					font-weight: 500;
					color: #999999;
				}
			}

			.item {
				width: 220px;
				height: 24px;
				background: #E9F4FF;
				border: 1px solid #AAD3FF;
				border-radius: 4px;
				font-size: 14px;
				font-weight: bold;
				color: #3B99FE;
				text-align: center;
				line-height: 25px;
				margin-left: 20px;
			}

			.titl {
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				margin: 16px 0 8px 0;
				padding: 0 20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

			&>img {
				width: 369px;
				height: 220px;
				vertical-align: middle;
			}
		}
	}

	.bnotteOne {
		margin-top: 30px;
	}

	.bnotteTwo {
		margin-top: 50px;
	}

	.zhibo:hover {
		transform: translate(0, -10px);

	}

	.zhibo {
		transition: all 0.3s;
		cursor: pointer;
		zoom: 0.8;
		width: 369px;
		height: 310px;
		background: #FFFFFF;
		border-radius: 12px;
		overflow: hidden;

		&>img {
			width: 369px;
			height: 220px;
			vertical-align: middle;
		}

		.tit {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			margin: 16px 0 13px 0;
			padding: 0 20px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}

		.text {
			font-size: 16px;
			font-weight: 500;
			color: #999999;
			padding: 0 20px;
		}
	}

	.jiequImg {
		position: relative;
		height: 80px;
		padding: 20px 0 30px 0;
		overflow: hidden;

		.advertisement {
			position: absolute;
			height: 80px;
			width: 1920px;
			top: 26px;
			left: calc(50% - 960px);
		}
	}


	.botoness {
		display: flex;
		align-items: center;

		.vip::after {
			z-index: 2;
			content: '';
			background: #FF9000;
			width: 100px;
			height: 100px;
			top: -34px;
			left: calc(50% - 50px);
			border-radius: 50%;
			position: absolute;
			-webkit-animation: Animati 1.5s ease 0s infinite;
			-moz-animation: Animati 1.5s ease 0s infinite;
			-o-animation: Animati 1.5s ease 0s infinite;
			animation: Animati 1.5s ease 0s infinite;
		}

		.vip {
			overflow: hidden;
			position: relative;
			width: 90px;
			height: 32px;
			border: 1px solid #FF9000;
			border-radius: 16px;
			font-size: 18px;
			font-weight: bold;
			color: #FF9000;
			text-align: center;
			line-height: 32px;


		}

		.free::after {
			z-index: 2;
			content: '';
			background: #3B99FE;
			width: 100px;
			height: 100px;
			top: -34px;
			left: calc(50% - 50px);
			border-radius: 50%;
			position: absolute;
			-webkit-animation: Animati 1.5s ease 0s infinite;
			-moz-animation: Animati 1.5s ease 0s infinite;
			-o-animation: Animati 1.5s ease 0s infinite;
			animation: Animati 1.5s ease 0s infinite;
		}

		.free {
			overflow: hidden;
			position: relative;
			width: 90px;
			height: 32px;
			border: 1px solid #3B99FE;
			border-radius: 16px;
			font-size: 18px;
			font-weight: bold;
			color: #3B99FE;
			text-align: center;
			line-height: 32px;
		}
	}

	.lijigou {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 60px;

		.two {
			width: 88px;
			height: 32px;
			background: linear-gradient(90deg, #CD1212 0%, #E65C5C 100%);
			border-radius: 16px;
			font-size: 18px;
			font-weight: bold;
			color: #FFFFFF;
			line-height: 32px;
			text-align: center;
		}

		.one {
			font-size: 26px;
			font-weight: bold;
			color: #CD1212;

			&>span {
				font-size: 18px;
			}

		}
	}

	.ReuseBox {
		width: 1240px;
		margin: auto;

		.ReuseBoxsp:nth-child(3n) {
			margin-right: 0;
		}

		.ReuseBoxsp:hover {
			transform: translate(0, -10px);
		}

		.ReuseBoxsp {
			transition: all 0.3s;
			cursor: pointer;
			zoom: 0.83;
			margin-right: 24px;
			width: 480px;
			height: 180px;
			background: #FFFFFF;
			border-radius: 12px;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.boxspBox {
				margin-left: 50px;

				.texts {
					font-size: 20px;
					font-weight: bold;
					color: #333333;
					width: 260px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}
			}

			img {
				width: 116px;
				height: 149px;
				margin-left: 30px;
			}
		}

		.maxBox {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.bottonBox {
				padding: 25px 20px 20px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.minBox:nth-child(4n) {
				margin-right: 0;
			}

			.minBox:hover {
				transform: translate(0, -10px);
			}

			.minBox {
				transition: all 0.3s;
				zoom: 0.79;
				margin-right: 32px;
				margin-bottom: 20px;
				width: 368px;
				background: #FFFFFF;
				border-radius: 12px;
				overflow: hidden;
				background-color: #FFFFFF;

				.leftBox {
					font-size: 16px;
					font-weight: 500;
					color: #333333;

					img {

						width: 21px;
						height: 21px;
						vertical-align: middle;
						position: relative;
						bottom: 2px;

					}
				}

				.title {
					margin-top: 5px;
					padding: 0 20px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-o-text-overflow: ellipsis;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
				}

				&>img {
					width: 368px;
					height: 270px;
				}
			}
		}

		.ReuseBox-header {
			zoom: 0.7;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 50px;

			.minText {
				font-size: 22px;
				font-weight: 500;
				color: #999999;
				position: relative;
				top: 4px;
				margin-left: 16px;
			}

			.gengduo {
				cursor: pointer;
				font-size: 16px;
				font-weight: 500;
				color: #999999;

				&>img {
					width: 21px;
					height: 21px;
					position: relative;
					top: 4px;
					margin-left: 2px;
				}
			}

			.tit {
				font-size: 28px;
				font-weight: bold;
				color: #333333;
				margin-right: 10px;
			}

			.text {
				margin-top: 3px;
				font-size: 20px;
				font-weight: bold;
				color: #FFFFFF;
				width: 110px;
				height: 30px;
				border-radius: 18px;
				text-align: center;
				line-height: 30px;
			}
		}
	}

	.homeIcons {
		// padding: 36px 200px 61px 200px;
		margin: auto;
		width: 1240px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.homeIconsBox:hover {
		transform: scale(1.1);
	}

	.homeIconsBox {
		transition: all 0.3s;
		zoom: 0.8;
		width: 368px;
		height: 112px;
		background: linear-gradient(0deg, #BACFF5 0%, #688CE4 99%);
		border-radius: 12px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		img {
			margin-left: 22px;
			width: 70px;
			height: 70px;
			vertical-align: middle;
		}

		.right {
			margin-left: 30px;

			.titlle {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
			}

			.text {
				font-size: 16px;
				color: #FFFFFF;
				margin-top: 5px;
			}
		}
	}
</style>