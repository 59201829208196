<template>
	<div>
		<div class="title">
			我的佣金
		</div>
		<div class="lineBox">
			<div class="leftOne">
				<div class="tiele">总佣金</div>
				<div class="price">
					<span>￥</span>{{tonghji.money_inc}}
				</div>
			</div>
			<div class="leftTwo">
				<div class="tiele">可提现</div>
				<div class="price">
					<span>￥</span>{{tonghji.money}}
				</div>
			</div>
			<div class="quTixian" @click="clickTixian">去提现</div>
		</div>
		<div class="liness">
			<div :style="{'background':values == 0?'#FFFFFF':'#F8F8F8'}" @click="clicker(0)">全部</div>
			<div :style="{'background':values == 1?'#FFFFFF':'#F8F8F8'}" @click="clicker(1)">佣金收入</div>
			<div :style="{'background':values == 2?'#FFFFFF':'#F8F8F8'}" @click="clicker(2)">佣金支出</div>
		</div>
		<div v-if="datalist.length >0">
			<div class="xiadanshi">
				<div class="ones">创建时间</div>
				<div class="twoa">标题</div>
				<div class="threes">下单人</div>
				<div>佣金</div>
			</div>
			<div>
				<div class="antse" v-for="(item,index) in datalist" :key="index">
					<div class="ones">{{item.create_time}}</div>
					<div class="twos">{{item.text}}</div>
					<div class="threes">{{item.User.nickname}}（ID：{{item.User.id}}）</div>
					<div class="fouers" v-if="item.type == 1">+￥{{item.sum}}</div>
					<div class="fouers" style="color: #b1b1b1;" v-else>-￥{{item.sum}}</div>
				</div>
			</div>
			<div class="paging">
				<el-pagination background layout="prev, pager, next" :page-size="10" :total="total"
					@current-change="pagese" @prev-click='pearDta' @next-click='mextDat'>
				</el-pagination>
			</div>
		</div>
		<div class="quesheng" v-else>
			<img src="../../../assets/quesheng.png" alt="" />
			<div>暂无内容~</div>
		</div>
		<el-dialog title="申请提现" :visible.sync="kaitonghui" :before-close="openGoumnai" custom-class="goumaiHui"
			:close-on-click-modal="false">
			<div class="popussse">
				<div class="antser">
					<input type="text" v-model="valuTixian" :placeholder="textssee" />
					<div @click="quanbuTixian">全部提现</div>
				</div>
				<div class="tixianz">提现账户</div>
				<div class="lineox">
					<div :class="{'clickde':vals == 1}" @click="clickVal(1)">微信</div>
					<div :class="{'clickde':vals == 2}" @click="clickVal(2)">支付宝</div>
					<div :class="{'clickde':vals == 3}" @click="clickVal(3)">银行卡</div>
				</div>
				<el-input v-model="inpute" placeholder="*请输入微信账户" v-if="vals == 1"></el-input>
				<el-input v-model="inputs" placeholder="*请输入支付宝账户" v-else-if="vals == 2"></el-input>
				<div v-else-if="vals == 3">
					<el-input v-model="kaNmae" placeholder="*请输入持卡人姓名"></el-input>
					<div class="tianc"></div>
					<el-input v-model="kaHao" placeholder="*请输入银行卡号"></el-input>
					<div class="tianc"></div>
					<el-select v-model="kaYinghan" placeholder="*请选择开户银行">
						<el-option v-for="item in options" :key="item.value" :label="item.text" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="tijiaos" @click="tijiaosheng">提交申请</div>
			</div>
		</el-dialog>
		<el-dialog title="申请成功" :visible.sync="applyfors" :before-close="applyAsse" custom-class="goumaiHui"
			:close-on-click-modal="false">
			<div v-if="sersssd">
				<div class="shengq">
					申请提现成功
				</div>
				<div class="yuyues">预计1-3个工作日内到账</div>
				<div class="tixianj">提现金额<span>￥{{sersssd.money}}</span></div>
				<div class="tixianzg">提现账户
					<span v-if="sersssd.type == 1">微信（{{sersssd.wx_acc}}）</span>
					<span v-else-if="sersssd.type == 2">支付宝（{{sersssd.zfb_acc}}）</span>
					<span v-else-if="sersssd.type == 3">银行卡（{{sersssd.code}}）</span>
				</div>
				<div class="wzhidao" @click="applyAsse">我知道了</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		myYongjing,
		qianbaoJlu,
		tixianShengqs,
		yingHanglist
	} from "../../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				kaNmae: '',
				kaHao: '',
				kaYinghan: '',
				inpute: '',
				inputs: "",
				textssee: '',
				applyfors: false,
				valuess: '',
				vals: 1,
				kaitonghui: false,
				values: 0,
				options: [],
				total: 0,
				page: 1,
				datalist: [],
				tonghji: '',
				valuTixian: '',
				sersssd: ''
			}
		},
		mounted() {
			this.getYongjing()
			this.tomghjiss()
			this.yinghangkas()
		},
		methods: {
			// 提现申请
			async tijiaosheng() {
				let sert = {
					money: this.valuTixian,
					type: this.vals,
				}
				if (this.vals == 1) {
					if (this.valuTixian == '' || this.inpute == '') {
						this.$message.error('请补充提现信息');
						return
					}
				} else if (this.vals == 2) {
					if (this.valuTixian == '' || this.inputs == '') {
						this.$message.error('请补充提现信息');
						return
					}
				} else if (this.vals == 3) {
					if (this.valuTixian == '' || this.kaNmae == '' || this.kaHao == '' || this.kaYinghan == '') {
						this.$message.error('请补充提现信息');
						return
					}
				}
				if (this.vals == 1) {
					sert.wx_acc = this.inpute
				} else if (this.vals == 2) {
					sert.zfb_acc = this.inputs
				} else if (this.vals == 3) {
					sert.name = this.kaNmae
					sert.code = this.kaHao
					sert.blank = this.kaYinghan
				}
				let data = await tixianShengqs(sert)
				if (data.code == 1) {
					this.sersssd = data.data
					this.openGoumnai()
					this.applyfors = true
				}
			},
			// 取消提现
			openGoumnai() {
				this.valuTixian = ''
				this.inpute = ''
				this.inputs = ''
				this.kaHao = ''
				this.kaYinghan = ''
				this.kaNmae = ''
				this.kaitonghui = false
			},
			// 获取银行卡
			async yinghangkas() {
				let data = await yingHanglist()
				if (data.code == 1) {
					this.options = data.data
				}
			},
			// 全部提现
			quanbuTixian() {
				this.valuTixian = this.tonghji.money
			},
			// 统计
			async tomghjiss() {
				let data = await qianbaoJlu()
				if (data.code == 1) {
					this.tonghji = data.data
				}
			},
			// 我的佣金
			async getYongjing() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let ser = {
					currency_type_id: 1,
					page: this.page,
					limit: 10
				}
				if (this.values != 0) {
					ser.type = this.values
				}
				let data = await myYongjing(ser)
				if (data.code == 1) {
					this.datalist = data.data.list
					this.total = data.data.total
				} else {
					this.datalist = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			applyAsse() {
				this.applyfors = false
			},
			clickVal(e) {
				this.inpute = ''
				this.inputs = ''
				this.kaHao = ''
				this.kaYinghan = ''
				this.kaNmae = ''
				this.vals = e
			},
			clickTixian() {
				this.textssee = `*请输提现金额（最高￥${this.tonghji.money}）`
				this.kaitonghui = true
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getYongjing()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getYongjing()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getYongjing()
			},
			clicker(e) {
				this.values = e
				this.getYongjing()
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .goumaiHui {
		width: 640px;
	}

	/deep/ .el-input__inner {
		height: 68px;
		width: 500px;
		font-size: 18px;
	}

	.wzhidao {
		margin: 42px auto 20px auto;
		width: 500px;
		height: 60px;
		background: #3B99FE;
		border-radius: 12px;
		font-size: 18px;
		text-align: center;
		line-height: 60px;
		color: #FFFFFF;
	}

	.tixianzg {
		font-size: 18px;
		color: #B2B2B2;
		margin-top: 26px;
		padding-left: 180px;

		&>span {
			margin-left: 50px;
			color: #333333;
		}
	}

	.tixianj {
		margin-top: 42px;
		font-size: 18px;
		color: #B2B2B2;
		padding-left: 180px;

		&>span {
			margin-left: 50px;
			color: #333333;
		}
	}

	.shengq {
		font-size: 18px;
		text-align: center;
		color: #333333;
	}

	.yuyues {
		font-size: 18px;
		text-align: center;
		color: #333333;
		margin-top: 10px;
	}

	.tijiaos {
		margin: 40px 0 20px 0;
		width: 500px;
		height: 60px;
		background: #3B99FE;
		border-radius: 12px;
		text-align: center;
		line-height: 60px;
		font-size: 18px;
		color: #FFFFFF;
	}

	.tianc {
		height: 10px;
	}

	.popussse {
		padding: 0 50px;
	}

	.lineox {
		margin-bottom: 20px;
		display: flex;

		.clickde {
			width: 80px;
			height: 40px;
			background: #3B99FE;
			border-radius: 8px;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
			line-height: 40px;
			margin-right: 10px;
		}

		&>div {
			width: 80px;
			height: 40px;
			border: 1px solid #E5E5E5;
			border-radius: 8px;
			text-align: center;
			line-height: 40px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #B2B2B2;
			margin-right: 10px;
		}
	}

	.tixianz {
		font-size: 20px;
		margin: 20px 0 14px 0;
		color: #333333;
	}

	.antser {
		margin: auto;
		width: 500px;
		height: 68px;
		border: 1px solid #E5E5E5;
		border-radius: 12px;
		display: flex;
		align-items: center;

		&>div {
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #3B99FE;
		}

		&>input {
			font-size: 18px;
			width: 365px;
			padding-left: 26px;
			border: none;
			outline: none;
		}
	}

	.paging {
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}

	.quesheng {
		text-align: center;
		padding: 200px 0 100px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.antse {
		display: flex;
		margin-top: 30px;

		.ones {
			text-align: center;
			width: 200px;
			font-size: 16px;
			color: #999999;
			margin-right: 60px;
		}

		.twos {
			text-align: center;
			width: 300px;
			font-size: 16px;
			color: #999999;
			margin-right: 20px;
		}

		.threes {
			text-align: center;
			width: 265px;
			font-size: 16px;
			color: #999999;
			margin-right: 210px;
		}

		.five {
			text-align: center;
			width: 120px;
			padding-left: 18px;
			font-size: 16px;
			color: #F5B53A;
		}

		.fouers {
			text-align: center;
			width: 120px;
			font-size: 16px;
			color: #F5B53A;
		}
	}

	.xiadanshi {
		display: flex;
		font-size: 16px;
		font-weight: bold;
		color: #333333;
		margin-top: 49px;

		.ones {
			margin: 0 262px 0 68px;
		}

		.twoa {
			margin-right: 252px;
		}

		.threes {
			margin-right: 372px;
		}
	}

	.liness {
		padding: 0 8px;
		margin-top: 40px;
		width: 1184px;
		height: 60px;
		background: #F8F8F8;
		border-radius: 12px;
		display: flex;
		align-items: center;

		&>div {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			width: 140px;
			height: 48px;
			border-radius: 8px;
			text-align: center;
			line-height: 48px;
		}
	}

	.lineBox {
		display: flex;
		align-items: center;
		margin-top: 30px;

		.quTixian {
			cursor: pointer;
			font-size: 18px;
			margin-right: 20px;
			text-decoration: underline;
			color: #3B99FE;
		}

		.leftTwo {
			flex: 1;

			.tiele {
				font-size: 18px;
				color: #B2B2B2;
			}

			.price {
				margin-top: 10px;
				font-size: 28px;
				color: #333333;

				&>span {
					font-size: 18px;
					font-weight: bold;
					color: #333333;
				}
			}
		}

		.leftOne {
			width: 200px;

			.tiele {
				font-size: 18px;
				color: #B2B2B2;
			}

			.price {
				margin-top: 10px;
				font-size: 28px;
				color: #3B99FE;

				&>span {
					font-size: 18px;
					font-weight: bold;
					color: #3B99FE;
				}
			}
		}
	}

	.title {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
	}
</style>