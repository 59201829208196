<template>
	<div class="maxin" id="Msjiuhdhu">
		<!-- 标签 -->
		<div class="tiprsHer">
			<div class="lines">
				<div class="left">一级分类：</div>
				<div class="right">
					<div v-for="(item,index) in oneDat" :key="index" :class="{'bottonsre':ones== item.id}"
						@click="clickOne(item.id)">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="lines">
				<div class="left">二级分类：</div>
				<div class="right">
					<div v-for="(item,index) in twoDat" :key="index" :class="{'bottonsre':twos== item.id}"
						@click="clickTwo(item.id)">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="biaoqian">
			<div class="title">下载列表</div>
			<div class="text">共找到<span style="color: #333333;">{{total}}</span>个结果</div>
		</div>
		<div class="arlicle" v-if="datalist.length >0">
			<div class="lines" v-for="(item,index) in datalist" :key="index" @click="xiazai(item)">
				<div class="left">
					{{item.title}}
				</div>
				<div class="right">
					<div class="wenjian">
						<img src="../../assets/wenjian.png" alt="">
						{{item.size}}
					</div>
					<div class="number">
						{{item.view_text}}
					</div>
					<div class="imgase">
						<img src="../../assets/home/xiazi.png" alt="" class="tupian">
						<img src="../../assets/home/yuanxs.png" alt="" class="tupians">
					</div>
				</div>
			</div>
		</div>
		<div v-else class="zhanwuShu">
			<img src="../../assets/quesheng.png" alt="" />
			<div>暂无数据</div>
		</div>
		<!-- 分页 -->
		<div class="paging" v-if="datalist.length >0">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="20" @current-change="pagese"
				@prev-click='pearDta' @next-click='mextDat'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		xueOnes,
		xueTwos,
		xueList,
		yanzXia,
		myXiazai
	} from "../../api/xueXi.js"
	import {
		Loading
	} from 'element-ui';
	import axios from 'axios'
	export default {
		data() {
			return {
				id: '',
				total: 0,
				checkList: [],
				ones: 0,
				twos: 0,
				datalist: [],
				oneDat: [],
				twoDat: [],
				page: 1,
				valueTit: ''
			}
		},
		mounted() {
			if (this.$route.query.title) {
				this.valueTit = this.$route.query.title
			}
			this.getData()
			this.getDaters()
			this.getLists()
		},
		watch: { // 监听,当路由发生变化的时候执行
			'$route'() {
				if (this.$route.query.title) {
					this.valueTit = this.$route.query.title
					this.getLists()
				}
			},
		},
		methods: {
			// 下载 
			async xiazai(e) {
				this.id = e.id
				if (this.$store.state.user.token) {
					let data = await yanzXia({
						id: this.id
					})
					if (data.data == 1) {
						this.myXiazaiz()
					} else if (data.data == 2 && data.code == 1) {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			// 我的下载
			async myXiazaiz() {
				let data = await myXiazai({
					id: this.id
				})
				if (data.code == 1) {
					let geshi = data.data.url.split('.')
					axios({
						method: 'get',
						// baseURL: 'http://localhost:8080/api/course.coursedetail/down_file',
						baseURL: 'https://shop.yhjtedu.com/api/course.coursedetail/down_file',
						params: {
							file: data.data.url,
							title: data.data.title
						},
						// 自定义请求头
						headers: {
							'token': this.$store.state.user.token
						},
						timeout: 4000, // 默认值是 `0` (永不超时)
						responseType: 'blob' // 默认值
					}).then((response) => {
						console.log(response)
						const blob = new Blob([response.data], {
							type: 'application/;charset=utf-8'
						})
						// 创建一个临时的a标签用于下载
						const a = document.createElement('a')
						a.href = URL.createObjectURL(blob)
						a.download = data.data.title + "." + geshi[geshi.length - 1]
						// // 将a标签添加到文档中，触发点击事件，然后移除a标签
						document.body.appendChild(a)
						a.click()
						document.body.removeChild(a)

					}).catch((error) => {
						console.log(error)
					})
				}
			},
			// 获取列表
			async getLists() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Msjiuhdhu')
				});
				let ser = {
					page: this.page,
					limit: 20,
					title: this.valueTit
				}
				if (this.ones) {
					ser.cid = this.ones
				} else if (this.twos) {
					ser.cid = this.twos
				}
				let data = await xueList(ser)
				if (data.code == 1) {
					this.datalist = data.data.list
					this.total = data.data.total
				} else {
					this.datalist = []
					this.total = 0
				}
				this.valueTit = ''
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 一级分类
			async getData() {
				let data = await xueOnes()
				this.oneDat = data.data.list
				this.oneDat.unshift({
					title: '全部',
					id: 0
				})

			},
			//二级分类
			async getDaters() {
				let data = await xueTwos()
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 获取二级分类
			async getDsherss() {
				let data = await xueOnes({
					pid: this.ones
				})
				if (data.code == 1) {
					this.twoDat = data.data.list
				} else {
					this.twoDat = []
				}
				this.twoDat.unshift({
					title: '全部',
					id: 0
				})
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getLists()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getLists()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getLists()
			},
			clickOne(e) {
				this.ones = e
				this.twos = ''
				if (this.ones == 0) {
					this.getDaters()
				} else {
					this.getDsherss()
				}
				this.getLists()
			},
			clickTwo(e) {
				this.twos = e
				this.ones = ''
				this.getLists()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.zhanwuShu {
		text-align: center;
		padding: 200px 0 220px 0;

		&>img {
			width: 200px;
			height: 100px;
		}

		&>div {
			margin-top: 10px;
			font-size: 14px;
			color: #B2B2B2;
		}
	}

	.tupian {
		display: none;
	}

	.arlicle {
		width: 100%px;
		padding: 30px 30px 16px 30px;
		background: #FFFFFF;
		border-radius: 12px;

		.lines:hover {

			&>.left {
				color: #1296db;
			}

			&>.right {
				.number {
					color: #1296db;
				}

				.wenjian {
					color: #1296db;
				}

				.imgase {
					.tupians {
						display: none;
					}

					.tupian {
						display: block;
					}
				}
			}
		}

		.lines {
			margin-bottom: 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;

			&>.right {
				zoom: 0.8;
				display: flex;
				align-items: center;

				.imgase {
					position: relative;

					&>.tupian {
						width: 19px;
						height: 19px;
						position: absolute;
						top: -10px;
					}

					&>.tupians {
						width: 19px;
						height: 19px;
						position: absolute;
						top: -10px;
					}
				}


				.number {
					font-size: 16px;
					color: #999999;
					width: 250px;
					text-align: center;
				}

				.wenjian {
					font-size: 16px;
					color: #999999;

					&>img {
						width: 16px;
						height: 16px;
						position: relative;
						top: 3px;
					}
				}
			}

			&>.left {
				font-size: 14px;
				color: #333333;
			}
		}
	}

	.paging {
		display: flex;
		justify-content: center;
		padding: 55px 0 20px 0;
		zoom: 0.76;
	}

	.biaoqian {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px 0 20px 0;

		.title {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}

		.text {
			font-size: 14px;
			color: #999999;
		}
	}

	.tiprsHer {
		width: 1180px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 20px 30px 10px 30px;

		.lines {
			display: flex;

			.right {
				font-size: 14px;
				color: #666666;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				&>div {
					font-size: 14px;
					margin-right: 22px;
					margin-bottom: 10px;
					padding: 0 5px;
					height: 36px;
					line-height: 36px;
				}

				.bottonsre {
					padding: 0 6px;
					height: 28px;
					background: #DEEEFF;
					border-radius: 4px;
					font-size: 14px;
					line-height: 28px;
					color: #3B99FE;
				}
			}

			.left {
				padding-top: 6px;
				min-width: 100px;
				font-size: 14px;
				color: #333333;
			}
		}
	}

	.maxin {
		padding-top: 30px;
		width: 1240px;
		margin: auto;
	}
</style>