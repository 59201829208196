<template>
	<div>
		<div class="header">
			<div @click="clickDat(index)" :class="{'tipsBox':index == valuer}" v-for="(item,index) in textData"
				:key="index">
				{{item}}
			</div>
		</div>
		<div class="linese">
			<div class="ones">课程</div>
			<div class="twos">报名时间</div>
			<div class="threes">课程类型</div>
		</div>
		<div v-if="dataliss.length>0">
			<div class="maxBNox" v-for="(item,index) in dataliss" :key="index">
				<div>
					<img :src="item.list.image" alt="" />
				</div>
				<div class='biaoqian'>{{item.title}}</div>
				<div class="itemse">{{item.create_time}}</div>
				<div class="leixing">{{item.list.vip == 1?'免费':'VIP'}}</div>
				<div class="lioanjie" @click="routers(item.list.id)">进入学习</div>
			</div>
		</div>
		<div class="quesheng" v-else>
			<img src="../../../assets/quesheng.png" alt="" />
			<div>暂无内容~</div>
		</div>
		<div class="paging" v-if="dataliss.length>0">
			<el-pagination background layout="prev, pager, next" :page-size="5" :total="total" @current-change="pagese"
				@prev-click='pearDta' @next-click='mextDat'>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		myKencheng
	} from "../../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				page: 1,
				total: 0,
				valuer: 0,
				textData: ['全部', '课程免费', 'VIP课程'],
				dataliss: []
			}
		},
		mounted() {
			this.getDatas()
		},
		methods: {
			// 获取列表
			async getDatas() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let sers = {
					page: this.page,
					limit: 5
				}
				if (this.valuer != 0) {
					sers.vip = this.valuer
				}
				let data = await myKencheng(sers)
				if (data.code == 1) {
					this.dataliss = data.data.list
					this.total = data.data.total
				} else {
					this.dataliss = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.getDatas()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.getDatas()
			},
			// 页数
			pagese(e) {
				this.page = e
				this.getDatas()
			},
			clickDat(e) {
				this.valuer = e
				this.getDatas()
			},
			// 跳转详情
			routers(e) {
				this.$router.push({
					path: '/zaiXingq',
					query: {
						id: e
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.paging {
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}

	.quesheng {
		text-align: center;
		padding: 200px 0 240px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.maxBNox {
		display: flex;
		align-items: center;
		margin-top: 38px;

		img {
			width: 150px;
			height: 90px;
		}

		.lioanjie {
			cursor: pointer;
			font-size: 16px;
			margin-left: 250px;
			font-weight: bold;
			text-decoration: underline;
			color: #3B99FE;
		}

		.leixing {
			width: 218px;
			font-size: 16px;
			text-align: center;
			color: #333333;
		}

		.itemse {
			text-align: center;
			width: 220px;
			font-size: 16px;
			color: #333333;
		}

		.biaoqian {
			margin: 0 40px 0 10px;
			font-size: 20px;
			width: 210px;
			font-weight: bold;
			color: #333333;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}

	.ones {
		margin: 0 409px 0 50px;
	}

	.twos {
		margin-right: 152px;
	}

	.linese {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #E5E5E5;
		padding: 46px 0 26px 0;
		font-size: 16px;
		color: #999999;
	}

	.header {
		width: 1200px;
		height: 60px;
		background: #F8F8F8;
		border-radius: 12px;
		display: flex;
		justify-content: center;

		&>div {
			cursor: pointer;
			padding-top: 15px;
			width: 90px;
			font-size: 20px;
			color: #333333;
			margin-right: 50px;
			text-align: center;
		}

		.tipsBox {
			position: relative;
		}

		.tipsBox::before {
			position: absolute;
			content: '';
			width: 65px;
			height: 2px;
			background: #3B99FE;
			bottom: 0;
			left: 12px;
		}
	}
</style>