<template>
	<div class="mainx" id="Msehdhd">
		<div class="haeder">
			<div>
				<img :src="datase.image" alt="">
			</div>
			<div class="right">
				<div class="laist">
					{{datase.title}}
				</div>
				<div class="items">直播时间：{{datase.create_time}}~{{datase.start_time}}</div>
				<div class="fotBotn" v-if="!datase.error && datase.url">
					<div class="yuan"></div>
					<div class="tetx">直播链接</div>
					<div class="lianji" @click="tiaozhuan(datase.url)">{{datase.url}}</div>
				</div>
			</div>
		</div>
		<article class="maxbox">
			<div class="left">
				<div style="font-size: 20px;font-weight: bold;">直播介绍</div>
				<div v-html="datase.content"></div>
			</div>
			<div class="right">
				<div class="tuijian">
					推荐课程
				</div>
				<div class="minBox" v-for="(item,index) in datalist" :key="index" :class="{'margings': index == 2}"
					@click="routers(item.id)">
					<img :src="item.image" alt="">
					<div class="titllr">{{item.title}}</div>
					<div class="fotters">
						<div class="left"><img src="../../assets/logo.png" alt="">{{item.view_text}}</div>
						<div class="bott" v-if="item.vip == 2">免费</div>
						<div class="botts" v-else>VIP</div>
					</div>
				</div>
			</div>
		</article>
	</div>
</template>

<script>
	import {
		zhiboDelis,
		zhiboYanzen,
		zhiboChakan
	} from "../../api/zhiBo.js"
	import {
		keChenList,
	} from "../../api/keChen.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				id: '',
				datase: '',
				datalist: []
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.getData()
			this.getLists()
		},
		methods: {
			// 查看直播
			async chakanZhi() {
				let data = await zhiboChakan({
					id: this.id
				})
				console.log(data)
			},
			// 获取详情
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Msehdhd')
				});
				let data = await zhiboDelis({
					id: this.id
				})
				if (data.code == 1) {
					this.datase = data.data
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			routers(e) {
				this.$router.push({
					path: '/zaiXingq',
					query: {
						id: e
					}
				})
			},
			// 获取列表
			async getLists() {
				let ser = {
					limit: 3,
					is_hot: 1
				}
				let data = await keChenList(ser)
				this.datalist = data.data.list
			},
			async tiaozhuan(e) {
				let path = e
				if (this.$store.state.user.token) {
					let data = await zhiboYanzen({
						id: this.id
					})
					if (data.data == 1) {
						this.chakanZhi()
						setTimeout(() => {
							window.location.href = path
						}, 1000)
					} else if (data.data == 2 && data.code == 1) {
						this.$store.commit('statess/setPopushui', true)
					}
				} else {
					this.$store.commit('statess/setStates', true)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.minBox {
		zoom: 0.8;
		padding-bottom: 30px;
		border-bottom: 1px solid #E5E5E5;
		margin-bottom: 30px;

		&>img {
			width: 350px;
			height: 210px;
			border-radius: 12px;
		}

		.fotters {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;

			.bott {
				width: 60px;
				height: 32px;
				border: 1px solid #3B99FE;
				border-radius: 16px;
				font-size: 18px;
				text-align: center;
				line-height: 32px;
				font-weight: bold;
				color: #3B99FE;
			}

			.botts {
				width: 60px;
				height: 32px;
				border: 1px solid #FF9000;
				border-radius: 16px;
				font-size: 18px;
				font-weight: bold;
				color: #FF9000;
				text-align: center;
				line-height: 32px;
			}

			&>.left {
				font-size: 16px;
				color: #333333;

				&>img {
					width: 21px;
					height: 21px;
					margin-right: 5px;
					vertical-align: middle;
					position: relative;
					bottom: 2px;
				}
			}
		}

		.titllr {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			width: 350px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}

	.maxbox {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	
		&>.left {
			position: relative;
			width: 800px;
			padding: 30px;
			background: #FFFFFF;
			border-radius: 12px;
		}
	
		&>.right {
			padding: 0 30px;
			width: 291px;
			// height: 1150px;
			background: #FFFFFF;
			border-radius: 12px;
	
			.tuijian {
				margin-bottom: 30px;
				padding: 30px 0 0 0;
				font-size: 20px;
				font-weight: bold;
				color: #333333;
			}
		}
	}

	.margings {
		border-bottom: 0;
	}

	.haeder {
		padding: 30px;
		background: #FFFFFF;
		border-radius: 12px;
		margin: 30px 0;
		display: flex;

		.right {
			margin-left: 40px;
			flex: 1;

			.fotBotn {
				zoom: 0.7;
				margin-top: 90px;
				width: 850px;
				height: 70px;
				background: #F8F8F8;
				border-radius: 12px;
				display: flex;
				align-items: center;

				.lianji {
					cursor: pointer;
					margin-left: 70px;
					font-size: 16px;
					text-decoration: underline;
					color: #FF7E00;
				}

				.tetx {
					font-size: 20px;
					font-weight: bold;
					color: #333333;
				}

				.yuan {
					min-width: 6px;
					height: 6px;
					background: #3B99FE;
					border-radius: 50%;
					margin: 0 12px 0 32px;
				}
			}

			.items {
				font-size: 14px;
				color: #3B99FE;
			}

			.laist {
				font-size: 16px;
				font-weight: bold;
				color: #333333;
				margin-bottom: 22px;
			}
		}

		&>div>img {
			width: 540px;
			height: 320px;
			border-radius: 12px;
			zoom: 0.7;
		}
	}

	.mainx {
		width: 1240px;
		margin: auto;
	}
</style>