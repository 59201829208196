<template>
	<div>
		<div v-show="state">
			<div class="titles">
				我的团队
			</div>
			<div class="lineser">
				<div>
					<div class="ones">总人数</div>
					<div class="twos">{{taudnuisse.teamcount_count}}</div>
				</div>
				<div class="center">
					<div class="ones">一级团队</div>
					<div class="twos">{{taudnuisse.team_count_z}}</div>
				</div>
				<div>
					<div class="ones">二级团队</div>
					<div class="twos">{{taudnuisse.team_count_j}}</div>
				</div>
			</div>
			<div class="tipser">
				<div class="lefts">
					<div :class="{'bjyanse':valus == 0}" @click="clickIn(0)">全部</div>
					<div :class="{'bjyanse':valus == 1}" @click="clickIn(1)">直推团队</div>
					<div :class="{'bjyanse':valus == 2}" @click="clickIn(2)">间推团队</div>
				</div>
				<div class="rights">
					<img src="../../../assets/sjpuss.png" alt="" />
					<input type="text" placeholder="请输入成员姓名/ID号" v-model="tetxeesw" />
				</div>
				<div class="shoushu" @click="shoushuoser">搜索</div>
			</div>
			<div class="linesser">
				<div class="ones">成员姓名</div>
				<div class="twos">ID</div>
				<div class="threes">等级</div>
				<div class="fours">加入时间</div>
				<div class="fivrs">
					订单总量
				</div>
				<div>成员订单</div>
			</div>
			<div v-if="dataList.length >0">
				<div class="minLine" v-for="(item,index) in dataList" :key="index">
					<div class="ons">{{item.nickname}}</div>
					<div class="tws">{{item.id}}</div>
					<div class="thrs">{{item.group_title}}</div>
					<div class="fos">{{item.create_time}}</div>
					<div class="fis">
						{{item.order_count_all}}
					</div>
					<div class="chakan" @click="clickCka(item)">查看</div>
				</div>
				<div class="paging">
					<el-pagination background layout="prev, pager, next" :page-size="10" :total="total"
						@current-change="pagese" @prev-click='pearDta' @next-click='mextDat'>
					</el-pagination>
				</div>
			</div>
			<div class="quesheng" v-else>
				<img src="../../../assets/quesheng.png" alt="" />
				<div>暂无内容~</div>
			</div>
		</div>
		<DeliPage @niganma='liangnianban' ref="deunsserdd" v-show="!state" />
	</div>
</template>

<script>
	import DeliPage from "./deliPage.vue"
	import {
		myTuanduis,
		qianbaoJlu
	} from "../../../api/infos.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		components: {
			DeliPage
		},
		data() {
			return {
				state: true,
				total: 100,
				valus: 0,
				dataList: [],
				page: 1,
				tetxeesw: '',
				taudnuisse: ''
			}
		},
		mounted() {
			this.myTuand()
			this.tuandutong()
		},
		methods: {
			// 我的团队
			async myTuand() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#jiazaizhong')
				});
				let set = {
					page: this.page,
					limit: 10,
					title: this.tetxeesw
				}
				if (this.valus != 0) {
					set.type = this.valus
				}
				let data = await myTuanduis(set)
				if (data.code == 1) {
					this.dataList = data.data.list
					this.total = data.data.total
				} else {
					this.dataList = []
					this.total = 0
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			async tuandutong() {
				let data = await qianbaoJlu()
				if (data.code == 1) {
					this.taudnuisse = data.data
				}
			},
			shoushuoser() {
				this.page = 1
				this.myTuand()
			},
			liangnianban() {
				this.state = true
			},
			clickCka(e) {
				this.state = false
				this.$refs.deunsserdd.datae = e
			},
			// 上一页
			pearDta(e) {
				this.page = e
				this.myTuand()
			},
			// 下一页
			mextDat(e) {
				this.page = e
				this.myTuand()
			},
			// 跳页
			pagese(e) {
				this.page = e
				this.myTuand()
			},
			clickIn(e) {
				this.valus = e
				this.myTuand()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.quesheng {
		text-align: center;
		padding: 200px 0 240px 0;

		&>div {
			margin-top: 38px;
			font-size: 16px;
			color: #B2B2B2;
		}

		&>img {
			width: 256px;
			height: 150px;
		}
	}

	.paging {
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}

	.minLine {
		margin-top: 28px;
		display: flex;
		font-size: 16px;
		color: #999999;

		.chakan {
			color: #3B99FE;
		}

		.fis {
			text-align: center;
			width: 136px;
			margin-right: 208px;
		}

		.fos {
			text-align: center;
			width: 230px;
			margin-right: 20px;
		}

		.thrs {
			width: 190px;
			text-align: center;
		}

		.tws {
			text-align: center;
			width: 154px;
		}

		.ons {
			text-align: center;
			width: 100px;
			margin-right: 60px;
		}
	}

	.linesser {
		margin-top: 40px;
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		color: #333333;

		.ones {
			margin: 0 139px 0 24px;
		}

		.twos {
			margin-right: 151px;
		}

		.threes {
			margin-right: 157px;
		}

		.fours {
			margin-right: 142px;
		}

		.fivrs {
			margin-right: 230px;
		}
	}

	.tipser {
		display: flex;
		align-items: center;
		margin-top: 30px;
		justify-content: space-between;

		.shoushu {
			width: 120px;
			height: 60px;
			background: #3B99FE;
			border-radius: 12px;
			font-size: 18px;
			font-weight: bold;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
		}

		.rights {
			width: 500px;
			height: 60px;
			background: #F8F8F8;
			border-radius: 12px;
			display: flex;
			align-items: center;

			&>input {
				font-size: 18px;
				outline: none;
				border: none;
				background-color: #F8F8F8;
			}

			&>img {
				width: 28px;
				height: 28px;
				margin: 0 10px 0 19px;
			}
		}

		.lefts {
			padding: 0 8px;
			width: 544px;
			height: 60px;
			background: #F8F8F8;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.bjyanse {
				background: #ffffff;
			}

			&>div {
				width: 180px;
				height: 48px;
				border-radius: 8px;
				text-align: center;
				line-height: 48px;
				font-size: 18px;
				font-weight: bold;
				color: #333333;
			}
		}
	}

	.lineser {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;

		&>div {
			text-align: center;
		}

		.center {
			margin: 0 120px;
		}

		.ones {
			font-size: 18px;
			color: #B2B2B2;
		}

		.twos {
			margin-top: 10px;
			font-size: 28px;
			font-weight: bold;
			color: #333333;
		}
	}

	.titles {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
	}
</style>