<template>
	<div id="Mashsexz">
		<div class="mainx" v-if="dataList">
			<el-carousel :interval="5000" trigger="click" class="Carousel" :height="imgHeight">
				<el-carousel-item v-for="(item,index) in dataList.banner" :key="index">
					<img :src="item.image" alt="" class="imgsser" @click="routeeHs(item.url)" ref="imgHeight"
						@load="imgLoad">
				</el-carousel-item>
			</el-carousel>
			<article>
				<div class="header">
					<div class="left">
						<img src="../../assets/maishu.png" alt="">
					</div>
					<div class="right">
						<el-carousel indicator-position="outside" :autoplay='false' class="huadong">
							<el-carousel-item v-for="(item,index) in miaosha" :key="index+'qqw'">
								<div class="Limited">
									<div class="minhe" v-for="(ite,ind) in item" :key="ind+'we'"
										@click="routerMiao(ite.id)">
										<img :src="ite.Goods.image" alt="">
										<div class="title">{{ite.title}}</div>
										<div class="fotBotton">
											<span class="one">￥</span>
											<span class="two">{{ite.money}}</span>
											<span class="three">活动价</span>
										</div>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div class="zhuansb" v-if="dataList.list.length > 0">
					{{dataList.list[0].title}}
				</div>
				<div class="zhuanbTe">
					<div>教材+真题试卷+宝典 轻松提升学历</div>
					<div class="right" @click="routers(1)">查看更多 <img src="../../assets/xiaojian.png" alt=""></div>
				</div>
				<div class="fuyoBox">
					<div class="box" v-for="(item,index) in dataList.list[0].list" :key="index"
						@click="routerXing(item.id)">
						<img :src="item.image" alt="">
						<div class="title">{{item.title}}</div>
						<div class="fotter">
							<div class="left">
								<span>￥</span>{{item.money}}
							</div>
							<div class="right">已售{{item.sale}}</div>
						</div>
					</div>
				</div>
				<div class="zhuansb" v-if="dataList.list.length > 1">
					{{dataList.list[1].title}}
				</div>
				<div class="zhuanbTe">
					<div>紧扣考试大纲，精选考点重点，能力强化训练</div>
					<div class="right" @click="routers(2)">查看更多 <img src="../../assets/xiaojian.png" alt=""></div>
				</div>
				<div class="fuyoBox">
					<div class="box" v-for="(item,index) in dataList.list[1].list" :key="index"
						@click="routerXing(item.id)">
						<img :src="item.image" alt="">
						<div class="title">{{item.title}}</div>
						<div class="fotter">
							<div class="left">
								<span>￥</span>{{item.money}}
							</div>
							<div class="right">已售{{item.sale}}</div>
						</div>
					</div>
				</div>
				<div class="zhuansb" v-if="dataList.list.length > 2">
					{{dataList.list[2].title}}
				</div>
				<div class="zhuanbTe" v-if="dataList.list.length > 2">
					<div>全景式考情呈现，课堂式备考体验</div>
					<div class="right" @click="routers(3)">查看更多 <img src="../../assets/xiaojian.png" alt=""></div>
				</div>
				<div class="fuyoBox" v-if="dataList.list.length > 2">
					<div class="box" v-for="(item,index) in dataList.list[2].list" :key="index"
						@click="routerXing(item.id)">
						<img :src="item.image" alt="">
						<div class="title">{{item.title}}</div>
						<div class="fotter">
							<div class="left">
								<span>￥</span>{{item.money}}
							</div>
							<div class="right">已售{{item.sale}}</div>
						</div>
					</div>
				</div>
				<div class="zhuansb" v-if="dataList.list.length > 3">
					{{dataList.list[3].title}}
				</div>
				<div class="zhuanbTe" v-if="dataList.list.length > 3">
					<div>强化训练，巩固知识，加深记忆</div>
					<div class="right" @click="routers(4)">查看更多 <img src="../../assets/xiaojian.png" alt=""></div>
				</div>
				<div class="fuyoBox" v-if="dataList.list.length > 3">
					<div class="box" v-for="(item,index) in dataList.list[3].list" :key="index"
						@click="routerXing(item.id)">
						<img :src="item.image" alt="">
						<div class="title">{{item.title}}</div>
						<div class="fotter">
							<div class="left">
								<span>￥</span>{{item.money}}
							</div>
							<div class="right">已售{{item.sale}}</div>
						</div>
					</div>
				</div>
			</article>
			<div class="jiequImg" v-if="dataList.list.length > 4">
				<img src="../../assets/home/onewsss.jpg" alt="" class="advertisement">
			</div>
			<footer v-if="dataList.list.length > 4">
				<div class="superBox" v-if="dataList.list.length > 4">
					<div class="title" @click="clickChi(1)">
						<div>
							<div class="oens">{{dataList.list[4].title}}</div>
							<div class="twos">强化训练，巩固知识，加深记忆</div>
						</div>
						<img src="../../assets/dajian.png" alt="">
					</div>
					<div class="witerBox">
						<div class="box" v-for="(item,index) in dataList.list[4].list" :key="index"
							@click="routerXing(item.id)">
							<div>
								<img :src="item.image" alt="">
							</div>
							<div class="right">
								<div class="titles">{{item.title}}</div>
								<div class="fotter">
									<div class="text">
										<span>￥</span>
										{{item.money}}
									</div>
									<div class="botton" @click.stop='clickBotton'>立即购买</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="superBox twocol" v-if="dataList.list.length > 5">
					<div class="title" @click="clickChi(2)">
						<div>
							<div class="oens">{{dataList.list[5].title}}</div>
							<div class="twos">强化训练，巩固知识，加深记忆</div>
						</div>
						<img src="../../assets/dajian.png" alt="">
					</div>
					<div class="witerBox">
						<div class="box" v-for="(item,index) in dataList.list[5].list" :key="index"
							@click="routerXing(item.id)">
							<div>
								<img :src="item.image" alt="">
							</div>
							<div class="right">
								<div class="titles">{{item.title}}</div>
								<div class="fotter">
									<div class="text">
										<span>￥</span>
										{{item.money}}
									</div>
									<div class="botton" @click.stop='clickBotton'>立即购买</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="superBox threecol" v-if="dataList.list.length > 6">
					<div class="title" @click="clickChi(3)">
						<div>
							<div class="oens">{{dataList.list[6].title}}</div>
							<div class="twos">强化训练，巩固知识，加深记忆</div>
						</div>
						<img src="../../assets/dajian.png" alt="">
					</div>
					<div class="witerBox">
						<div class="box" v-for="(item,index) in dataList.list[6].list" :key="index"
							@click="routerXing(item.id)">
							<div>
								<img :src="item.image" alt="">
							</div>
							<div class="right">
								<div class="titles">{{item.title}}</div>
								<div class="fotter">
									<div class="text">
										<span>￥</span>
										{{item.money}}
									</div>
									<div class="botton" @click.stop='clickBotton'>立即购买</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
		<div style="height: 30px;"></div>
	</div>
</template>

<script>
	import {
		shoppHome
	} from "../../api/shoppIng.js"
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				dataList: '',
				miaosha: [],
				valueTit: '',
				imgHeight: ''
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			imgLoad() {
				// 获取图片高度
				this.$nextTick(() => {
					this.imgHeight = this.$refs.imgHeight['0'].height + 'px'
				})
			},
			routeeHs(e) {
				this.$router.push(e)
			},
			async getData() {
				let loadingInstance = Loading.service({
					text: '加载中',
					lock: true,
					target: document.querySelector('#Mashsexz')
				});
				let data = await shoppHome()
				if (data.code == 1) {
					this.dataList = data.data
					let set = 0
					let arry = []
					let that = this
					data.data.ms.forEach((item) => {
						if (set == 3) {
							arry.push(item)
							that.miaosha.push(arry)
							arry = []
							set = 0
						} else {
							set = set + 1
							arry.push(item)
						}
					})
					that.miaosha.push(arry)
				}
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},
			clickBotton() {
				console.log('nidhiuhdu')
			},
			clickChi(e) {
				this.$router.push('/zaiXidel')
				console.log(e)
			},
			routers(e) {
				this.$router.push('/zaiXidel')
				console.log(e)
			},
			routerMiao(e) {
				if (this.$store.state.user.token) {
					this.$router.push({
						path: '/deloePage',
						query: {
							id: e,
							state: true
						}
					})
				} else {
					this.$store.commit('statess/setStates', true)
				}
			},
			routerXing(e) {
				this.$router.push({
					path: '/deloePage',
					query: {
						id: e
					}
				})
			},
			clickHuad(e) {
				console.log(e, 'pjpihjpiji')
			}
		}
	}
</script>

<style lang="scss" scoped>
	#Mashsexz {
		min-height: 70vh;
	}

	.jiequImg {
		position: relative;
		height: 80px;
		padding: 20px 0 30px 0;
		overflow: hidden;

		.advertisement {
			position: absolute;
			height: 80px;
			width: 1920px;
			top: 26px;
			left: calc(50% - 960px);
		}
	}

	.witerBox {
		width: 390px;
		height: 540px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-top: 22px;
		padding: 20px;

		.box {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.right {
				margin-left: 16px;

				.fotter {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 32px;

					.text {
						font-size: 26px;
						font-weight: bold;
						color: #CD1212;

						&>span {
							font-size: 18px;
							font-weight: bold;
							color: #CD1212;
						}
					}

					.botton {
						width: 88px;
						height: 32px;
						background: linear-gradient(90deg, #CD1212 0%, #E65C5C 100%);
						border-radius: 16px;
						font-size: 18px;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
					}

				}

				.titles {
					height: 50px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}
			}

			img {
				width: 120px;
				height: 120px;
				vertical-align: middle;
			}
		}
	}

	footer {
		margin: 40px auto;
		width: 1240px;
		display: flex;
		justify-content: space-between;

		.superBox {
			zoom: 0.82;
			width: 430px;
			background: linear-gradient(0deg, #FFE4CE 0%, #FFC99D 100%);
			border-radius: 12px;
			padding: 24px 30px 24px 30px;

			&>.title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.oens {
					font-size: 36px;
					font-weight: bold;
					color: #FFFFFF;
				}

				.twos {
					font-size: 18px;
					color: #FFFFFF;
					margin-top: 10px;
				}

				&>img {
					width: 34px;
					height: 32px;
				}
			}
		}

		.twocol {
			background: linear-gradient(0deg, #D4E9FF 0%, #95C9FF 100%);
		}

		.threecol {
			background: linear-gradient(0deg, #FFDED7 0%, #FF8E77 100%);
		}
	}

	.hengfu {
		width: 100%;
		margin-top: 40px;
	}

	.fuyoBox {
		display: flex;

		.fotter {
			display: flex;
			justify-content: space-between;
			padding: 16px 20px 0 20px;
			align-items: center;

			.right {
				font-size: 16px;
				color: #B2B2B2;
			}

			.left {
				font-size: 28px;
				color: #CD1212;

				&>span {
					font-size: 18px;
					font-weight: bold;
				}
			}
		}

		.box:hover {
			transform: scale(1.06);
		}

		.box {
			transition: all 0.3s;
			zoom: 0.82;
			margin-right: 17px;
			width: 290px;
			height: 420px;
			background: #FFFFFF;
			border-radius: 12px;
			overflow: hidden;

			.title {
				height: 44px;
				padding: 0 20px;
				font-size: 16px;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				margin-top: 10px;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			&>img {
				width: 290px;
				height: 290px;
			}
		}
	}

	.zhuansb {
		text-align: center;
		font-size: 20px;
		margin: 50px 0 16px 0;
		font-weight: bold;
		color: #333333;
	}

	.zhuanbTe {
		font-size: 16px;
		margin-bottom: 38px;
		color: #666666;
		display: flex;
		justify-content: center;
		position: relative;

		&>.right {
			zoom: 0.7;
			position: absolute;
			right: 0;
			display: flex;
			align-items: center;

			&>img {
				vertical-align: middle;
				width: 19px;
				height: 19px;
				position: relative;
				top: 1px;
				margin-left: 8px;
			}
		}
	}


	/deep/.imgsser {
		width: 100%;
	}

	.huadong {
		height: 400px;
	}

	/deep/.huadong>.el-carousel__container {
		height: 400px !important;
	}

	/deep/.huadong>.el-carousel__indicators--outside {
		display: none;
	}

	/deep/.huadong>.el-carousel__container>.el-carousel__arrow {
		background: RGBA(178, 178, 178, 1) !important;
		width: 24px;
		height: 50px;
		border-radius: 0;
	}

	/deep/.huadong>.el-carousel__container>.el-carousel__arrow--right {
		top: 140px;
		right: 0;
	}

	/deep/.huadong>.el-carousel__container>.el-carousel__arrow--left {
		left: 0;
		top: 140px;
	}

	/deep/.huadong>.el-carousel__container>.el-carousel__arrow--right>.el-icon-arrow-right:before {
		font-size: 20px;
	}

	/deep/.huadong>.el-carousel__container>.el-carousel__arrow--left>.el-icon-arrow-left:before {
		font-size: 20px;
	}

	.Limited {
		display: flex;

		.minhe:hover {
			transform: scale(1.06);
		}

		.minhe {
			transition: all 0.3s;
			width: 278px;
			background: #FFFFFF;
			padding-bottom: 14px;
			border-radius: 12px;
			margin-right: 18px;

			.fotBotton {
				width: 240px;
				height: 36px;
				background: linear-gradient(0deg, #E63F16 0%, #F5661B 100%);
				border-radius: 8px;
				margin: auto;
				display: flex;
				align-items: center;

				.one {
					font-size: 18px;
					font-weight: bold;
					color: #FFFFFF;
					margin: 0 2px 0 25px;
				}

				.two {
					font-size: 24px;
					color: #FFFFFF;
				}

				.three {
					font-size: 15px;
					color: #FFFFFF;
					margin-left: 60px;
				}
			}

			.title {
				margin-bottom: 12px;
				padding: 12px 20px 8px 20px;
				font-size: 16px;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				height: 36px;
			}

			&>img {
				width: 278px;
				height: 278px;
			}
		}
	}

	article {
		width: 1240px;
		margin: auto;
		margin-top: 20px;

		.header {
			zoom: 0.8;
			display: flex;

			&>.right {
				flex: 1;
				height: 400px;
			}

			&>.left {
				margin-right: 18px;

				&>img {
					width: 360px;
					height: 400px;
				}
			}
		}
	}

	.mainx {}
</style>