import request from "../utils/request.js"

// 购物车列表
export function gouwuList(data) {
	return request({
		url: "/goods.cart/get_list",
		method: "post",
		data
	});
}
// 删除商品
export function deleShanghg(data) {
	return request({
		url: "/goods.cart/get_del",
		method: "post",
		data
	});
}
// 计算购物车价格
export function gouwceJia(data) {
	return request({
		url: "/goods.cart/get_money",
		method: "post",
		data
	});
}
// 购物车修改数量
export function gouwceNumber(data) {
	return request({
		url: "/goods.cart/get_sum",
		method: "post",
		data
	});
}